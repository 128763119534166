import React, { useContext, useEffect, useState } from "react";
import { FiStar } from "react-icons/fi";
import { FaUserCircle } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./ProductDetails.scss";
import "flag-icon-css/css/flag-icons.min.css";

import GeneralContext from "../../../context/GlobalContext";
import { BoxDetailsProd } from "../../../components/boxDetailsProd/boxDetailsProd";
import CardProducts from "../../../components/cardProducts/CardProducts";


const ProductDetails = (props) => {
  const locations = useLocation();

  const { save_comment, allProducts,
    getProdDetails, prodDetails } = useContext(GeneralContext);

  const reviewss = prodDetails ? prodDetails.Reviews : [];
  /*  data avis  */
  const [comment, setComment] = useState("");
  const [nbStart, setNbStart] = useState(null);
  const [prodSimilar, setProdSimilar] = useState([]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  // save comment
  const handleSaveComment = e => {
    e.preventDefault();
    save_comment(comment, nbStart, prodDetails.id_prod, "msg-form");
  };

  // get product similar
  const getSimilarProduct = () => {
    if (prodDetails) {
      let catId = prodDetails.idCat;
      let filter = allProducts.filter(
        (item) => item.idCat === catId,
      );

      if (filter || filter.length > 0) {
        setProdSimilar(filter);
      }
    }
  };

  useEffect(() => {
    // get product details
    let dataProdId = locations.pathname.split("=")[1];
    if (dataProdId) {
      getProdDetails(dataProdId);
    }

    // get similar product
    getSimilarProduct();

  }, []);

  return (
    <div className="product-detail-page">
      {prodDetails ? (
        <>
          <section className="section-details-produit">
            <div className="head-details-prod">
              <p>
                Produit / détails / {prodDetails ? prodDetails.name_prod : null}
              </p>
            </div>
            <BoxDetailsProd data={prodDetails} />
            <div className="produit-desc-avis">
              <div className="div-bloc">
                <div className="div1">
                  <h4> Description <hr /></h4>
                  <p>
                  {prodDetails ? prodDetails.desc_prod : null}
                  </p>
                </div>
                <div className="div-avis">
                  <h4> Avis <hr /></h4>
                  <div className="reviews-block">
                    {reviewss.length > 0 && reviewss.map((list, index) => {
                      return <div className="reviews-div" key={index}>
                        <div className="head-r">
                          <FaUserCircle />
                          <p>
                            <h3> {list.user && list.user.firstname + " " + list.user.lastname} </h3>
                            <span> {list.createdAt.split("T")[0] + " " + list.createdAt.split("T")[1].split(".")[0]} </span>
                          </p>
                        </div>
                        <p className="comment"> {list.comments} </p>
                      </div>;
                    })}
                  </div>
                  <button className="btn-first"> Ajouter un avis </button>
                  <form className="form-avis">
                    <p>
                      Votre adresse e-mail ne sera pas publiée.
                      Les champs obligatoires sont indiqués avec *
                    </p>
                    <p id="msg-form"></p>
                    <div className="note-product">
                      Votre note*
                      <p>
                        {new Array(5).fill().map((_, index) => {
                          if (nbStart > index) {
                            return <span className={`star-note svgFill ${""}`}
                            onClick={() => setNbStart(index + 1)} key={index}>
                            <FiStar />
                          </span>;
                          }
                          else {
                            return <span className={"star-note"}
                            onClick={() => setNbStart(index + 1)} key={index}>
                            <FiStar />
                          </span>;
                          }
                          
                        })}
                      </p>
                    </div>
                    <div className="div-field">
                      <textarea cols={5} rows={5}
                        value={comment}
                        onChange={e => setComment(e.target.value)}></textarea>
                    </div>
                    {/* <div className="div-fields">
                      <p>
                        <input type="text" placeholder="Nom *"
                          value={firstname}
                          onChange={e => setFirstname(e.target.value)} />
                        <span className="span-error" id="name-error"></span>
                      </p>
                      <p>
                        <input type="email" placeholder="E-mail*"
                          value={email}
                          onChange={e => setEmail(e.target.value)} />
                        <span className="span-error" id="email-error"></span>
                      </p>
                    </div> */}
                    {/* <div className="infos">
                      <input type="checkbox" name="check" />
                      <span>
                        Enregistrer mon nom, mon e-mail et mon site dans le
                        navigateur pour mon prochain commentaire.
                      </span>
                    </div> */}
                    <button className="btn-send"
                      onClick={handleSaveComment}> Soumettre </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="product-similar">
              <h4 className="title-ps"> Produits similaires <hr /> </h4>
              <div className="slide-div-prod">
                <Carousel responsive={responsive} autoPlay={true}>
                  <CardProducts listProduct={prodSimilar || prodSimilar.length > 0 ? prodSimilar:[]} />
                </Carousel>
              </div>
            </div>
          </section>
        </>
      ) : <h1> Vérifier votre connexion</h1>}
    </div>
  );
};

export default ProductDetails;
