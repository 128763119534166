import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { AiFillShop, AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from "react-share";

import GeneralContext from "../../context/GlobalContext";
import { API_URL, CART_SHOPPING, PRODUCT_CATEGORY } from "../../settings/constant";
import { PopupLayout } from "../popupLayout/PopupLayout";
import "./boxDetailsProd.scss";

export const BoxDetailsProd = ({ data, isPopupClose }) => {

  const color_prod = data ? data.colors:"";
  const size_prod = data ? data.sizes:"";

  const { createCartUser } = useContext(GeneralContext);

  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [colorChoose, setColorChoose] = useState(null);
  const [sizeChoose, setSizeChoose] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [popup, isPopup] = useState(false);

  const sizess = [];
  const colorss = [];

  // incremebnt function
  const incrementQty = () => {
    setQuantity(quantity + 1);
  };

  // decrement function
  const decrementQty = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  /* action pour des produits au panier */
  const addToCart = () => {
    let size_err = document.querySelector(".alert-size");
    let color_err = document.querySelector(".alert-color");
    let amount = data.reduces === 0 || data.reduces === null ? Math.round(data.price_prod) : Math.round(data.price_prod) - Math.round((Math.round(data.price_prod) * data.reduces) / 100);
    const formData = new FormData();
    formData.append("id_prod", data.id_prod);
    formData.append("amount", amount);
    formData.append("name_prod", data.name_prod);
    formData.append("image_prod", data.image_prod);
    formData.append("quantity", quantity);
    formData.append("store_id", data.Store ? data.Store.store_id:0);
    
    if (colors.length > 0) {
      if (sizes.length > 0) {
        if (colorChoose) {
          if (sizeChoose) {
            formData.append("colors", colorChoose ? colorChoose:"");
            formData.append("sizes", sizeChoose ? sizeChoose:"");
            createCartUser(formData, isPopup);
          } else {
            size_err.innerText = "Sélectionner une taille";
          }
        } else {
          color_err.innerText = "Sélectionner une couleur";
        }
      } else {
        if (colorChoose) {
          formData.append("colors", colorChoose ? colorChoose:"");
          formData.append("sizes", sizeChoose ? sizeChoose:"");
            createCartUser(formData, isPopup);
        } else {
          color_err.innerText = "Sélectionner une couleur";
        }
      }
    } else {
      if (sizes.length > 0) {
        if (sizeChoose) {
          formData.append("colors", colorChoose ? colorChoose:"");
          formData.append("sizes", sizeChoose ? sizeChoose:"");
          createCartUser(formData, isPopup);
        } else {
          size_err.innerText = "Sélectionner une taille";
        }
      } else {
        formData.append("colors", colorChoose ? colorChoose:"");
        formData.append("sizes", sizeChoose ? sizeChoose:"");
        createCartUser(formData, isPopup);
      }
    }
  };

  /* convert string in array */
  const convertStringToArraySize = (type, array, setHook, typ, setValChoose) => {
    if (data) {
      if (type) {
        const splits = type.split(" ");
        if (splits.length > 1) {
          for (var i = 0; i < splits.length; i++) {
            array.push(splits[i]);
          }
          setHook(array);
        } else if (splits.length == 1) {
          setHook([type]);
          setValChoose(type);
        }
      } 
    }
  };


  // return link product
  const returnLinkProduct = () => {
    let origin = window.location.href;
    return origin;
  };

  useEffect(() => {
    // return product link
    returnLinkProduct();
    /* covert string in array size and color */
    convertStringToArraySize(size_prod, sizess, setSizes, "size", setSizeChoose);
    convertStringToArraySize(color_prod, colorss, setColors, "color", setColorChoose);
  }, [data]);

  return (
    <div className="ProduitDetails">
      <PopupLayout closes={popup}>
        <div className="popup-card">
          <p>Produit ajouté au panier avec succès !!
            Ouvrez le panier ou continuer... </p>
          <div className="btns-action">
            <button onClick={() => {
              window.location.href = CART_SHOPPING;
            }}> Ouvrir le panier</button>
            <button onClick={() => {
              window.location.href = PRODUCT_CATEGORY;
            }}> Continuer </button>
          </div>
        </div>
      </PopupLayout>
      <span className="close" onClick={() => isPopupClose(false)}>&times;</span>
      <div className="div-img">
        <p className="name-shop"><AiFillShop /> {data ? !data.for_admin ? data.Store.companyName:"Autasys":""}</p>
        <div className="image-prod">
          <img src={data ? `${API_URL}${data.image_prod}` : null} />
        </div>
      </div>
      <div className="div-details">
        <h1> {data ? data.name_prod : null} </h1>
        <b> {data ? Math.round(data.price_prod) : null} {data ? data.currency : null} </b>
        {
          colors.length > 0 && (
            <div className="select-div">
              <label> color: </label>
              <select className="select"
                value={colorChoose}
                onChange={e => setColorChoose(e.target.value)}>
                <option disabled selected>choisissez une couleur</option>
                {
                  colors.map((list, index) => {
                    if(list !== "") {
                      return <option value={list} key={index
                      }> {list} </option>;
                    }
                  })
                }
              </select>
              <span className='alert-color'></span>
            </div>
          )
        }
        {
          sizes.length > 0 &&  (
            <div className="select-div">
              <label>Taille:</label>
              <select className="select"
                value={sizeChoose}
                onChange={e => setSizeChoose(e.target.value)}>
                <option disabled selected> Choisissez une taille </option>
                {
                  sizes.map((list, index) => {
                    if (list != "") {
                      return <option value={list} key={index}>
                        {list}
                      </option>;
                    }
                  })
                }
              </select>
              <span className='alert-size'></span>
            </div>
          )
        }
        <div className="quantity">
          <label> Quantité : </label>
          <div className="qty">
            <button onClick={decrementQty}>
              <AiOutlineMinus /> </button>
            <input type="text" value={quantity} />
            <button onClick={incrementQty}>
              <AiOutlinePlus /> </button>
          </div>
        </div>
        <button className="btn-add-cart" onClick={addToCart}> Ajouter au panier </button>
        <div className="brand-category">
          <p> USG : <b>HTDO833</b> Catégorie : <b>{data ? data.Category.nameCat: null}</b></p>
        </div>
        <div className="share">
          <span> Share :</span>
          <div className="">
            <li>
              <FacebookShareButton
                url={returnLinkProduct()}>
                <FacebookIcon size={28} round />
              </FacebookShareButton>
            </li>
            <li>
              <WhatsappShareButton
                url={returnLinkProduct()}>
                <WhatsappIcon size={28} round />
              </WhatsappShareButton>
            </li>
          </div>
        </div>
      </div>
    </div>
  );
};