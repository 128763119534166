import React, { useContext, useEffect, useState } from "react";
import { FiChevronDown, FiChevronRight, FiImage } from "react-icons/fi";
import { BoxAlertSucess } from "../../../../components/boxAlertSucess/BoxAlertSucess";
import { validInputText } from "../../../../components/controlFields/ControlField";
import { HeadTabs } from "../../../../components/headTabs/HeadTabs";
import GeneralContext from "../../../../context/GlobalContext";
import { API_URL, } from "../../../../settings/constant";

import "./products.scss";
import { verifyFile } from "../../../../components/verifyFile/verifyFile";
import CurrencyInput from "../../../../components/currencyList/currencyInput";

const AddProduct = () => {
  const { store, create_product,
    category, subCat, getProductToStore } = useContext(GeneralContext);

  const [fail, isFail] = useState(false);
  const [done, isDone] = useState(false);
  const [popup, isPopup] = useState(false);
  const [openCategory, isOpenCategory] = useState(false);

  const [storeId, setStoreId] = useState(null);
  const [nameProd, setNameProd] = useState(null);
  const [prodDesc, setProdDesc] = useState(null);
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [price, setPrice] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [colors, setColors] = useState("");
  const [brandProd, setBrandProd] = useState(null);
  const [subcategoryId, setSubCategoryId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [categoryName, setCategoryName] = useState("");

  const [lsize, setSizeL] = useState(false);
  const [small, setSmall] = useState(false);
  const [sizeM, setSizeM] = useState(false);
  const [sizeX, setSizeX] = useState(false);
  const [size2X, setSize2X] = useState(false);
  const [size3X, setSize3X] = useState(false);

  const [msgSucces, setMsgSucces] = useState("");


  /* return image selected */
  const handleChangeImage = (e) => {
    setImage(e.target.files[0]);
  };

  /* clear all fields after add product */
  const clearAllFields = () => {
    setNameProd("");
    setBrandProd("");
    setPrice("");
    setCategoryName("");
    setSubCategoryId("");
    setCategoryId("");
    setProdDesc("");
    setImage("");
    setColors("");
    setSizeL(false);
    setSize2X(false);
    setSize3X(false);
    setSizeM(false);
    setSizeX(false);
    setSmall(false);
    setImage(null);
    setImageName(null);
  };

  /* select size active style */
  const selectSizeActive = {
    background: "var(--orange-transparent)",
    border: "1px solid var(--orange)",
  };

  /* get subcategorie by category */
  const setCategory = (name, idcat) => {
    setCategoryId(idcat);
    setCategoryName(name);
    isOpenCategory(!openCategory);
  };

  const setSubCategory = (id, nameCat, idCat, nameSubCat) => {
    setSubCategoryId(id);
    setCategoryName(nameCat + "->" + nameSubCat);
    setCategoryId(idCat);isOpenCategory(!openCategory);
  };

  // return infos store active
  const returnStore = () => {
    if (store && store.length > 0) {
      let find = store.find(
        (item) => item.is_active === true
        );

      if (find) {
        setStoreId(find.store_id);
        getProductToStore(find.store_id);
      }
    }
  };

  /* function add product */
  const handleSubmit = async (e) => {
    e.preventDefault();

    const desc_err = document.getElementById("desc_err");
    const img_error = document.getElementById("img-error");
    const size_error = document.getElementById("size-error");
    const color_error = document.getElementById("color-error");
    const msg_text = document.querySelector(".msg");
    let product = JSON.parse(sessionStorage.getItem("product"));

    let btn_send = document.querySelector(".btn-update");

    validInputText(price, "price-error", "price");

    if (validInputText(price, "price-error", "price") && 
    verifyFile(image.name,"img-error")) {

      if (prodDesc) {
        if (image || imageName) {
          img_error.innerText = "";
          size_error.innerText = "";
          color_error.innerText = "";
          let sizes = `${small ? "S" : ""}${sizeM ? " M" : ""}${lsize ? " L" : ""}${sizeX ? " XL" : ""}${size2X ? " 2XL" : ""}${size3X ? " 3XL" : ""}`.trim();
          if (!product) {
            create_product(nameProd, brandProd, prodDesc, price, currency,
              image, sizes ?? sizes, colors ?? colors.trim(), storeId, subcategoryId !== null ? subcategoryId : 0, categoryId,
              clearAllFields, "create", null, null, isPopup, setMsgSucces, isDone, isFail);
          } else {
            create_product(nameProd, brandProd, prodDesc, price, currency,
              image, sizes ?? sizes, colors ?? colors.trim(), storeId, subcategoryId !== null ? subcategoryId : 0, categoryId,
              clearAllFields, "update", product.id_prod, btn_send, isPopup, setMsgSucces, isDone, isFail);
          }
        } else {
          img_error.innerText = "Choisissez une image de produit";
        }
      } else {
        desc_err.innerText = "Décrivez votre produit en quelques mots";
      }
    }
  };

  /* set data product for update */
  const update_product = () => {
    let product = sessionStorage.getItem("product");
    if (product) {
      let array = JSON.parse(product);
      let arraySize = array.sizes ? array.sizes.split(" ") : "";

      setNameProd(array.name_prod);
      setBrandProd(array.brand);
      setPrice(Math.round(array.price_prod));
      setProdDesc(array.desc_prod);
      setColors(array.colors);
      setImage(array.image_prod);
      setImageName(array.image_prod);
      setCategoryId(array.Category.idCat);
      setSubCategoryId(array.Subcategory != null ? array.Subcategory.idSubCat : null);
      setCategoryName(array.Category.nameCat);
      setCurrency(array.currency);

      for (var i = 0; i < arraySize.length; i++) {
        if (arraySize[i] === "S") {
          setSmall(true);
        } else if (arraySize[i] === "L") {
          setSizeL(true);
        } else if (arraySize[i] === "M") {
          setSizeM(true);
        } else if (arraySize[i] === "XL") {
          setSizeX(true);
        } else if (arraySize[i] === "2XL") {
          setSize2X(true);
        } else if (arraySize[i] === "3XL") {
          setSize3X(true);
        }
      }
    }
  };

  const open = {display: "block"};
  const close = {display: "none"};

  useEffect(() => {
    returnStore();
    update_product();

    let prod = sessionStorage.getItem("product");
    let btn_send = document.querySelector(".btn-update");
    if (prod) {
      btn_send.innerText = "Mettre à jour";
    }

  }, [storeId, category, store]);


  return (
    <div className="add-product-form">
      <HeadTabs title={"Nouveau produit"} />
      <BoxAlertSucess popup={popup}
        text={msgSucces}
        isDone={isDone}
        done={done}
        handleBtn={isPopup}
        link={"#"} />
      <div className="block-form-product parent">
        <div className="form">
          <form>
            {fail ? (
              <p className="text-mg">
                Opération échoué ! Veuillez Réessayer.
              </p>
            ) : null}
            <div className="div-input">
              <div className="div-categorie">
                <label> Catégorie <span>*</span></label>
                <div className="select-categorie">
                  <input type="text" value={categoryName ? categoryName : "Sélectionner une catégorie"} />
                  <p className="svg-p" onClick={() => isOpenCategory(!openCategory)}><FiChevronDown /></p>
                  <div className="parents-categorie" style={openCategory ? open:close}>
                    {category.length > 0 && category.map((list, index) => {
                      return <div key={index} className="ul-parent">
                        <p className="p-item" onClick={() => setCategory(list.nameCat, list.idCat)}>
                          {list.nameCat}
                          {subCat.length > 0 ? <FiChevronRight /> : null}
                        </p>
                        {/* <div className="ul-child">
                          {list.SubCategories && (
                            list.SubCategories.map((list2, index2) => {
                              return <p className="p-item" key={index2}
                                onClick={() => setSubCategory(list2.idSubCat, list.nameCat, list.idCat, list2.nameSubCat)}>
                                {list2.nameSubCat}
                              </p>;
                            })
                          )}
                        </div> */}
                      </div>;
                    })}
                  </div>
                </div>
              </div>
              <span className="span-error"></span>
            </div>
            <div className="div-input">
              <p>
                <label> Nom du Produit <span>*</span></label>
                <input type="text"
                  name="" placeholder="..."
                  value={nameProd}
                  onChange={(e) => setNameProd(e.target.value)} />
              </p>
              <span className="span-error" id="name-error"></span>
            </div>
            <div className="div-input">
              <p>
                <label> Marque du Produit <span>*</span></label>
                <input type="text"
                  name="" placeholder="..."
                  value={brandProd}
                  onChange={(e) => setBrandProd(e.target.value)} />
              </p>
              <span className="span-error" id="name-error"></span>
            </div>
            
            <div className="div-input">
              <p>
                <label> Devise monétaire (par défaut (CFA)) <span>(facultatif) </span></label>
                <CurrencyInput  setCurrency={setCurrency} curren={"CFA"} />
              </p>
            </div>
            <div className="div-input">
              <p>
                <label> Prix unitaire <span>*</span></label>
                <input type="text"
                  name="" placeholder="..."
                  value={price}
                  onChange={(e) => setPrice(e.target.value)} />
              </p>
              <span className="span-error" id="price-error"></span>
            </div>
            <div className="div-input">
              <p>
                <label> Description du produit<span>*</span></label>
                <textarea cols={6} rows={5}
                  placeholder="décrivez le produit en quelques mots"
                  value={prodDesc}
                  onChange={(e) => setProdDesc(e.target.value)}> </textarea>
              </p>
              <span className="span-error" id="desc_err"></span>
            </div>
            <div className="div-input div-file">
              <p className='file-img-p'>
                <label> Image du product<span>*</span></label>
                <div className="file-img">
                  <FiImage />
                  <span> {image ? (imageName ? imageName:image.name) :  "Sélectionner une image"} </span>
                  <input type="file" name="file"
                    accept=".jpg, .png, .jpeg"
                    onChange={handleChangeImage} />
                </div>
                {imageName && <img src={`${API_URL}${imageName}`} /> }
                <span>
                  {image ? "\""+image.name+"\"" + " Produit sélectionner" : null}
                </span>
              </p>
              <span className="span-error" id="img-error"></span>
              <p className="text-file-img">
                La taille maximale pour une seule image est de 3 Mo. Les formats suivants sont pris en charge : jpeg, jpg, png. Utilisez autant de photos sous autant d'angles que nécessaire pour afficher correctement votre produit.
                Nous suggérons que vos images soient inférieures à 1000px*1000px avec un sujet clair pour améliorer la satisfaction de l'acheteur.
              </p>
            </div>
            <div className="div-input">
              <p>
                <label> Taille <span>*</span> </label>
                <ul className="sizes">
                  <span style={small ? selectSizeActive : null}
                    onClick={() => setSmall(small ? false : true)} >S</span>
                  <span style={sizeM ? selectSizeActive : null}
                    onClick={() => setSizeM(sizeM ? false : true)} >M</span>
                  <span style={lsize ? selectSizeActive : null}
                    onClick={() => setSizeL(lsize ? false : true)} > L </span>
                  <span style={sizeX ? selectSizeActive : null}
                    onClick={() => setSizeX(sizeX ? false : true)}> XL </span>
                  <span style={size2X ? selectSizeActive : null}
                    onClick={() => setSize2X(size2X ? false : true)}> 2XL </span>
                  <span style={size3X ? selectSizeActive : null}
                    onClick={() => setSize3X(size3X ? false : true)}> 3XL </span>
                </ul>
                <i>choisissez les tailles ou la taille par défaut <b>(Facultatif)</b> </i>
              </p>
              <span className="span-error" id="size-error"></span>
            </div>
            <div className="div-input colos-input">
              <p>
                <label> Couleur<span></span> (facultatif)  </label>
                <textarea cols={3} rows={8}
                  value={colors}
                  onChange={e => setColors(e.target.value)}
                  placeholder="ex: rouge blue jaune..."></textarea>
              </p>
              <p className='notice'>
                Entré le nom ou la valeur des couleurs séparée
                par une espace
              </p>
              <span className="span-error" id="color-error"></span>
            </div>
            <button className="btn-update"
              onClick={handleSubmit}> Ajouter le produit </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
