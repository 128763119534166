import React from "react";
import axios from "axios";
import { useEffect, useState, createContext, useContext } from "react";
import { country } from "../components/currencyList/countries";
import { ACTIVE_ACCOUNT_PAGE, API_URL, config, LOGIN } from "../settings/constant";
import AuthContext from "./AuthContext";
import { useDispatch, useSelector } from "react-redux";
import { addItem, clearCart, decrementQty, incrementQty, removeItem } from "../store/cart/cartSlice";
import useAxios from "../utils/useAxios";

const GeneralContext = createContext();

export default GeneralContext;

export const GlobalContext = ({ children }) => {
  const dispatch = useDispatch();
  const order_item = useSelector((state) => state.cart.cartItem);

  const { user, createStore, userAdmin } = useContext(AuthContext);
  const [userData, setUserData] = useState([]);

  const useaxios = useAxios();
  const userIdLocal = localStorage.getItem("userId__");

  const [allProducts, setAllProducts] = useState([]);
  const [whishlist, setWhishlist] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [store, setStore] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [shippingAdress, setShippingAdress] = useState([]);

  const [productSearch, setProductSearch] = useState([]);
  const [productSearchStore, setProductSearchStore] = useState([]);
  const [productStore, setProductStore] = useState([]);
  const [productTrash, setProductTrash] = useState([]);
  const [productOne, setProductOne] = useState([]);
  const [profileStore, setProfileStore] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState([]);
  const [categoryMarket, setCategoryMarket] = useState([]);
  const [orders, setOrders] = useState([]);
  const [categorieByStore, setCategorieByStore] = useState([]);
  const [saleTrash, setSaleTrash] = useState([]);
  const [saleStore, setSaleStore] = useState([]);
  const [cartItemStore, setCartItemStore] = useState([]);
  const [cartStoreId, setCartStoreId] = useState(0);
  const [statsStore, setStatsStore] = useState([]);

  const [subCat, setSubCat] = useState([]);
  const [itemSubCat, setItemSubCat] = useState([]);

  const [location, setLocation] = useState([]);

  const [storeProfile, setStoreProfile] = useState();


  // get location of user from the API
  const getDataLocation = async () => {
    try {
      const res = await axios.get("https://geolocation-db.com/json/");

      if (res) {
        setLocation(res.data);
        sessionStorage.setItem("code_country", res.data.country_code);
        sessionStorage.setItem("country", res.data.country_name);
        sessionStorage.setItem("city", res.data.city);

        let find = country.find(
          (item) => item.countryName === res.data.country_name,
        );

        if (find) {
          sessionStorage.setItem("currency", find.currencyCode);
        }
      }
    } catch (error) {
      return error;
    }
  };

  /* get user info */
  const getUserData = async () => {
    try {
      const response = await useaxios.get(`${API_URL}account/user-details/${user ? user.id : userIdLocal}`, config);
      if (response.status === 200) {
        setUserData(response.data);
      }
    } catch (error) {
      return error;
    }
  };


  /* create cart user */
  const createCartUser = async (formData, isPopup) => {
    const formDataObject = {};
    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });
    const userId__ = localStorage.getItem("userId__");
    formDataObject.user = user ? user.id : userId__ ? userId__ : null;
    try {
      const response = await axios.post(`${API_URL}shoppingCart/create`, formDataObject, config);
      if (response.status === 201 || response.status === 200) {
        isPopup(true);
        localStorage.setItem("userId__", response.data.id);
      }
    } catch (error) {
      return error;
    }
  };

  /* get shoppingcart item */
  const [shoppingCartItem, setShoppingCartItem] = useState([]);
  const getShoppingCartItem = async () => {
    const userId__ = localStorage.getItem("userId__");
    try {
      const response = await axios.get(`${API_URL}shoppingCart/user/${user ? user.id : userId__}`, config);
      if (response.status === 200) {
        setShoppingCartItem(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  /* update shoppingcart item qty */
  const updateShoppingCartItemQty = async (id_prod, colors, sizes, action) => {
    const userId__ = localStorage.getItem("userId__");
    if (user || userId__) {
      try {
        const response = await axios.put(`${API_URL}shoppingCart/update-qty`, {
          id_prod: id_prod,
          user: user ? user.id : userId__,
          colors: colors ?? "",
          sizes: sizes ?? "",
          action: action
        }, config);
        if (response.status === 200) {
          getShoppingCartItem();
        }
      } catch (error) {
        return error;
      }
    }
  };

  /* delete shoppingcart item */
  const deleteShoppingCartItem = async (id_prod, colors, sizes, store_id) => {
    const userId__ = localStorage.getItem("userId__");
    if (user || userId__) {
      try {
        const response = await axios.put(`${API_URL}shoppingCart/delete`, {
          id_prod: id_prod,
          user: user ? user.id : userId__,
          colors: colors,
          sizes: sizes,
          store_id: store_id
        }, config);
        if (response.status === 200) {
          getShoppingCartItem();
        }
      } catch (error) {
        return error;
      }
    }
  };

  /* send email contact */
  const sendMailToStore = async (to_email, from_email, firstname, message) => {
    try {
      const response = await axios.post(`${API_URL}send/mail-to-store`, {
        from_email: from_email,
        subject: message,
        to_email: to_email,
        firstname: firstname,
        message: message
      }, config);
    } catch (error) {
      return error;
    }
  };

  /* send mail for update password */
  const sendEmail = async (email, setStep, isLose) => {
    try {
      const response = await axios.post(`${API_URL}account/send-mail/`, {
        email: email,
        origin: window.location.origin
      });
      if (response.status === 200) {
        setStep(2);
      } else {
        setStep(0);
        isLose(true);
      }
    } catch (error) {
      setStep(3);
      return error;
    }
  };

  /* send mail for update password */
  const sendEmailAdmin = async (email, setStep, isLose) => {
    try {
      const response = await axios.post(`${API_URL}account/send-mail-admin`, {
        email: email,
        origin: window.location.origin
      });
      if (response.status === 200) {
        setStep(2);
      } else {
        setStep(0);
        isLose(true);
      }
    } catch (error) {
      setStep(3);
      return error;
    }
  };

  /* reset password */
  const reset_pwd = async (email, password) => {
    try {
      const response = await axios.put(`${API_URL}account/change-password`, {
        password: password,
        email: email
      }, config);
      if (response.status === 200) {
        window.location.href = LOGIN;
      }
    } catch (error) {
      return error;
    }
  };

  /* get country code */
  const get_code_country = (countrys) => {
    const finds = country.find(
      (item) => item.countryName == countrys,
    );
    if (finds) {
      return finds.countryCode.toLowerCase();
    }
  };

  /* *************************************************** */
  /* get catégorie */
  const getCategoryData = async () => {
    try {
      const response = await axios.get(`${API_URL}category/all`, config);
      if (response.status === 200) {
        setCategory(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  /* get category product */
  const [categoryProduct, setCategoryProduct] = useState([]);
  const getCategoryProduct = async () => {
    try {
      const response = await axios.get(`${API_URL}category/product`, config);
      if (response.status === 200) {
        setCategoryProduct(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  /* **********************product***************************** */
  /* product and category */

  /* add produist */
  const create_product = async (name_prod, brand_prod, prod_desc, price_prod, currency,
    image_prod, sizes, colors, store_id, subcategory, categoryId, clearAllFields, action,
    id_prod, btn_send, isPopup, setMsgSucces, isDone, isFail, by) => {

    isPopup(true);
    const formData = new FormData();
    formData.append("name_prod", name_prod);
    formData.append("desc_prod", prod_desc);
    formData.append("brand", brand_prod);
    formData.append("price_prod", parseInt(price_prod));
    formData.append("image_prod", image_prod);
    sizes ? formData.append("sizes", sizes) : null;
    colors ? formData.append("colors", colors) : null;
    formData.append("store_id", store_id);
    formData.append("idSubCat", subcategory === null ? 0 : parseInt(subcategory));
    formData.append("idCat", parseInt(categoryId));
    if (currency) {
      formData.append("currency", currency);
    }
    if (by && by === "admin") {
      formData.append("for_admin", true);
    }

    try {
      if (action === "create") {
        const response = await axios.post(`${API_URL}product/add`, formData);
        if (response.status === 201) {
          setMsgSucces("Produit ajouté avec succès !");
          isDone(true);
          clearAllFields();
        } else {
          isFail(true);
          isPopup(false);
        }
      } else if (action === "update") {
        formData.append("id_prod", id_prod);
        if (!image_prod) {
          formData.delete("image_prod");
        }
        const response = await axios.put(`${API_URL}product/update`, formData, config);
        if (response.status === 200) {
          clearAllFields();
          setMsgSucces("Produit mise à jour avec succès !");
          isDone(true);

          btn_send.innerText = "Ajoute le produit";
          sessionStorage.removeItem("product");
        } else {
          isPopup(false);
          isFail(true);
        }
      }
    } catch (error) {
      isFail(true);
      isPopup(false);
      return error;
    }
  };

  /* get product by user */
  const [prodDetails, setProdDetails] = useState();
  const getProdDetails = async (id_prod) => {
    try {
      const response = await axios.get(`${API_URL}product/details/${id_prod}`);
      if (response.status === 200) {
        setProdDetails(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  /* upate product in tendance or collection */
  const updateProductTendanceOrCollection = async (list, type, action, isDone, isPopup2, setMsgPublish) => {
    isPopup2(true);

    try {
      const response = await axios.put(`${API_URL}product/update-tendorcollect`, {
        type: type,
        id_prod: list.id_prod,
        action: action
      });
      if (response.status === 200) {
        isDone(true);
        let data = response.data.msg;
        if (data === "add collection") {
          setMsgPublish("Produit ajouter à la catégorie collection avec succès !");
        } else if (data === "add tendance") {
          setMsgPublish("Produit ajouter à la catégorie tendance avec succès !");
        } else if (data === "retry collection") {
          setMsgPublish("Product retiré de la catégorie collection avec succès");
        } else if (data === "retry tendance") {
          setMsgPublish("Product retiré de la catégorie tendance");
        }
      }
    } catch (error) {
      return error;
    }
  };

  /* pusblish product to store */
  const publishProduct = async (id_prod, store_id, setMsgPublish, isPopup3) => {
    if (user) {
      try {
        const response = await axios.put(`${API_URL}product/publish/${id_prod}`, {
          store_id: store_id
        }, config);
        if (response.data.msg === "pub") {
          isPopup3(true);
          setMsgPublish("Produit publié avec succès !");
        } else {
          isPopup3(true);
          setMsgPublish("Produit supprimer des publication avec succès !");
        }
      } catch (error) {
        return error;
      }
    }
  };

  /* get all product */
  const getAllProduct = async () => {
    try {
      const response = await axios.get(`${API_URL}product/all`, config);
      const data = response.data;
      if (response.status == 200) {
        setAllProducts(data);
      }
    } catch (error) {
      return error;
    }
  };

  /* get product by category or subcategorie */
  const getProductBySubCategorie = async (idSubcat) => {
    try {
      const response = await axios.get(`${API_URL}product/by-subcategorie/${idSubcat}`, config);
      if (response.status === 200) {
        setAllProducts(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  const getProductByCategorie = async (idCat) => {
    try {
      const response = await axios.get(`${API_URL}product/by-categorie/${idCat}`, config);
      if (response.status === 200) {
        setAllProducts(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  /* set reduce product */
  const updateReduce = async (prod_id, reduce, isDone, setReducePercent) => {
    try {
      const response = await axios.put(`${API_URL}product/update-reduce/${prod_id}`, {
        reduces: reduce,
      }, config);
      if (response.status === 200) {
        isDone(true);
        setReducePercent(null);
      }
    } catch (error) {
      return error;
    }
  };

  /* add cobeille product */
  const deleteProduct = async (prod_id, isDone, isPopup2, setMsgPublish) => {
    isPopup2(true);
    try {
      const response = await axios.put(`${API_URL}product/add-trash/${prod_id}`, config);
      if (response.status === 200) {
        isDone(true);
        setMsgPublish("Produit envoyé dans la corbeille avec succès.");
      }
    } catch (error) {
      return error;
    }
  };

  /* restore product of cobeille */
  const restoreProduct = async (prod_id, isDone, setMsgText, isPopup) => {
    isPopup(true);

    try {
      const response = await axios.put(`${API_URL}product/restore/${prod_id}`, config);
      if (response.status === 200) {
        isDone(true);
        setMsgText("Produit restauré avec succès !");
      }
    } catch (error) {
      return error;
    }
  };

  /* delete definitively product */
  const deleteDefinitiveProduct = async (prod_id, isDone, setMsgText, isPopup) => {
    isPopup(true);
    try {
      const response = await axios.delete(`${API_URL}product/delete/${prod_id}`, config);
      if (response.status === 200) {
        isDone(true);
        setMsgText("Produit supprimé définitivement avec succès !");
      }
    } catch (error) {
      return error;
    }
  };

  /* get filter product */
  const getFilterProductMarket = async (category, subcat) => {
    try {
      const response = await axios.post(`${API_URL}product/get-filter-product-market`, {
        category: category,
        subcat: subcat,
      }, config);
      if (response.status === 200) {
        setAllProducts(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  // filter product by price
  const filterProductByPrice = async (min, max, type, store_id) => {
    try {
      const response = await axios.post(`${API_URL}product/filter-by-price`, {
        store_id: store_id,
        minPrice: min,
        maxPrice: max,
        type: type,
        categoryId: sessionStorage.getItem("cat") ? sessionStorage.getItem("cat") : 0
      });
      if (response.status === 200) {
        if (!type) {
          setAllProducts(response.data);
        } else {
          setProductStore(response.data);
        }
      }
    } catch (error) {
      return error;
    }
  };

  // filter product by size
  const filterProductBySize = async (size, type, store_id) => {
    try {
      const response = await axios.post(`${API_URL}product/filter-by-size`, {
        store_id: store_id,
        size: size,
        type: type,
        categoryId: sessionStorage.getItem("cat") ? sessionStorage.getItem("cat") : 0
      });
      if (response.status === 200) {
        if (!type) {
          setAllProducts(response.data);
        } else {
          setProductStore(response.data);
        }
      }
    } catch (error) {
      return error;
    }
  };

  // filter product by color
  const filterProductByColor = async (color, type, store_id) => {

    try {
      const response = await axios.post(`${API_URL}product/filter-by-color`, {
        store_id: store_id,
        color: color,
        type: type,
        categoryId: sessionStorage.getItem("cat") ? sessionStorage.getItem("cat") : 0
      });
      if (response.status === 200) {
        if (!type) {
          setAllProducts(response.data);
        } else {
          setProductStore(response.data);
        }
      }
    } catch (error) {
      return error;
    }
  };

  /* search product */
  const getProductSearch = async (keyword, categorie) => {
    try {
      const response = await axios.post(`${API_URL}product/search`, {
        keyword: keyword,
        categoryId: categorie
      });
      if (response.status === 200) {
        setProductSearch(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  /* search product */
  const getProductSearchStore = async (store_id, keyword) => {
    try {
      const response = await axios.post(`${API_URL}product/store-search`, {
        keyword: keyword,
        store_id: store_id,
      }, config);
      if (response.status === 200) {
        setProductSearchStore(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  /*  get favoris user */
  const getWhishlist = async () => {
    if (user) {
      try {
        const response = await axios.get(`${API_URL}user/get-whishlist/${user.id}`);
        if (response.status === 200) {
          setWhishlist(response.data);
        }
      } catch (error) {
        return error;
      }
    }
  };

  /* add favori */
  const add_favoris = async (id_prod, classe, index, activebtn) => {
    let all = document.querySelectorAll(classe);
    if (user) {
      try {
        await axios.post(`${API_URL}product/add-whishlist`, {
          users: user.id,
          product: id_prod,
        }, config).then((response) => {
          if (response.status === 201) {
            getWhishlist();
            all[index].classList.add("active-whishlist-btn");
          } else if (response.status === 200) {
            getWhishlist();
            all[index].classList.remove("active-whishlist-btn");
          }
        });
      } catch (error) {
        return error;
      }
    } else {
      window.location.href = LOGIN;
    }
  };

  /* save reviews */
  const save_comment = async (comment, nbEtoile, id_prod, id_msg) => {
    const msg = document.getElementById(id_msg);
    if (user) {
      if (comment) {
        if (nbEtoile) {
          msg.style.display = "none";
          try {
            const response = await axios.post(`${API_URL}product/save-reviews`, {
              user: user.id,
              id_prod: id_prod,
              comment: comment,
              ratings: nbEtoile
            });
            if (response.status === 201) {
              msg.innerText = "Commentaire avec succès !";
              window.location.reload();
            } else {
              msg.style.display = "flex";
              msg.innerText = "Vous avez déjà donner votre produit sur ce produit..";

            }
          } catch (error) {
            return error;
          }
        } else {
          msg.style.display = "flex";
          msg.innerText = "Noter cette produit par le nombre d'étoile attribué";
        }
      } else {
        msg.style.display = "flex";
        msg.innerText = "Entré votre avis et noter ce produit";
      }
    } else {
      msg.style.display = "flex";
      msg.innerText = "Connectez-vous !!!";
    }
  };

  /* get sold count by product */
  const [countSoldProd, setCountSoldProd] = useState(0);
  const getCountSold = async (prod_id) => {
    try {
      const response = await axios.get(`${API_URL}product/get-sold-count/${prod_id}`, config);
      if (response.status == 200) {
        setCountSoldProd(response.data.count_sold);
      }
    } catch (error) {
      return error;
    }
  };

  /* *************************** action adress *************************/

  /* get data */
  const getShippingAdress = async () => {
    try {
      const response = await axios.get(`${API_URL}shippingAdress/list/${user ? user.id : localStorage.getItem("userId__")}`);
      const data = response.data;
      if (response.status === 200) {
        setShippingAdress(data);
      }
    } catch (error) {
      return error;
    }
  };

  /* add adresse */
  const createShippingAdress = async (fullname, country, department,
    city, telephone, numRue, codePostale, email, clearField, msg_text) => {
    try {
      const response = await axios.post(`${API_URL}shippingAddress/create`, {
        fullname: fullname,
        country: country,
        department: department,
        city: city,
        telephone: telephone,
        numRue: numRue,
        zipcode: codePostale,
        email: email,
        id: user ? user.id : localStorage.getItem("userId__"),
      }, config);
      if (response.status === 201) {
        window.location.reload();
      }
    } catch (error) {
      return error;
    }
  };

  const updateShippingAdress = async (id, fullname, country, department,
    city, telephone, numRue, codePostale, email, clear, msg_text) => {
    try {
      const response = await axios.put(`${API_URL}shippingAdress/update/${id}`, {
        fullname: fullname,
        country: country,
        department: department,
        city: city,
        telephone: telephone,
        numRue: numRue,
        zipcode: codePostale,
        email: email
      }, config);
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      return error;
    }
  };

  /* delete adress */
  const delete_adress = async (index) => {
    try {
      const response = await axios.delete(`${API_URL}adresse/update/${index}`);
      if (response.status == 200) {
        getShippingAdress();
      }
    } catch (error) {
      return error;
    }
  };

  /* sale */
  /* add sale */
  const save_sale = async (shippingAdress, amount, shoppingCartItem,
    setInvoiceInfos, isSaveItemSale, isPopup5, isPopup6, isDone) => {
    isPopup5(true);
    const date = new Date();
    let localDate = date.toLocaleString();
    try {
      let response = await axios.post(`${API_URL}order/create`, {
        id: user ? user.id : localStorage.getItem("userId__"),
        id_addr: shippingAdress,
        payment_method: "FeexPay",
        orderitem: shoppingCartItem,
        total_price: amount,
        date: localDate
      }, config);
      if (response.status === 201) {
        isSaveItemSale(true);
        isDone(true);
        setInvoiceInfos(response.data);
      } else {
        isPopup5(false);
        isPopup6(true);
      }
    } catch (error) {
      return error;
    }
  };

  /* send facture */
  const sendInvoice = async (order, date, shippingAdress, isPopup8, isPopup9, isDone2) => {
    isPopup8(true);
    try {
      const response = await axios.post(`${API_URL}order/send-invoice`, {
        order: order,
        date: date,
        shippingAdress: shippingAdress,
      }, config);
      if (response.status === 200) {
        isDone2(true);
        destroyShoppingCart(user ? user.id : localStorage.getItem("userId__"));
      } else {
        isPopup8(false);
        isPopup9(true);
      }
    } catch (error) {
      isPopup8(false);
      isPopup9(true);
      return error;
    }
  };

  // destroy cart shopping
  const destroyShoppingCart = async (id) => {
    try {
      await axios.delete(`${API_URL}shoppingCart/delete/${id}`, config);
    } catch (error) {
      return error;
    }
  };

  // update order for user
  const updateOrderStatus = async (id_order, isDone) => {
    if (user) {
      try {
        const response = await axios.put(`${API_URL}order/update/user/${id_order}`);
        if (response.status === 200) {
          isDone(true);
        }
      } catch (error) {
        return error;
      }
    }
  };

  /* ******************* store ********************* */
  /* get store for users */
  const getStore = async () => {
    if (user) {
      let id = user.id;
      try {
        const response = await axios.get(`${API_URL}user/stores/${id}`);
        const data = response.data;
        if (response.status === 200) {
          setStore(data);
        }
      } catch (error) {
        return error;
      }
    }
  };

  // get categorie by store
  const getCategoriesByStore = async () => {
    let path = window.location.pathname.split("/");
    let id = path[2];

    try {
      const response = await axios.get(`${API_URL}categorie/by-store/${id}`, config);
      if (response.status === 200) {
        setCategorieByStore(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  /* update status store select */
  const update_status_store = async (setLink) => {
    if (user) {
      try {
        const response = await axios.put(`${API_URL}store/update-status/${user.id}`);
        if (response.status === 200) {
          let data = response.data;
          let origin = window.location.origin;
          for (let i = 0; i < data.length; i++) {
            if (data[i].is_active === true) {
              localStorage.setItem("link", `${origin}/Tableau-de-bord/${data[0].companyName.replaceAll(" ", "")}`);
              setLink(`${origin}/Tableau-de-bord/${data[0].companyName.replaceAll(" ", "")}`);
            }
          }
        }
      } catch (error) {
        return error;
      }
    }
  };

  /* get product to store */
  const getProductToStore = async (store_id) => {
    try {
      const response = await axios.get(`${API_URL}product/store/${store_id}`, config);
      let data = response.data;
      if (response.status === 200) {
        setProductStore(data);
      }
    } catch (error) {
      return error;
    }
  };

  /* get product to store */
  const [productShop, setProductShop] = useState([]);
  const getProductToShop = async () => {
    let store_id = window.location.pathname.split("/")[1];
    try {
      const response = await axios.get(`${API_URL}product/store/${store_id}`, config);
      let data = response.data;
      if (response.status === 200) {
        setProductShop(data);
      }
    } catch (error) {
      return error;
    }
  };

  // get product by categorie for store 
  const getProductByCategoriesToStore = async (idCat) => {
    let path = window.location.pathname.split("/");
    let id = path[2];

    try {
      const response = await axios.post(`${API_URL}product/store/by-categorie`, {
        idCat: idCat,
        store_id: id
      }, config);
      if (response.status === 200) {
        setProductStore(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  // get product by sub categorie for store 
  const getProductBySubCategoriesToStore = async (idSubCat) => {
    let path = window.location.pathname.split("/");
    let id = path[2];

    try {
      const response = await axios.post(`${API_URL}product/store/by-subcategorie`, {
        idSubCat: idSubCat,
        store_id: id
      }, config);
      if (response.status === 200) {
        setProductStore(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  // get product in promotion by store
  const [productPromoByStore, setProductPromoByStore] = useState([]);
  const getProductPromoByStore = async (store_id) => {
    try {
      const response = await axios.get(`${API_URL}product/promo-store/${store_id}`, config);
      if (response.status === 200) {
        setProductPromoByStore(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  /* get product cobeille */
  const getProductTrash = async (storeId) => {
    try {
      const response = await axios.get(`${API_URL}product/trash-list/${storeId}`, config);
      if (response.status === 200) {
        setProductTrash(response.data.products);
      }
    } catch (error) {
      return error;
    }
  };

  /* get profile store */
  const getProfileStore = async (store_id) => {
    try {
      const response = await axios.get(`${API_URL}store/profile/${store_id}`, config);
      if (response.status === 200) {
        setProfileStore(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  /* get store site */
  const getStoreProfile = async (store_id) => {
    try {
      const response = await axios.get(`${API_URL}store/profile/${store_id}`, config);
      if (response.status === 200) {
        setStoreProfile(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  /* get order by user */
  const getOrderByUser = async () => {
    if (user || userIdLocal) {
      try {
        const response = await axios.get(`${API_URL}order/get/${user ? user.id : userIdLocal}`, config);
        if (response.status === 200) {
          setOrders(response.data);
        }
      } catch (error) {
        return error;
      }
    }
  };

  /* get stats store */
  const getStatsStore = async () => {
    if (user) {
      try {
        const response = await axios.get(`${API_URL}store/details/${user.id}`, config);
        const data = response.data;
        if (response.status === 200) {
          setStatsStore(data);
        }
      } catch (error) {
        return error;
      }
    }
  };

  /* get sale store */
  const [sales, setSales] = useState([]);
  const [orderss, setOrderss] = useState([]);

  const getSaleStore = async (store_id) => {
    if (user || userAdmin) {
      try {
        const response = await axios.get(`${API_URL}store/order-sale/${store_id}`, config);
        if (response.status === 200) {
          setSales(response.data.sales);
          setOrderss(response.data.orders);
        }
      } catch (error) {
        return error;
      }
    }
  };

  /* get sale trash */
  const getSaleTrashStore = async (store_id) => {
    if (user) {
      try {
        const response = await axios.get(`${API_URL}store/get-sale-trash/${store_id}`, config);
        if (response.status === 200) {
          setSaleTrash(response.data);
        }
      } catch (error) {
        return error;
      }
    }
  };

  /*  save abonnement store */
  const saveSubscribe = async (store_id, subscribe, isPopup2) => {
    if (user) {
      try {
        const response = await axios.put(`${API_URL}store/subcription`, {
          store_id: store_id,
          subscribe: subscribe,
        }, config);
        if (response.status === 200) {
          isPopup2(true);
        }
      } catch (error) {
        return error;
      }
    }
  };

  // update status sale
  const updateSaleStatus = async (order_id, status_delivr, dateDelivr) => {
    try {
      const response = await axios.put(`${API_URL}order/sale-update/${order_id}`, {
        status_delivr: status_delivr,
        date_delivr: status_delivr ? dateDelivr : null
      }, config);
      if (response.status == 200) {
        window.location.reload();
      }
    } catch (error) {
      return error;
    }
  };

  // delete order sale store
  const deleteSaleStore = async (order_id) => {
    try {
      const response = await axios.delete(`${API_URL}order/sale-add-trash/${order_id}`, config);
      if (response.status == 200) {
        window.location.reload();
      }
    } catch (error) {
      return error;
    }
  };

  /*  add item to cart store */
  const add_cart_store_item = async (store_id, isPopup) => {
    if (user) {
      try {
        const response = await axios.post(`${API_URL}store/create-cart`, {
          cart_item: order_item,
          store_id: store_id,
        }, config);
        if (response.status === 201) {
          isPopup(5);
          setCartStoreId(response.data.token);
          dispatch(clearCart());
          sessionStorage.setItem("cartStoreId", response.data.token);
        } else {
          alert("Le panier a été déjà créé");
        }
      } catch (error) {
        return error;
      }
    }
  };

  /* delete cart store */
  const delete_cart_store = async (store_id, isShowDiv) => {
    if (user) {
      try {
        const response = await axios.delete(`${API_URL}store/delete-cart/${store_id}`, config);
        if (response.status == 200) {
          alert("Panier supprimer avec succès");
          isShowDiv(false);
          sessionStorage.removeItem("cartStoreId");
        }
      } catch (error) {
        return error;
      }
    }
  };

  /* get cart store item */
  const getCartStoreItem = async (cart_id) => {
    try {
      const response = await axios.post(`${API_URL}store/get-item-cart/${cart_id}`, config);
      if (response.status === 200) {
        setCartItemStore(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  // save followers
  const saveFollowers = async (store_id) => {
    if (user) {
      try {
        const response = await axios.post(`${API_URL}store/save-followers`, {
          id: user.id,
          store_id: store_id
        }, config);
      } catch (error) {
        return error;
      }
    } else {
      window.location.href = LOGIN;
    }
  };



  /* ******** operation  article ***********/
  /************** article ********** */
  const [articles, setArticles] = useState([]);
  const getArticle = async () => {
    try {
      const response = await axios.get(`${API_URL}article/get-all`);
      if (response.status === 200) {
        setArticles(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  const [articleAdmin, setArticleAdmin] = useState([]);
  const getArticleAdmin = async () => {
    try {
      const response = await axios.get(`${API_URL}article/get-by-admin`);
      if (response.status === 200) {
        setArticleAdmin(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  const [oneArticle, setOneArticle] = useState();
  const getArticleById = async (id) => {
    try {
      const response = await axios.get(`${API_URL}article/by-id/${id}`);
      if (response.status === 200) {
        setOneArticle(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  const [articleSimilar, setArticleSimilar] = useState([]);
  const getArticleSimilar = async () => {
    let categorieId = window.location.pathname.split("=")[0].split("catid")[1];
    try {
      const response = await axios.get(`${API_URL}article/by-categorie/${categorieId}`);
      if (response.status === 200) {
        setArticleSimilar(response.data);
      }
    } catch (error) {
      return error;
    }
  };



  // get categorie by article
  const getCategorieByArticle = async (id) => {
    try {
      const response = await axios.get(`${API_URL}article/by-categorie/${id}`);
      if (response.status === 200) {
        setArticles(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  // add article
  const addArticle = async (formData, setMsgSucces, isFail, isDone, isPopup) => {
    isPopup(true);
    try {
      const response = await axios.post(`${API_URL}article/create`, formData);
      if (response.status === 201) {
        isDone(true);
        setMsgSucces("Article ajouté avec succès !");
      } else {
        isPopup(false);
        isFail(true);
      }
    } catch (error) {
      isPopup(false);
      isFail(true);
      return error;
    }
  };

  // update article
  const updateArticle = async (id_art, formData, setMsgSucces,
    isFail, isDone, isPopup) => {
    isPopup(true);
    try {
      const response = await axios.put(`${API_URL}article/update/${id_art}`, formData);
      if (response.status === 200) {
        isDone(true);
        setMsgSucces("Article modifier avec succès !");
      } else {
        isPopup(false);
        isFail(true);
      }
    } catch (error) {
      isFail(true);
      isPopup(false);
      return error;
    }
  };

  // publich article
  const publishArticle = async (id_art, setMsgSucces,
    isFail, isDone, isPopup) => {
    isPopup(true);

    try {
      const response = await axios.put(`${API_URL}article/publish/${id_art}`);
      if (response.status === 200) {
        isDone(true);
        setMsgSucces("Article publier avec succès !");
      } else {
        isPopup(false);
        isFail(true);
      }
    } catch (error) {
      isPopup(false);
      isFail(true);
      return error;
    }
  };

  // no publich article
  const NoPublishArticle = async (id_art, setMsgSucces,
    isFail, isDone, isPopup) => {
    isPopup(true);
    try {
      const response = await axios.put(`${API_URL}article/no-publish/${id_art}`);
      if (response.status === 200) {
        isDone(true);
        setMsgSucces("Article retirer des publications avec succès !");
      } else {
        isPopup(false);
        isFail(true);
      }
    } catch (error) {
      isPopup(false);
      isFail(true);
      return error;
    }
  };

  // delete article
  const deleteArticle = async (id, setMsgSucces, isFail, isDone, isPopup) => {
    isPopup(true);
    try {
      const response = await axios.delete(`${API_URL}article/delete/${id}`);
      if (response.status === 200) {
        isDone(true);
        setMsgSucces("Article supprimer avec succès !");
      } else {
        isPopup(false);
        isFail(true);
      }
    } catch (error) {
      isPopup(false);
      isFail(true);
      return error;
    }
  };

  // save comment article
  const createReviewsByArticle = async (comment, id_at, msg) => {
    if (!user) {
      let date = new Date();
      let dd = date.toLocaleString();

      try {
        const response = await axios.post(`${API_URL}reviews-article/create`, {
          review: comment,
          userId: 8,
          date_review: dd,
          articleId: id_at
        });
        if (response.status === 201) {
          window.location.reload();
        } else {
          msg.innerText = "Commentaire non envoyer. Veuillez résessayer";
        }
      } catch (error) {
        return error;
      }
    } else {
      msg.innerText = "Connctez-vous pour envoyer votre commentaire";
    }
  };

  // get  categorie artcile
  const [categoryArticle, setCategoryArticle] = useState([]);
  const getCategoriesArticle = async () => {
    try {
      const response = await axios.get(`${API_URL}categorie-article/getall`);
      if (response.status === 200) {
        setCategoryArticle(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  // add article
  const addCategorieArticle = async (nomCat, setMsgSucces, isFail, isDone, isPopup) => {
    let date = new Date();
    let datecurrent = date.toLocaleString();
    isPopup(true);

    try {
      const response = await axios.post(`${API_URL}categorie-article/create`, {
        nomCat: nomCat,
        createdAt: datecurrent
      });
      if (response.status === 201) {
        isDone(true);
        setMsgSucces("Catégorie ajouté avec succès !");
      } else {
        isFail(true);
      }
    } catch (error) {
      isFail(true);
      return error;
    }
  };

  // update article
  const updateCategorieArticle = async (id, nomCat, setMsgSucces, isFail, isDone, isPopup) => {
    isPopup(true);

    try {
      const response = await axios.put(`${API_URL}categorie-article/update/${id}`, {
        nomCat: nomCat
      });
      if (response.status === 200) {
        isDone(true);
        setMsgSucces("Catégorie mise à jour avec succès !");
      } else {
        isFail(true);
      }
    } catch (error) {
      isFail(true);
      return error;
    }
  };

  // delete article
  const deleteCategorieArticle = async (id, setMsgSucces, isFail, isDone, isPopup) => {
    isPopup(true);

    try {
      const response = await axios.delete(`${API_URL}categorie-article/delete/${id}`);
      if (response.status === 200) {
        isDone(true);
        setMsgSucces("Catégorie supprimer avec succès !");
      } else {
        isFail(true);
      }
    } catch (error) {
      isFail(true);
      return error;
    }
  };

  // get all store
  const [allStore, setAllStore] = useState([]);
  const getAllStore = async () => {
    try {
      const response = await axios.get(`${API_URL}store/get-all`);
      if (response.status === 200) {
        setAllStore(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  // create store by admin
  const createStoreByAdmin = async (firstname, lastname, email, country,
    city, password, store, phone, category, descStore, setEmailExist, isDone, isLaunch) => {
    let links = window.location.origin + ACTIVE_ACCOUNT_PAGE;

    try {
      const response = await axios.post(`${API_URL}account/signup`, {
        firstname: firstname,
        lastname: lastname,
        email: email,
        country: country,
        city: city,
        phone: phone,
        password: password,
        role: "seller",
        createBy: "admin",
        link: links
      });
      if (response.status === 201) {
        let user = response.data.userId;
        createStore(descStore, store, country, category, phone, user, isDone, "admin");
      } else {
        isLaunch(false);
        setEmailExist(true);
      }
    } catch (error) {
      return error;
    }
  };

  // delete store by admin
  const deleteStoreByAdmin = async (id_store) => {
    try {
      const response = await axios.delete(`${API_URL}store/delete/${id_store}`);
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      return error;
    }
  };

  // add categorie
  const addCategorie = async (cat, subcat, isDone, msg) => {
    try {
      const response = await axios.post(`${API_URL}categorie/add`, {
        categoryName: cat,
        subCategories: subcat
      });
      if (response.status === 201) {
        window.location.reload();
      } else {
        isDone(true);
      }
    } catch (error) {
      return error;
    }
  };

  //  update categorie
  const updateCategorie = async (id, cat, subcat, isDone, msg) => {
    try {
      const response = await axios.put(`${API_URL}categorie/update/${id}`, {
        categoryName: cat,
        subCategories: subcat
      });
      if (response.status === 201) {
        window.location.reload();
      } else {
        isDone(true);
      }
    } catch (error) {
      return error;
    }
  };

  // delete category
  const deleteCategory = async (id) => {
    try {
      const response = await axios.delete(`${API_URL}categorie/delete/${id}`);
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      return error;
    }
  };

  // stats site
  /* get stats store */
  const [statsSite, setStatsSite] = useState();
  const getStatsSite = async () => {
    if (userAdmin) {
      try {
        const response = await axios.get(`${API_URL}stats-site`);
        const data = response.data;
        if (response.status === 200) {
          setStatsSite(data);
        }
      } catch (error) {
        return error;
      }
    }
  };

  // send message
  const sendMailByUser = async (msg, email, fullname) => {
    try {
      const response = await axios.post(`${API_URL}send/mail-message`, {
        message: msg,
        email: email,
        fullname: fullname
      });
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      return error;
    }
  };

  // send demande amount for  sale
  const createTransactionRequest = async (amountRequested, storeId, isDone, isPopup, setMsg,
    isOpenForm) => {
    isPopup(true);
    let date = new Date();
    let dateCurrent = date.toLocaleString();
    try {
      const response = await axios.post(`${API_URL}request-transaction/create`, {
        store: storeId,
        amountReq: amountRequested,
        dateRequest: dateCurrent,
      });

      if (response.status === 201) {
        isOpenForm(false);
        isDone(true);
        setMsg("Demande de reversement envoyer avec succès !");
      } else {
        isOpenForm(false);
        isDone(true);
        setMsg("Vous avez déjà une demande de reversement en cour.");
      }
    } catch (error) {
      return error;
    }
  };

  // get all transaction
  const [transaction, setTransaction] = useState([]);
  const getAllTransaction = async () => {
    try {
      const response = await axios.get(`${API_URL}request-transaction/get-all`);
      if (response.status === 200) {
        setTransaction(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  // get transaction by store
  const getTransactionByStore = async (store_id) => {
    try {
      const response = await axios.get(`${API_URL}request-transaction/get-by-store/${store_id}`);
      if (response.status === 200) {
        setTransaction(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  const updateTransactionRequest = async (id, amount, isDone, isPopup, setMsg, isOpenForm) => {
    isPopup(true);
    try {
      const response = await axios.put(`${API_URL}request-transaction/update/${id}`, {
        amount: amount,
      });
      if (response.status === 200) {
        isDone(true);
        isOpenForm(false);
        setMsg("Demande modifié avec succès !");
      } else {
        isDone(true);
        isOpenForm(false);
        setMsg("Erreur de la modification. Veuillez réessayer.");
      }
    } catch (error) {
      return error;
    }
  };

  const deleteTransactionRequest = async (id, isDone, isPopup, setMsg) => {
    isPopup(true);
    try {
      const response = await axios.delete(`${API_URL}request-transaction/delete/${id}`);
      if (response.status === 200) {
        isDone(true);
        setMsg("Demande de transaction supprimer avec succès");
      }
    } catch (error) {
      return error;
    }
  };

  // approuver request transaction by admin
  const approuveRequestTransaction = async (id, isDone, isPopup, setMsg) => {
    isPopup(true);
    try {
      const response = await axios.put(`${API_URL}request-transaction/approuve/${id}`);
      if (response.status === 200) {
        isDone(true);
        setMsg("Demande de transaction approuvé avec succès");
      }
    } catch (error) {
      return error;
    }
  };

  // approuver request transaction by admin
  const requestTransactionSucces = async (id, isDone, isPopup, setMsg) => {
    isPopup(true);
    let date = new Date();
    let dateCurrent = date.toLocaleString();
    try {
      const response = await axios.put(`${API_URL}request-transaction/success/${id}`);
      if (response.status === 200) {
        isDone(true);
        setMsg("Demande de transaction satisfaire avec succès");
      }
    } catch (error) {
      return error;
    }
  };

  // approuver request transaction by admin
  const rejectRequestTransaction = async (id, isDone, isPopup, setMsg) => {
    isPopup(true);
    try {
      const response = await axios.put(`${API_URL}request-transaction/reject/${id}`);
      if (response.status === 200) {
        isDone(true);
        setMsg("Demande de transaction rejecté avec succès");
      }
    } catch (error) {
      return error;
    }
  };

  // download file excel
  const downloadFileExcelTransaction = async (filter) => {
    try {
      const response = await fetch(`${API_URL}request-transaction/download-file/${filter}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        },
        responseType: "blob"
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);

        // Créer un lien de téléchargement et le déclencher
        const link = document.createElement("a");
        link.href = url;
        link.download = "transactions.xlsx";
        link.click();

        // Libérer l'URL d'objet
        URL.revokeObjectURL(url);
      } else {
        console.error("Erreur lors du téléchargement du fichier Excel");
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    getStore();
    getUserData();
    getDataLocation();
    getAllProduct();
    getWhishlist();
    getShippingAdress();
    getCategoryData();
    getOrderByUser();
    getShoppingCartItem();
    getCategoriesByStore();
    getProductToStore();
    getArticleSimilar();
    getProductToShop();
  }, []);

  const contextData = {
    sendMailToStore,
    /* location */
    location,
    sendEmail,
    reset_pwd,

    /* user */
    userData,
    createCartUser,
    shoppingCartItem,
    updateShoppingCartItemQty,
    deleteShoppingCartItem,

    /* product */
    allProducts,
    productOne,
    getProdDetails,
    prodDetails,
    reviews,
    store,
    shippingAdress,
    answers,
    whishlist,
    add_favoris,
    get_code_country,

    /* comment of product */
    save_comment,

    /* message */
    getStore,

    /* send invoice */
    sendInvoice,

    /* adress */
    getShippingAdress,
    createShippingAdress,
    updateShippingAdress,
    delete_adress,

    /* order */
    save_sale,
    updateSaleStatus,
    deleteSaleStore,
    updateOrderStatus,

    /* store part */
    getProductToShop,
    productShop,
    update_status_store,
    getProductSearch,
    getProductToStore,
    getProductByCategoriesToStore,
    getProductBySubCategoriesToStore,
    getProfileStore,
    getProductTrash,
    updateReduce,
    deleteProduct,
    deleteDefinitiveProduct,
    restoreProduct,
    getStoreProfile,
    getCategoriesByStore,
    getProductBySubCategorie,
    getProductByCategorie,
    getProductPromoByStore,
    getFilterProductMarket,
    getProductSearchStore,
    getCountSold,
    saveSubscribe,
    publishProduct,
    getCartStoreItem,
    delete_cart_store,
    cartStoreId,
    cartItemStore,
    productPromoByStore,
    categoryProduct,
    setCategoryProduct,
    getCategoryProduct,
    countSoldProd,
    productSearchStore,
    filterProductByPrice,
    filterProductBySize,
    filterProductByColor,
    orders,
    categoryMarket,
    categorieByStore,
    storeProfile,
    category,
    itemSubCat,
    subCat,
    categories,
    productStore,
    productTrash,
    profileStore,
    productSearch,
    add_cart_store_item,
    saveFollowers,

    /* dashboard */
    create_product,
    getStatsStore,
    getSaleStore,
    getSaleTrashStore,
    saleTrash,
    saleStore,
    statsStore,

    /* admin export  */
    createStoreByAdmin,
    getAllStore,
    allStore,
    deleteStoreByAdmin,

    // categorie operation
    addCategorie,
    updateCategorie,
    deleteCategory,

    /* article */
    getArticle,
    getCategorieByArticle,
    articles,
    addArticle,
    publishArticle,
    NoPublishArticle,
    updateArticle,
    deleteArticle,
    createReviewsByArticle,
    oneArticle,
    articleSimilar,
    getArticleById,
    getArticleSimilar,

    getCategoriesArticle,
    categoryArticle,
    addCategorieArticle,
    updateCategorieArticle,
    deleteCategorieArticle,

    sendEmailAdmin,

    // stats site
    getStatsSite,
    statsSite,
    sales,
    orderss,
    updateProductTendanceOrCollection,

    // send mail message
    sendMailByUser,

    createTransactionRequest,
    updateTransactionRequest,
    deleteTransactionRequest,
    requestTransactionSucces,
    getAllTransaction,
    getTransactionByStore,
    transaction,
    approuveRequestTransaction,
    rejectRequestTransaction,
    downloadFileExcelTransaction,
    getArticleAdmin,
    articleAdmin
  };

  return (
    <GeneralContext.Provider value={contextData}>
      {children}
    </GeneralContext.Provider>
  );
};
