import React, { useContext, useEffect, useState } from "react";

import "./Newsletter.scss";

import { HeadTabs } from "../../../../components/headTabs/HeadTabs";
import { BoxAlertSucess } from "../../../../components/boxAlertSucess/BoxAlertSucess";
import Pagination from "../../../../components/pagination2/Pagination";
import AuthContext from "../../../../context/AuthContext";

const NewsletterPage = () => {

    const { emailNewsletter, getNewsletter,downloadFileExcelEmail } = useContext(AuthContext);

    const [startNbPage, setStartNbPage] = useState(10);
    const [nextPage, setNextPage] = useState(startNbPage);
    const [previouspage, setPreviousPage] = useState(0);
    const [resultSearch, setResultSearch] = useState([]);

    const [done, isDone] = useState(false);
    const [popup, isPopup] = useState(false);
    const [msg, setMsg] = useState(null);


    useEffect(() => {
        getNewsletter();
    }, []);

    return (
        <div className="revenu-fond-page">
            <HeadTabs title={"La Newsletter"} />
            <BoxAlertSucess popup={popup}
                text={msg}
                handleBtn={isPopup} isDone={isDone} done={done}
                link={"#"} />
            <div className='block-revenu parent'>
                <div className="download-file-excel">
                    <button onClick={() => downloadFileExcelEmail()}>Télécharger la liste</button>
                </div>
                <div className="child">
                    <table className="table-data">
                        <thead>
                            <tr>
                                <th>N°</th>
                                <th>Email</th>
                                <th>Date création</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                emailNewsletter.length > 0 && (
                                    emailNewsletter.map((list, index) => {
                                        if (previouspage <= index && (nextPage - 1) >= index) {
                                            return <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{list.email}</td>
                                                <td>{list.createdAt}</td>
                                            </tr>;
                                        }
                                    })
                                )
                            }
                        </tbody>
                    </table>
                    {resultSearch.length == 0 ? (
                        <Pagination nb={startNbPage}
                            countTab={emailNewsletter.length > 0 ? emailNewsletter.length : 0}
                            setNextt={setNextPage}
                            setPrevious={setPreviousPage}
                            nextt={nextPage}
                            previous={previouspage} />
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default NewsletterPage;
