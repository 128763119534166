import React, { useEffect, useState } from "react";
import { FiChevronDown, FiSearch } from "react-icons/fi";
import { countries } from "../countrylist/countrylist";
import { country } from "../currencyList/countries";

import "./SelectCountryField.scss";

const SelectCountryField = ({ country_name, setDialCode, setCountry }) => {

  const [countrys, setCountrys] = useState(country_name);
  const [code, setCode] = useState();
  const [searchField, setSearchField] = useState("");
  const [opens, isOpen] = useState(false);

  const selectedCountry = (name, code) => {
    setCountrys(name);
    setCountry(name);
    setCode(code.toLowerCase());
    const currency = country.find(
      (item) => item.countryName === name
    );

    if (currency) {
      sessionStorage.setItem("currency", currency.currencyCode);
      sessionStorage.setItem("code_country", currency.countryCode.toLowerCase());
    }
    isOpen(!opens);
  };

  const open = {display: "block"};
  const close = {display: "none"};

  useEffect(() => {
    if (countrys) {
      let find = country.find(
        (item) => item.countryName == countrys
      );

      if (find) {
        setCode(find.countryCode.toLowerCase());
      }

      let find2 = countries.find(
        (item) => item.name == countrys
      );

      if (find2) {
        setDialCode(find2.dial_code);
      }
    }

  }, [code, countrys]);


  return (
    <div className="select-country">
      <div className="selected" onClick={() => isOpen(!opens)}>
        <div className="selected-item-options">
          {countrys ? (
            <>
              <i className={`flag-icon flag-icon-${code}`}>
              </i>
              <span> <input type="text" value={countrys} /> </span>
            </>
          ) : <span>Sélectionner un pays</span>}
        </div>
        <FiChevronDown className="arrow" />
      </div>
      <div className="options-select" style={opens ? open:close}>
        <div className='search-country-div'>
          <FiSearch />
          <input type="text" name="country"
            placeholder='Rechercher...'
            value={searchField}
            onChange={e => setSearchField(e.target.value)} />
        </div>
        {countries.map((list, index) => {
          if (!searchField) {
            return <li className="option"
              onClick={() => selectedCountry(list.name, list.code)}
              key={index}>
              <i className={`flag-icon flag-icon-${list.code.toLowerCase()}`}></i>
              <span>  {list.name} </span>
            </li>;
          } else {
            let a = list.name.toLowerCase();
            let b = searchField.toLowerCase();
            if (a.includes(b)) {
              return <li className="option"
                onClick={() => selectedCountry(list.name, list.code)}
                key={index}>
                <i className={`flag-icon flag-icon-${list.code.toLowerCase()}`}></i>
                <span>  {list.name} </span>
              </li>;
            }
          }
        })}
      </div>
    </div>
  );
};

export default SelectCountryField;
