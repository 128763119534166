import React, { useContext, useEffect, useRef, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { FiEdit3, FiEye, FiTrash2 } from "react-icons/fi";
import { HeadTabs } from "../../../../components/headTabs/HeadTabs";
import Pagination from "../../../../components/pagination2/Pagination";
import SearchBar from "../../../../components/searchBar2/SearchBar";
import { API_URL } from "../../../../settings/constant";

import "./products.scss";
import GeneralContext from "../../../../context/GlobalContext";
import { PopupLayout } from "../../../../components/popupLayout/PopupLayout";
import { BoxAlertSucess } from "../../../../components/boxAlertSucess/BoxAlertSucess";
import { useSelector } from "react-redux";


const ListProduct = () => {
  const [popup, isPopup] = useState(false);
  const [popup2, isPopup2] = useState(false);

  const [searchWord, setSearchWord] = useState("");
  const [startNbPage, setStartNbPage] = useState(4);
  const [nextPage, setNextPage] = useState(startNbPage);
  const [previouspage, setPreviousPage] = useState(0);
  const [resultSearch, setResultSearch] = useState([]);
  const [storeName, setStoreName] = useState(null);

  const [reducePercent, setReducePercent] = useState();
  const [nameProd, setNameProd] = useState("");
  const [idProd, setIdProd] = useState("");

  const [done, isDone] = useState(false);


  const [cartLink, setCartLink] = useState([]);

  const { productStore, getProductToStore,
    store, updateReduce, deleteProduct } = useContext(GeneralContext);


  /* return store active */
  const returnStore = () => {
    if (store && store.length > 0) {
      let find = store.find(
        (item) => item.is_active === true
      );

      if (find) {
        setStoreName(find.companyName.replaceAll(" ", ""));
        getProductToStore(parseInt(find.store_id));
      }
    }
  };

  /* search product */
  const search = async () => {
    if (searchWord) {
      const tab = productStore.filter(
        (item) => item.name_prod.toLowerCase().includes(searchWord.toLowerCase()) ||
          item.category.nameCat.toLowerCase().includes(searchWord),
      );

      if (tab.length != 0) {
        setResultSearch(tab);
      } else {
        setResultSearch([]);
      }
    }
  };

  /* update product */
  const update_product = (product) => {
    sessionStorage.setItem("product", JSON.stringify(product));
    window.location.href = `/Tableau-de-bord/${storeName}/nouveau-produit`;
  };

  /* handle update product of reduce percent */
  const handleReduceUpdate = () => {
    updateReduce(idProd, reducePercent, isDone, setReducePercent);
  };

  /* delete product */
  const handleDelete = (id_prod) => {
    deleteProduct(id_prod, isDone, isPopup2);
  };

  /* set reduction of promotion */
  const setReduce = (name_prod, prod_id) => {
    isPopup(true);
    setNameProd(name_prod);
    setIdProd(prod_id);
  };

  /* return link store */
  const getLinkStore = () => {
    let link = "";
    let origin = window.location.origin;
    if (store.length > 0) {
      let find = store.find(
        (item) => item.is_active === true,
      );

      if (find) {
        link = `${origin}/${find.companyName.replaceAll(" ", "")}/${find.store_id}`;
      }
    }
    return link;
  };

  /* render table column data */
  const renderColumnData = (tableData) => {
    return (
      tableData.map((list, index) => {
        if(!list.is_delete) {
          if (previouspage <= index && (nextPage - 1) >= index) {
            return <tr key={index}>
              <td> {index + 1} </td>
              <td> <img src={`${API_URL}${list.image_prod}`} /> </td>
              <td> {list.name_prod} </td>
              <td> {list.sizes ? list.sizes: "-"} </td>
              <td> {list.colors ? list.colors: "-"} </td>
              <td> {list.Category !== null ? list.Category.nameCat : null} </td>
              <td> {Math.round(list.price_prod)} {list.currency} </td>
              <td> {list.reduces} </td>
              <td>
                <button> <a href={getLinkStore()} target='_blank' rel="noreferrer"> <FiEye /><br /> Voir</a> </button>
                <button onClick={() => setReduce(list.name_prod, list.id_prod)}> <FiEdit3 /> <br /> Mettre en promotion </button>
                <button onClick={() => update_product(list)}> <FiEdit3 /> <br /> Editer </button>
                <button onClick={() => handleDelete(list.id_prod)}> <FiTrash2 /> <br /> Supprimer </button>
              </td>
            </tr>;
          }
        }
      })
    );
  };


  useEffect(() => {
    returnStore();
    search();
  }, [store, searchWord, cartLink]);


  return (
    <div className="product-dashboard-buyer">
      <HeadTabs title={"Produits"} />
      <PopupLayout closes={popup}>
        <div className='block-form-promo'>
          <span className='close-x' onClick={() => {
            isDone(false);
            isPopup(false);
            setReducePercent("");
            window.location.reload();
          }}>&times;</span>
          {
            !done ? (
              <>
                <p className='text'>
                  Mettez votre produit en promotion
                </p>
                <div className='box'>
                  <p>
                    Nom Produit: <b> {nameProd} </b>
                  </p>
                  <p>
                    Réduction:
                    <input type="text" name='reduce'
                      placeholder='Pourcentage de réduction'
                      value={reducePercent}
                      onChange={e => setReducePercent(e.target.value)}
                    /> %
                  </p>
                  <button onClick={handleReduceUpdate}> Appliquer </button>
                </div>
              </>
            ) : (
              <>
                <BoxAlertSucess popup={popup}
                  text="Réduction Appliquée avec succès !"
                  handleBtn={isPopup} isDone={isDone} done={done}
                  link={"#"} />
              </>
            )
          }
        </div>
      </PopupLayout>
      <BoxAlertSucess popup={popup2}
        text="Produit envoyé dans la cobeille avec succès !"
        handleBtn={isPopup2} isDone={isDone} done={done}
        link={"#"} />

      <div className="product-box parent">
        <div className="btn-add-product">
          <button
            onClick={() => window.location.href = `/Tableau-de-bord/${storeName}/nouveau-produit`}> <FaPlus /> Nouveau produit </button>
        </div>
        <div className="list-product child">
          <>
            <SearchBar placeholderText="Rechercher par nom Product"
              searchWord={searchWord} allList={productStore}
              setList={setResultSearch}
              setWord={setSearchWord} />
            <table className="table-data">
              <thead>
                <tr>
                  <th> ID </th>
                  <th> Image </th>
                  <th> Nom du Produits </th>
                  <th> Tailles </th>
                  <th> Couleurs </th>
                  <th> Catégorie </th>
                  <th> Prix unité </th>
                  <th> Réduction </th>
                  <th> Actions </th>
                </tr>
              </thead>
              <tbody>
                {
                  productStore.length > 0 ? (
                    resultSearch.length == 0 ? (
                      productStore.length !== 0 && (
                        renderColumnData(productStore)
                      )
                    ) : (
                      renderColumnData(resultSearch)
                    )
                  ) : null
                }
              </tbody>
            </table>
            {resultSearch.length == 0 ? (
              <Pagination nb={startNbPage}
                countTab={productStore.length}
                setNextt={setNextPage}
                setPrevious={setPreviousPage}
                nextt={nextPage}
                previous={previouspage} />
            ) : null}
          </>

        </div>
      </div>
    </div>
  );
};

export default ListProduct;
