import React, { useContext, useEffect, useState } from "react";
import { FiDelete, FiEdit3 } from "react-icons/fi";

import GeneralContext from "../../../../context/GlobalContext";
import { validInputText } from "../../../../components/controlFields/ControlField";
import SearchBar from "../../../../components/searchBar2/SearchBar";
import Pagination from "../../../../components/pagination2/Pagination";

const AddEtiquete = () => {
  const [searchWord, setSearchWord] = useState("");
  const [startNbPage, setStartNbPage] = useState(6);
  const [nextPage, setNextPage] = useState(startNbPage);
  const [previouspage, setPreviousPage] = useState(0);
  const [resultSearch, setResultSearch] = useState([]);

  const [categorie, setCategorie] = useState(null);
  const [subCategorie, setSubCategorie] = useState(null);
  const [done, isDone] = useState(false);
  const [idCat, setIdCat] = useState(null);

  const { articles, getArticle, addCategorie,
    deleteCategory, updateCategorie } = useContext(GeneralContext);

  // search 
  const searchArticle = () => {
    if (searchWord) {
      const tab = articles.filter(
        (item) => item.nameCat.toLowerCase().includes(searchWord.toLowerCase())
      );

      if (tab.length > 0) {
        setResultSearch(tab);
      } else {
        setResultSearch([]);
      }
    }
  };

  const updateCat = (list) => {
    setIdCat(list.idCat);
    setCategorie(list.nameCat);

    let text = document.getElementById("text");
    let btn = document.getElementById("btn");
    text.innerText = "Modification";
    btn.innerText = "Modifier";
    let subs = "";
    const sub = list.SubCategories;
    for (let i = 0; i < sub.length; i++) {
      subs += sub[i].nameSubCat + "/";
    }
    setSubCategorie(subs);
  };

  // add categorie
  const handleAddCategoie = e => {
    e.preventDefault();

    validInputText(categorie, "cat-err", "text");

    const subs = [];
    if (validInputText(categorie, "cat-err", "text")) {
      if (subCategorie) {
        let split = subCategorie.split("/");
        if (split && split.length > 0) {
          for (let i = 0; i < split.length; i++) {
            subs.push(split[i].trim());
          }
        }
      }
      if (idCat) {
        updateCategorie(idCat, categorie, subs, isDone);
      }
      else {
        addCategorie(categorie, subs, isDone);
      }
    }
  };

  useEffect(() => {
    // search automatik
    searchArticle();

    // get article
    getArticle();
  }, []);

  return (
    <div className="add-article">
      <div className="child">
        <SearchBar placeholderText="Rechercher par nom categorie"
          searchWord={searchWord} allList={articles.length > 0 ? articles : null}
          setList={setResultSearch}
          setWord={setSearchWord} />
        <table className="table-data">
          <thead>
            <tr>
              <th> N° </th>
              <th> Nom Etiquette </th>
              <th> Description </th>
              <th> Date de création </th>
              <th> Actions </th>
            </tr>
          </thead>
          <tbody>
            {
              resultSearch.length == 0 ? (
                articles.length > 0 && (
                  articles.map((list, index) => {
                    if (previouspage <= index && (nextPage - 1) >= index) {
                      return <tr key={index}>
                        <td> {index + 1} </td>
                        <td> {list.title} </td>
                        <td> {list.description} </td>
                        <td></td>
                        <td>
                          <button onClick={() => updateCat(list)}> <FiEdit3 /> </button>
                          <button onClick={() => deleteCategory(list.id_art)}> <FiDelete /> Supprimer </button>
                        </td>
                      </tr>;
                    }
                  })
                )
              ) : (
                resultSearch.map((list, index) => {
                  return <tr key={index}>
                    <td> {index + 1} </td>
                    <td> {list.title} </td>
                    <td> {list.description} </td>
                    <td></td>
                    <td>
                      <button onClick={() => updateCat(list)}> <FiEdit3 /> </button>
                      <button onClick={() => deleteCategory(list.id_art)}> <FiDelete /> Supprimer </button>
                    </td>
                  </tr>;
                })
              )

            }
          </tbody>
        </table>
        {resultSearch.length == 0 ? (
          <Pagination nb={startNbPage}
            countTab={articles.length > 0 ? articles.length : 0}
            setNextt={setNextPage}
            setPrevious={setPreviousPage}
            nextt={nextPage}
            previous={previouspage} />
        ) : null}
      </div>
      <div className="add-categorie">
        <form>
          <h3 id="text"> Nouvelle Catégorie </h3>
          {done ? <p className="msg-text">
            échec !
          </p> : null}
          <div className="div-input">
            <input type="text"
              placeholder="Nom du catégorie"
              value={categorie}
              onChange={e => setCategorie(e.target.value)}
            />
            <span className="err" id="cat-err"></span>
          </div>
          <div className="div-input">
            <textarea cols={5} rows={5}
              placeholder="description de la catégorie"
              value={subCategorie}
              onChange={e => setSubCategorie(e.target.value)}
            ></textarea>
            <span className="err" id="subcat-err"></span>
          </div>
          <button id="btn" onClick={handleAddCategoie}>
            Ajouter
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddEtiquete;