import React, { useContext, useEffect, useState } from "react";
import { FiDownload } from "react-icons/fi";

import "./Profil.scss";

import SelectCountryField from "../../../../components/selectCountryField/SelectCountryField";
import AuthContext from "../../../../context/AuthContext";
import { validInputText } from "../../../../components/controlFields/ControlField";
import { HeadTabs } from "../../../../components/headTabs/HeadTabs";
import GeneralContext from "../../../../context/GlobalContext";
import { BoxAlertSucess } from "../../../../components/boxAlertSucess/BoxAlertSucess";
import { API_URL } from "../../../../settings/constant";
import { verifyFile } from "../../../../components/verifyFile/verifyFile";

const ProfilSeller = () => {

  const { updateUserAccount } = useContext(AuthContext);
  const { userData, get_code_country, store } = useContext(GeneralContext);

  const [namesUser, setNames] = useState(null);
  const [countrys, setCountrys] = useState(null);
  const [city, setCity] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);

  const [storeId, setStoreId] = useState(null);
  const [nameStore, setNameStore] = useState(null);
  const [descStore, setDescStore] = useState(null);
  const [done, isDone] = useState(false);
  const [popup, isPopup] = useState(false);
  const [fail, isFail] = useState(false);
  const [msgText, setMsgText] = useState(null);
  const [dialCode, setDialCode] = useState(null);
  const [imgCouv, setImgCouv] = useState(null);
  const [cover, setCover] = useState(null);

  const [img, setImg] = useState(null);

  /* mise à jour du profile utilisateur */
  const updateUserProfile = async (e) => {
    e.preventDefault();

    const img_error = document.getElementById("img-error");
    const msg_res = document.getElementById("msg-res");

    validInputText(city, "city-error", "text");
    validInputText(phone, "phone-error", "tel2");
    validInputText(email, "email-error", "email");
    validInputText(nameStore, "store-error", "text");
    validInputText(descStore, "desc-error", "text");

    if (validInputText(city, "city-error", "text") &&
      validInputText(phone, "phone-error", "tel2") &&
      validInputText(nameStore, "store-error", "text") &&
      validInputText(descStore, "desc-error", "text")) {

      img_error.innerText = "";

      let nam = namesUser.trim();
      const formData = new FormData();
      formData.append("firstname", nam.split(" ")[0]);
      formData.append("lastname", nam.split(" ")[1]);
      formData.append("country", countrys);
      formData.append("city", city);
      formData.append("phone", phone);

      if (img !== null) {
        formData.append("image_profil", img);
      } else {
        formData.delete("image_profil");
      }

      let formData2 = new FormData();
      formData2.append("store_id", storeId);
      formData2.append("name_store", nameStore);
      formData2.append("desc_store", descStore);
      if (imgCouv !== null) {
        formData2.append("image_cover", imgCouv);
      } else {
        formData2.delete("image_cover", imgCouv);
      }

      updateUserAccount(formData, formData2, msg_res, isDone, isPopup, isFail, setMsgText);
    }
  };

  // return store active
  const returnStoreActive = () => {
    if (store && store.length > 0) {
      for (var i = 0; i < store.length; i++) {
        if (store[i].is_active == true) {
          setNameStore(store[i].companyName);
          setDescStore(store[i].desc_store);
          setStoreId(store[i].store_id);
          setCover(store[i].image_cover);
        }
      }
    }
  };

  useEffect(() => {
    if (userData) {
      setNames(userData.firstname + " " + userData.lastname);
      setCountrys(userData.country);
      setCity(userData.city);
      setEmail(userData.email);
      setPhone(userData.phone);
    }

    // return store active
    returnStoreActive();
  }, [store, userData, img, imgCouv]);

  //const renderer = ()

  return (
    <div className="profil-dashboard-buyer">
      <BoxAlertSucess popup={popup}
        text={msgText}
        handleBtn={isDone}
        isDone={isDone}
        done={done}
        link={"#"}
      />
      <HeadTabs title={"Profil"} />
      <div className="block-form">
        <div className="block-div1">
          <div className="div1">
            <form>
              <h3 className="title-h3"> Information du Compte </h3>
              {fail ? (
                <p id="msg-res">Echec de la mise du profil ! Veuillez Réessayer.</p>
              ) : null}
              <div className="div-input">
                <p>
                  <label> Nom et Prénom <span>*</span> </label>
                  <input type="text"
                    name="fullname"
                    placeholder="..."
                    value={namesUser}
                    onChange={(e) => setNames(e.target.value)} />
                </p>
                <span className="span-error" id="names-error"></span>
              </div>
              <div className="div-input">
                <p>
                  <label> Pays actuel<span>*</span></label>
                  <SelectCountryField country_name={countrys} setCountry={setCountrys}
                    setDialCode={setDialCode} />
                  {
                    countrys ? (
                      <span style={{ marginLeft: "1rem" }}>
                        <i className={`flag-icon flag-icon-${get_code_country(countrys)}`}></i>
                        <b> {countrys} </b>
                      </span>
                    ) : null
                  }
                </p>
                <span className="span-error" id="country-error"></span>
              </div>
              <div className="div-input">
                <p>
                  <label>Ville <span>*</span></label>
                  <input type="text"
                    name="city"
                    placeholder="..."
                    value={city}
                    onChange={(e) => setCity(e.target.value)} />
                </p>
                <span className="span-error" id="city-error"></span>
              </div>
              <div className="div-input">
                <p>
                  <label> Photo de profile <span>*</span></label>
                  <div className="file-img">
                    <FiDownload />
                    <span> {img !== null ? img.name : "Sélectionner un fichier"}  </span>
                    <input type="file" name="file" accept=".jpg,.png,.jpeg"
                      onChange={e => setImg(e.target.files[0])} />
                  </div>
                  <span className="img-namesUser"> {img !== null ? img.name + "  sélectionner" : null}</span>
                </p>
                <span className="text-img-accept">
                  Seul les images au format jpg, jpeg, png sont accepté avec une
                  taille minimum de 500 x 600
                </span>
                <span className="span-error" id="img-error"></span>
              </div>

              <div className="div-input">
                <p>
                  <label> Téléphone <span>*</span> </label>
                  <input type="tel" name="phone"
                    placeholder=""
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)} />
                </p>
                <span className="span-error" id="phone-error"></span>
              </div>
              <div className="div-input">
                <p>
                  <label> email <span>*</span></label>
                  <input type="text"
                    name="email"
                    placeholder="..."
                    value={email} />
                </p>
                <span className="span-error" id="email-error"></span>
              </div>
              <div className="div-input">
                <p>
                  <label> Nom boutique <span>*</span></label>
                  <input type="text"
                    name="email"
                    placeholder="..."
                    value={nameStore}
                    onChange={e => setNameStore(e.target.value)} />
                </p>
                <span className="span-error" id="store-error"></span>
              </div>
              <div className="div-input">
                <p>
                  <label> Description de la boutique <span>*</span> </label>
                  <textarea cols={3}
                    rows={3}
                    placeholder="..."
                    value={descStore}
                    onChange={e => setDescStore(e.target.value)}></textarea>
                </p>
                <span className="span-error" id="desc-error"></span>
              </div>
              <div className="div-input div-file">
                <p>
                  <label> Ajouter une photo de couverture à votre boutique <span>*</span></label>
                  <div className="file-img">
                    <FiDownload />
                    <span> {imgCouv !== null ? imgCouv.name : "Sélectionner une photo de couverture"}  </span>
                    <input type="file" name="file" accept=".jpg,.png,.jpeg"
                      onChange={e => setImgCouv(e.target.files[0])} />
                  </div>
                  {cover ? <img className="cover-img" src={`${API_URL}${cover}`} /> : null}
                </p>
                <span className="text-img-accept">
                  Seul les images au format jpg, jpeg, png sont accepté avec une
                  taille minimum de 1100 x 400
                </span>
                <span className="span-error" id="imgCouv-error"></span>
              </div>
              <button className="btn-update" onClick={updateUserProfile}> Mettre à jour </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilSeller;
