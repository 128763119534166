import { createSlice } from "@reduxjs/toolkit";

const cart = createSlice({
  name: "cart",
  initialState: {
    cartItem: [],
    isSuccess: false,
  },
  reducers: {
    addItem: (state, action) => {
      const { id_prod, colors, sizes } = action.payload;
      const item = state.cartItem.find(
        (item) => parseInt(item.id_prod) === parseInt(id_prod) &&
          item.colors === (colors ?? "") && item.sizes === (sizes ?? ""),
      );

      if (item) {
        item.quantity = (parseInt(item.quantity) + parseInt(action.payload.quantity));
      } else {
        state.cartItem.push({ ...action.payload });
      }

      state.isSuccess = true;
    },
    incrementQty: (state, action) => {
      const { id_prod, colors, sizes } = action.payload;
      const item = state.cartItem.find(
        (item) => parseInt(item.id_prod) === parseInt(id_prod) &&
          item.colors === (colors ?? "") && item.sizes === (sizes ?? ""),
      );

      if (item) {
        item.quantity++;
      }
    },
    decrementQty: (state, action) => {
      const { id_prod, colors, sizes } = action.payload;
      const item = state.cartItem.find(
        (item) => parseInt(item.id_prod) === parseInt(id_prod) &&
          item.colors === (colors ?? "") && item.sizes === (sizes ?? ""),
      );

      if (item.quantity === 1) {
        item.quantity = 1;
      } else {
        item.quantity--;
      }
    },
    removeItem: (state, action) => {
      const { id_prod, colors, sizes } = action.payload;

      const updatedCartItems = state.cartItem.filter(
        (item) => parseInt(item.id_prod) !== parseInt(id_prod) ||
          item.colors !== (colors ?? "") || item.sizes !== (sizes ?? ""),
      );

      state.cartItem = updatedCartItems;
    },
    clearCart: (state, action) => {
      state.cartItem = [];
    }
  },
});

export const {
  addItem,
  incrementQty,
  decrementQty,
  removeItem,
  clearCart
} = cart.actions;

export const cartReducer = cart.reducer;
