import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import { Link, useLocation } from "react-router-dom";
import {
    FacebookShareButton,
    FacebookIcon,
    WhatsappShareButton,
    WhatsappIcon,
    TwitterShareButton,
    TwitterIcon,
    PinterestShareButton,
    PinterestIcon
  } from "react-share";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ArticlePage.scss";

import { FaFacebookF, FaPinterest, FaTwitter } from "react-icons/fa";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import GeneralContext from "../../../context/GlobalContext";
import { API_URL } from "../../../settings/constant";
import { validInputText } from "../../../components/controlFields/ControlField";

const ArticlePage = (props) => {
    const location = useLocation();
    const data = location?.data;

    const { articles, createReviewsByArticle ,
        oneArticle,
        articleSimilar,
        getArticleById, getArticle } = useContext(GeneralContext);

    const [comment, setComment] = useState(null);
    const [filter, setFilter] = useState([]);

    const getFIlterArticleRelated = () => {
        let articleId = location.pathname.split("=")[1];
        if(articleSimilar.length > 0) {
            let filters = articleSimilar.filter(
                (item) => item.id_at !== articleId
            );
            if (filters.length > 0) {
                setFilter(filters);
            }
        }
    };
    
    const handleSaveComment = e => {
        e.preventDefault();
        let msg = document.querySelector("#msg-form");
        validInputText(comment, "comment-err", "text2");
        if (validInputText(comment, "comment-err", "text2")) {
            createReviewsByArticle (comment, oneArticle && oneArticle.id_at, msg);
        }
    };

    const settings3 = {
        dots: true,
        infinite: true,
        autoplaySpeed: 5000,
        slidesToScroll: 1,
        slidesToShow: 3,
        autoplay: true,
        arrows: true,
      };

      const getOneArticle = () => {
        let articleId = location.pathname.split("=")[1];
        if (articleId) {
            getArticleById(articleId);
        }
      };

      const getLinkArticle = () => {
        let path = window.location.href;
        return path;
      };

      const nextArticle = () => {
        let articleId = location.pathname.split("=")[1];
        if(articles.length > 0) {
            let indexPos = articles.findIndex(item => item.id_at === parseInt(articleId));

            if(indexPos < articles.length-1) {
                return articles[indexPos+1] ?articles[indexPos+1].title:"";
            } else if (indexPos === articles.length - 1) {
                return articles[indexPos].title;
            }
        }
      };

      const prevArticle = () => {
        let articleId = location.pathname.split("=")[1];
        if(articles.length > 0) {
            let indexPos = articles.findIndex(item => item.id_at === parseInt(articleId));

            if(indexPos > 0) {
                return articles[indexPos-1].title;
            } else if (indexPos === 0) {
                return articles[indexPos].title;
            }
        }
      };

      const btnNext = () => {
        let articleId = location.pathname.split("=")[1];
        if(articles.length > 0) {
            let indexPos = articles.findIndex(item => item.id_at === parseInt(articleId));

            if(indexPos < articles.length-1) {
                return `/article/${articles[indexPos+1].slug}catid=${articles[indexPos+1].id_at}`;
            } 
        }
      };

      const btnPrev = () => {
        let articleId = location.pathname.split("=")[1];
        if(articles.length > 0) {
            let indexPos = articles.findIndex(item => item.id_at === parseInt(articleId));

            if(indexPos > 0) {
                return `/article/${articles[indexPos-1].slug}catid=${articles[indexPos-1].id_at}`;
            } 
        }
      };

    useEffect(() => {
        getOneArticle();
        getArticle();
        if(articleSimilar) {
            getFIlterArticleRelated();
        }
    },[]);

    return (
        <div className="articles-page">
            <section className="section-one-art">
                <div className="head-arts">
                    <h1> {oneArticle && oneArticle.title} </h1>
                    <p> By admin_autasys {oneArticle && oneArticle.createdAt.split(" ")[0]} </p>
                </div>
                <div className="body-article">
                    <img className="first-img" src={`${API_URL}${oneArticle && oneArticle.image1}`} />
                    <p className="text" dangerouslySetInnerHTML={{ __html: oneArticle ? oneArticle.description:"" }} />
                    <div className="imgs">
                        {oneArticle && (
                            oneArticle.image2 ?(
                                oneArticle.image3 ? (
                                   oneArticle.image4 ? (
                                    <>
                                        <div className="orther">
                                            <img src={`${API_URL}${oneArticle && oneArticle.image3}`} />
                                            <img src={`${API_URL}${oneArticle && oneArticle.image4}`} />
                                        </div>
                                        <img className="imgss" src={`${API_URL}${oneArticle && oneArticle.image2}`} />
                                    </>
                                   ):(
                                    <>
                                        <div className="orther">
                                        <img src={`${API_URL}${oneArticle && oneArticle.image2}`} />
                                        <img src={`${API_URL}${oneArticle && oneArticle.image3}`} />
                                        </div>
                                    </>
                                   )
                                ):(
                                    <>
                                         <img src={`${API_URL}${oneArticle && oneArticle.image2}`} />
                                    </>
                                )
                            ):("")
                        )}
                    </div>
                </div>
                <div className="footer">
                    <p>
                        share :
                        <li>
                        <FacebookShareButton
                            url={getLinkArticle()}>
                            <FacebookIcon size={28} round />
                        </FacebookShareButton>
                        </li>
                        <li>
                            <TwitterShareButton
                                url={getLinkArticle()}>
                                <TwitterIcon size={28} round />
                            </TwitterShareButton>
                        </li>
                    </p>
                </div>
            </section>
            <div className="btnNextPrev">
                <div className="prev">
                    <a href={btnPrev()}> <FiChevronLeft />retour</a>
                    <h3>
                        {prevArticle()}
                    </h3>
                </div>
                <div className="next">
                    <a href={btnNext()}>suivant <FiChevronRight /> </a>
                    <h3>
                        {nextArticle()}
                    </h3>
                </div>
            </div>
            <div className="comment-div">
                <form className="form-avis">
                    <h3>Laisser un commentaire</h3>
                    <p id="msg-form"></p>
                    <div className="div-field">
                        <textarea cols={5} rows={5}
                            value={comment}
                            onChange={e => setComment(e.target.value)}></textarea>
                        <span id="comment-err"></span>
                    </div>
                    <button className="btn-send"
                        onClick={handleSaveComment}> Laisser un commentaire
                    </button>
                </form>
            </div>
            {filter.length > 0 && (
                <div className="related-posted">
                <h2>Related Posts</h2>
                {articleSimilar.length > 3 ? (
                    <Slider {...settings3} className="slider-actuality">
                    {articleSimilar && articleSimilar.map((list, index) => {
                        if (list.id_at !== oneArticle.id_at) {
                            return <div className="card-actuality" key={index}>
                            <div className="cards-act">
                                <Link to={{ pathname: `/article/${list.slug}catid=${list.id_at}` }}>
                                <img src={`${API_URL}${list.image1}`} />
                                </Link>
                                <h4> {list.title} ?</h4>
                                <p>by <b>Admin_autasys</b>on {list.createdAt}</p>
                            </div>
                        </div>;
                        }
                    })}
                     </Slider>
                ):(
                    (
                    <div className="slider-actuality act2">
                    {articleSimilar && articleSimilar.map((list, index) => {
                        if (list.id_at !== oneArticle.id_at) {
                            return <div className="card-actuality" key={index}>
                            <div className="cards-act">
                                <Link to={{ pathname: `/article/${list.slug}catid=${list.id_at}` }}>
                                <img src={`${API_URL}${list.image1}`} />
                                </Link>
                                <h4>{list.title}</h4>
                                <p>by <b>Admin_autasys</b>on {list.createdAt}</p>
                            </div>
                        </div>;
                        }
                    })}
                    </div>
                    )
                )}
               
                <div className="slider-actuality act">
                {articleSimilar.length > 0 && articleSimilar.map((list, index) => {
                    if (list.id_at !== oneArticle.id_at) {
                        return <div className="card-actuality" key={index}>
                        <div className="cards-act">
                            <Link to={{ pathname: `/article/${list.slug}catid=${list.id_at}` }}>
                            <img src={`${API_URL}${list.image1}`} />
                            </Link>
                            <h4>{list.title}</h4>
                            <p>by <b>Admin_autasys</b>on {list.createdAt}</p>
                        </div>
                    </div>;
                    }
                })}
                </div>
            </div>
            )}
        </div>
    );
};

export default ArticlePage;