import React, { useContext, useEffect, useState } from "react";

import { HeadTabs } from "../../../../components/headTabs/HeadTabs";
import GeneralContext from "../../../../context/GlobalContext";

import "./Article.scss";
import AddArticle from "./addArticle";
import AddEtiquete from "./AddEtiquete";
import AddCategorie from "./addCategorie";

const Article = () => {

  const [tabIndex, setTabIndex] = useState(0);

  const active = {
    background: "white"
  };

  // return tab content
  const returnTabs = () => {
    if (tabIndex === 0) {
      return <AddArticle />;
    }
    else if (tabIndex === 1) {
      return <AddCategorie />;
    }
    else if (tabIndex === 2) {
      return <AddEtiquete />;
    }
  };


  return (
    <div className="article-page">
      <HeadTabs title={"Articles"} />
      <div className='article parent'>
        <div className="content-tabs">
          <div className="head-tabs">
            <button style={tabIndex === 0 ? active : null}
              onClick={() => setTabIndex(0)}> Articles </button>
            {/* <button style={tabIndex === 1 ? active : null}
              onClick={() => setTabIndex(1)}> Catégorie </button> */}
          </div>
          <div className="contents-tabs">
            {returnTabs()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Article;
