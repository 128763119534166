import React, { useContext, useState } from "react";

import "../Header.scss";
import "flag-icon-css/css/flag-icons.css";

import logo from "../../../assets/images/png/logo.png";
import { FiHeart, FiMenu, FiSearch, FiShoppingCart } from "react-icons/fi";
import { Link } from "react-router-dom";

import { Cart } from "../../cart/Cart";
import Auth from "../../authForms/Auth";
import FormSeller from "../../formSeller/FormSeller";
import AuthContext from "../../../context/AuthContext";
import GeneralContext from "../../../context/GlobalContext";
import { ACCOUNT_HOME, ACCOUNT_ORDER, ACCOUNT_WHISHLIST, API_URL, LOGIN, ORDER, SEARCH_PRODUCT_PAGE } from "../../../settings/constant";
import { FaLayerGroup, FaUserCircle } from "react-icons/fa";
import { AiOutlineUser } from "react-icons/ai";
import axios from "axios";
import { useSelector } from "react-redux";

const HeaderHome = () => {
  const { user, userIdLocal, logoutUser } = useContext(AuthContext);
  const { store, userData,
    getCategoryProduct, category, shoppingCartItem } = useContext(GeneralContext);

  const [openCart, isOpenCart] = useState(false);
  const [openBar, isOpenBar] = useState(false);
  const [popup, isPopup] = useState(false);
  const [openFormShop, isOpenFormShop] = useState(false);
  const [link, setLink] = useState(null);
  const [keyword, setKeyWord] = useState(null);
  const [categorys, setCategory] = useState(0);

  const fixeHeader = () => {
    let y = window.scrollY;
    let link = document.querySelectorAll(".color-link");
    let bar = document.querySelector(".header-home");

    if (y > 2) {
      for (let i = 0; i < link.length; i++) {
        link[i].style.color = "var(--dark)";
      }
      bar.style.background = "white";
      bar.style.boxShadow = "0 5px 5px rgba(0, 0, 0, 0.162)";
    } else {
      for (let i = 0; i < link.length; i++) {
        link[i].style.color = "white";
      }
      bar.style.background = "none";
    }
  };

  // open form store create
  const clickCreateStore = () => {
    if (user) {
      isOpenFormShop(true);
    } else {
      isPopup(true);
    }
  };

  // active store
  const activeStore = () => {
    if (store.length > 0) {
      const find = store.find(
        (item) => item.is_active === true
      );
      if (!find) {
        update_status_store(setLink);
      }
    }
  };

  // show menu mobile nav
  const showMenu = () => {
    const menu = document.querySelector(".mobile");
    if (menu.style.width == "100%") {
      menu.style.width = "0%";
    } else {
      menu.style.width = "100%";
    }
  };

  // action research product
  const searchProduct = e => {
    e.preventDefault();
    if (keyword) {
      window.location.href = `${SEARCH_PRODUCT_PAGE}?${keyword}&${categorys}` /* `/produits/rechercher/mot-cle/${keyword}` */;
    }
  };

  const cats = ["Beauté Et Cheveux", "Vêtements", "Tissus", "Sacs Et Accessoires", "Bijoux"];

  /* update status store select */
  const update_status_store = async () => {
    if (user) {
      try {
        const response = await axios.put(`${API_URL}store/update-status/${user.id}`);
        if (response.status === 200) {
          let data = response.data;
          let origin = window.location.origin;
          for (let i = 0; i < data.length; i++) {
            if (data[i].is_active === true) {
              setLink(`${origin}/Tableau-de-bord/${data[0].companyName.replaceAll(" ", "")}`);
            }
          }
        }
      } catch (error) {
        return error;
      }
    }
  };

  const returnActionType = () => {
    if (store && store.length > 0) {
      return <button className="btn-create-shop"
        onClick={activeStore}>
        <a href={link ? link : "#"} target="blank">Ouvrir ma boutique</a>
      </button>;
    } else {
      return <button className="btn-create-shop"
        onClick={clickCreateStore}>Créer ma boutique
      </button>;
    }
  };

  useState(() => {

    setInterval(() => {
      let path = window.location.pathname;
      let link = document.querySelectorAll(".color-link");
      let bar = document.querySelector(".header-home");
      if (!path.includes("details") || !path.includes("payement") || !path.includes("mon-compte")) {
        fixeHeader();
      } else if (path.includes("mon-compte")) {
        for (let i = 0; i < link.length; i++) {
          link[i].classList.add("link-color-dark ");
        }

        bar.style.position = "static";
      } else {
        bar.style.position = "static";
      }
    }, 1000);

    // getcategory 
    getCategoryProduct();
    update_status_store();
  }, [store, link]);

  const returnAccount = () => {
    if (user) {
      return (
        <div className="div-account-user">
          <li className="first-lis color-link">Mon Compte</li>
          <div className="bv">
            <div className="box-account-user">
              <p>
                Bienvenue !!
                <b> {userData.firstname + " " + userData.lastname} </b>
              </p>
              <ul className="menu-vertical">
                <a href={ACCOUNT_WHISHLIST}> <FiHeart /> Mes Favoris </a>
                <a href={ACCOUNT_ORDER}> <FaLayerGroup /> Mes Commandes </a>
                <a href={ACCOUNT_HOME}> <AiOutlineUser /> Mon Profil </a>
              </ul>
              <button onClick={logoutUser} className="btn-deconnect"> Deconnexion </button>
            </div>
          </div>
        </div>
      );
    } else if (userIdLocal) {
      return (
        <div className="div-account-user">
          <li className="first-lis color-link">Mon Compte</li>
          <div className="bv">
            <div className="box-account-user">
              <p>
                Bienvenue !!
                <b> {userData.firstname + " " + userData.lastname} </b>
              </p>
              <ul className="menu-vertical">
                <a href={ACCOUNT_ORDER}> <FaLayerGroup /> Mes Commandes </a>
              </ul>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <a href="#" className="link-connect mg color-link"
          onClick={() => isPopup(true)
          }> Connexion </a>
      );
    }
  };


  return (
    <div className='header-home' id="hd">
      <div className="mobile">
        <div className="div-nav">
          <span className="closes" onClick={showMenu}>&times;</span>
          <a href='/' className='logo-link-mobile'>
            <img src={logo} />
          </a>
          <ul>
            {category &&
              category.map((list, index) => {
                for (let i = 0; i < cats.length; i++) {
                  if (list.nameCat === cats[i]) {
                    return <div className="nav-li" key={index}>
                      <Link className="link color-link"
                        to={{ pathname: "/categories/produits", search: `categorie=${list.nameCat.replaceAll(" ", "-")}catId${list.idCat}$` }}
                        state={list}> {list.nameCat} </Link>
                      <div className="group-sub-link">
                        {
                          list.SubCategories && (
                            list.SubCategories.map((list2, index2) => {
                              return <div className="div-sub-link" key={index2}>
                                <Link className="sublink color-link"
                                  to={{ pathname: "/categories/produits", search: `categorie=${list.nameCat.replaceAll(" ", "-")}catId${list.idCat}$subcategories=${list2.nameSubCat}subid${list2.idSubCat}$` }}
                                  state={list}> {list2.nameSubCat} </Link>
                              </div>;
                            })
                          )
                        }
                      </div>
                    </div>;
                  }
                }
              })
            }
          </ul>
          {returnActionType()}
          {returnAccount()}
        </div>
      </div>
      <FormSeller isPopup={isOpenFormShop} popup={openFormShop} />
      {popup ? <Auth isPopup={isPopup} /> : null}
      <Cart openCart={openCart} isOpenCart={isOpenCart} />
      <div className="div-search" style={openBar ? { display: "flex" } : { display: "none" }}>
        <div className="block-searchs">
          <form>
            <span className="closeSearch" onClick={() => isOpenBar(false)}>&times;</span>
            <div className="div-input">
              <button onClick={searchProduct}> <FiSearch /> </button>
              <input type="search" placeholder="Rechercher vos produits"
                value={keyword}
                onChange={e => setKeyWord(e.target.value)} />
              <select className="group-cat"
                value={categorys}
                onChange={e => setCategory(e.target.value)}>
                <option disabled selected> Tout categorie </option>
                {category && (
                  category.map((list, index) => {
                    return <option value={list.idCat} key={index}>
                      {list.nameCat}
                    </option>;
                  })
                )}
              </select>
            </div>
          </form>
        </div>
      </div>
      <li className="icon-nav-mobile color-link" onClick={showMenu}>
        <FiMenu />
      </li>
      <a href='/' className='logo-link'>
        <img src={logo} />
      </a>
      <div className="div-nav">
        <nav>
          <div className="nav-ul">
            {category &&
              category.map((list, index) => {
                for (let i = 0; i < cats.length; i++) {
                  if (list.nameCat === cats[i]) {
                    return <div className="nav-li" key={index}>
                      <Link className="link color-link"
                        to={{ pathname: "/categories/produits", search: `categorie=${list.nameCat.replaceAll(" ", "-")}catId${list.idCat}$` }}
                        state={list}> {list.nameCat} </Link>
                      <div className="group-sub-link">
                        {
                          list.SubCategories && (
                            list.SubCategories.map((list2, index2) => {
                              return <div className="div-sub-link" key={index2}>
                                <Link className="sublink color-link"
                                  to={{ pathname: "/categories/produits", search: `categorie=${list.nameCat.replaceAll(" ", "-")}catId${list.idCat}$subcategories=${list2.nameSubCat}subid${list2.idSubCat}$` }}
                                  state={list}> {list2.nameSubCat} </Link>
                              </div>;
                            })
                          )
                        }
                      </div>
                    </div>;
                  }
                }
              })
            }
          </div>
        </nav>
        <div className="div-right">
          {returnActionType()}
          {/* <div className="div-lang mg">
            <li>
              <i className="flag-icon flag-icon-fr"></i>
              <span className="color-link"> French</span>
            </li>
            <div className="div-choose-lang">
              <a href="#" className="color-link">English</a>
              <a href="#" className="color-link">French</a>
            </div>
          </div> */}
          {returnAccount()}
          <button className="btn-search mg color-link" onClick={() => isOpenBar(true)}> <FiSearch /> </button>
          <button className="btn-cart mg color-link"
            onClick={() => isOpenCart(true)}>
            <FiShoppingCart />
            <span> {shoppingCartItem && shoppingCartItem.length} </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default HeaderHome;
