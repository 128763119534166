import React from "react";

import "../Header.scss";
import "flag-icon-css/css/flag-icons.css";

import logo from "../../../assets/images/png/logo.png";


const HeaderAuth = () => {

  return (
    <div className='header-home headAuth' id="hd">
      <a href='/' className='logo-link'>
        <img src={logo} />
      </a>
    </div>
  );
};

export default HeaderAuth;
