import React, { useContext } from "react";
import { FaLayerGroup, FaUserCircle } from "react-icons/fa";
import { BsGeoAlt } from "react-icons/bs";
import { FiHeart, FiLogOut, FiMenu, FiUser } from "react-icons/fi";

import "./index.scss";

import LayoutHome from "../../../layouts/layoutSite/LayoutHome";
import { AiOutlineDashboard } from "react-icons/ai";
import {
  ACCOUNT_ADRESS,
  ACCOUNT_DETAILS,
  ACCOUNT_HOME,
  ACCOUNT_ORDER,
  ACCOUNT_WHISHLIST
} from "../../../settings/constant";
import GeneralContext from "../../../context/GlobalContext";
import AuthContext from "../../../context/AuthContext";

const Account = ({ children, title }) => {

  const {userData} = useContext(GeneralContext);
  const {logoutUser} = useContext(AuthContext);

  const menu = [
    {
      label: "Tableau de bord",
      link: ACCOUNT_HOME,
      icon: <AiOutlineDashboard />
    },
    {
      label: "Commandes",
      link: ACCOUNT_ORDER,
      icon: <FaLayerGroup />
    },
    {
      label: "Favoris",
      link: ACCOUNT_WHISHLIST,
      icon: <FiHeart />
    },
    {
      label: "Adresse",
      link: ACCOUNT_ADRESS,
      icon: <BsGeoAlt />
    },
    {
      label: "Détails du compte",
      link: ACCOUNT_DETAILS,
      icon: <FiUser />
    }
  ];

  // show nav menu mobile
  const showMenuMobile = () => {
    let nav = document.querySelector(".box-menu");

    if (nav.style.width === "0px") {
      nav.style.width = "220px";
    } else {
      nav.style.width = "0px";
    }
  };
  

  return (
    <LayoutHome>
      <div className="account-page">
        <h1 className="title-page">
          <FiMenu className="menu-icon" onClick={showMenuMobile} /> {title}
        </h1>
        <div className="account-container">
          <div className="box-menu">
            <span className="close" onClick={showMenuMobile}>&times;</span>
            <div className="user-head">
              <FaUserCircle />
              <h3> {userData ? userData.firstname+" "+userData.lastname:null} </h3>
            </div>
            <nav className="nav-menus">
              <ul className="menu-lists">
                {menu.map((list, index) => {
                  return <a href={list.link} key={index}>
                    {list.icon}
                    {list.label}
                  </a>;
                })}
              </ul>
              <a className="btn-disconnect" onClick={logoutUser}> <FiLogOut /> Déconnexion </a>
            </nav>
          </div>
          <div className="account-page-content">
            {children}
          </div>
        </div>
      </div>
    </LayoutHome>
  );
};

export default Account;