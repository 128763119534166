import React, { useContext, useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { FiEdit3, FiTrash2 } from "react-icons/fi";

import "./products.scss";

import { HeadTabs } from "../../../../components/headTabs/HeadTabs";
import Pagination from "../../../../components/pagination2/Pagination";
import SearchBar from "../../../../components/searchBar2/SearchBar";
import { API_URL } from "../../../../settings/constant";
import GeneralContext from "../../../../context/GlobalContext";
import { BoxAlertSucess } from "../../../../components/boxAlertSucess/BoxAlertSucess";

const Cobeille = () => {
  const [popup, isPopup] = useState(false);
  const [done, isDone] = useState(false);
  const [msgText, setMsgText] = useState(null);

  const [searchWord, setSearchWord] = useState("");
  const startNbPage = 4;
  const [nextPage, setNextPage] = useState(startNbPage);
  const [previouspage, setPreviousPage] = useState(0);
  const [resultSearch, setResultSearch] = useState([]);
  const [storeName, setStoreName] = useState(null);

  const { productTrash, getProductTrash,
    store, deleteDefinitiveProduct, restoreProduct } = useContext(GeneralContext);

  /* return store */
  const returnStore = () => {
    if (store && store.length > 0) {
      let find = store.find(
        (item) => item.is_active === true
      );

      if (find) {
        setStoreName(find.companyName.replace(/[-'_`\s]/, "").trim());
        getProductTrash(find.store_id);
      }
    }
  };

  /* search product */
  const search = async () => {
    if (searchWord) {
      const tab = productTrash.filter(
        (item) => item.name_prod.toLowerCase().includes(searchWord.toLowerCase()) ||
          item.category.nameCat.toLowerCase().includes(searchWord),
      );

      if (tab.length != 0) {
        setResultSearch(tab);
      } else {
        setResultSearch([]);
      }
    }
  };


  /* delete product */
  const handleDelete = (id_prod) => {
    deleteDefinitiveProduct(id_prod, isDone, setMsgText, isPopup);
  };

  /* restore product */
  const handleRestoreProduct = (id_prod) => {
    restoreProduct(id_prod, isDone, setMsgText, isPopup);
  };

  /* render column data */
  const renderTableData = () => {
    return (
      productTrash.map((list, index) => {
        if (previouspage <= index && (nextPage - 1) >= index) {
          return <tr key={index}>
            <td> Prod{index + 1} </td>
            <td> <img src={`${API_URL}${list.image_prod}`} /> </td>
            <td> {list.name_prod} </td>
            <td> {list.Category.nameCat} </td>
            <td> {Math.round(list.price_prod)} </td>
            <td> {list.reduces} </td>
            <td>
              <button onClick={() => handleRestoreProduct(list.id_prod)}> <FiEdit3 /> <br /> Restaurer </button>
              <button onClick={() => handleDelete(list.id_prod)}> <FiTrash2 /> <br /> Supprimer </button>
            </td>
          </tr>;
        }
      })
    );
  };


  useEffect(() => {
    returnStore();
    search();
  }, [store, searchWord]);


  return (
    <div className="product-dashboard-buyer">
      <HeadTabs title={"Cobeille"} />
      <BoxAlertSucess popup={popup}
        text={msgText}
        handleBtn={isPopup} isDone={isDone} done={done}
        link={"#"} />
      <div className="product-box parent">
        <div className="btn-add-product">
          <button
            onClick={() => window.location.href = `/Tableau-de-bord/${storeName}/nouveau-produit`}> <FaPlus /> Nouveau produit </button>
        </div>
        <div className="list-product child">
          <>
            <SearchBar placeholderText="Rechercher par nom produit et categorie"
              searchWord={searchWord} allList={productTrash}
              setList={setResultSearch}
              setWord={setSearchWord} />
            <table className="table-data">
              <thead>
                <tr>
                  <th> Id Produit </th>
                  <th>Photo Image</th>
                  <th> Nom Produit </th>
                  <th> Catégorie </th>
                  <th> Prix unité (XOF) </th>
                  <th> Réduction </th>
                  <th> Actions </th>
                </tr>
              </thead>
              {
                productTrash.length != 0 ? (
                  <tbody>
                    {
                      resultSearch.length == 0 ? (
                        productTrash && (
                          renderTableData(productTrash)
                        )
                      ) : (
                        renderTableData(resultSearch)
                      )
                    }
                  </tbody>
                ) : <p> Aucun donnée a affiché</p>
              }
            </table>
            {resultSearch.length == 0 ? (
              <Pagination nb={startNbPage}
                countTab={productTrash.length}
                setNextt={setNextPage}
                setPrevious={setPreviousPage}
                nextt={nextPage}
                previous={previouspage} />
            ) : null}
          </>

        </div>
      </div>
    </div>
  );
};

export default Cobeille;
