import React, { useContext, useState } from "react";
import { FaLayerGroup } from "react-icons/fa";
import { AiOutlineUser } from "react-icons/ai";

import "../Header.scss";
import "flag-icon-css/css/flag-icons.css";

import logo from "../../../assets/images/png/logo.png";
import { FiHeart, FiMenu, FiSearch, FiShoppingCart } from "react-icons/fi";
import { Link } from "react-router-dom";

import { Cart } from "../../cart/Cart";
import Auth from "../../authForms/Auth";
import FormSeller from "../../formSeller/FormSeller";
import AuthContext from "../../../context/AuthContext";
import GeneralContext from "../../../context/GlobalContext";
import { ACCOUNT_HOME, ACCOUNT_ORDER, ACCOUNT_WHISHLIST, API_URL, SEARCH_PRODUCT_PAGE } from "../../../settings/constant";
import axios from "axios";


const HeaderShop = () => {
  const { user } = useContext(AuthContext);
  const { store, userData,
    getCategoriesByStore, categorieByStore } = useContext(GeneralContext);

  const [openCart, isOpenCart] = useState(false);
  const [openBar, isOpenBar] = useState(false);
  const [popup, isPopup] = useState(false);
  const [openFormShop, isOpenFormShop] = useState(false);
  const [keyword, setKeyWord] = useState(null);
  const [category, setCategory] = useState(0);
  const [link, setLink] = useState(null);

  const fixeHeader = () => {
    let y = window.scrollY;
    let link = document.querySelectorAll(".color-link");
    let bar = document.querySelector(".header-home");

    if (y > 2) {
      for (let i = 0; i < link.length; i++) {
        link[i].style.color = "var(--dark)";
      }
      bar.style.background = "white";
      bar.style.boxShadow = "0 5px 5px rgba(0, 0, 0, 0.162)";
    } else {
      for (let i = 0; i < link.length; i++) {
        link[i].style.color = "white";
      }
      bar.style.background = "none";
    }
  };

  // open form store create
  const clickCreateStore = () => {
    if (user) {
      isOpenFormShop(true);
    } else {
      isPopup(true);
    }
  };

  // show menu mobile nav
  const showMenu = () => {
    const menu = document.querySelector(".mobile");
    if (menu.style.width == "100%") {
      menu.style.width = "0%";
    } else {
      menu.style.width = "100%";
    }
  };

  const searchProduct = e => {
    e.preventDefault();
    if (keyword) {
      window.location.href = `${SEARCH_PRODUCT_PAGE}?${keyword}&${category}` /* `/produits/rechercher/mot-cle/${keyword}` */;
    }
  };

  const setInfosStore = () => {
    let path = window.location.pathname.split("/");
    let id = path[2];

    if (id) {
      getCategoriesByStore();
    }
  };

  /* update status store select */
  const update_status_store = async () => {
    if (user) {
      try {
        const response = await axios.put(`${API_URL}store/update-status/${user.id}`);
        if (response.status === 200) {
          let data = response.data;
          let origin = window.location.origin;
          for (let i = 0; i < data.length; i++) {
            if (data[i].is_active === true) {
              setLink(`${origin}/Tableau-de-bord/${data[0].companyName.replaceAll(" ", "")}`);
            }
          }
        }
      } catch (error) {
        return error;
      }
    }
  };

  useState(() => {

    setInfosStore();

    setInterval(() => {

      let path = window.location.pathname;
      let link = document.querySelectorAll(".color-link");
      let bar = document.querySelector(".header-home");
      if (!path.includes("details") || !path.includes("payement") || !path.includes("mon-compte")) {
        fixeHeader();
      } else if (path.includes("mon-compte")) {
        for (let i = 0; i < link.length; i++) {
          link[i].classList.add("link-color-dark ");
        }

        bar.style.position = "static";
      } else {
        bar.style.position = "static";
      }
    }, 1000);

    update_status_store();

  }, [store]);

  return (
    <div className='header-home' id="hd">
      <div className="mobile">
        <div className="div-nav">
          <span className="closes" onClick={showMenu}>&times;</span>
          <a href='/' className='logo-link-mobile'>
            <img src={logo} />
          </a>
          <ul>
            {categorieByStore &&
              categorieByStore.map((list, index) => {
                if (index < 5) {
                  return <Link key={index}
                    to={{ pathname: window.location.pathname, search: `categorie=${list.nameCat.replaceAll(" ", "-")}catId${list.idCat}$` }}
                    state={list}> {list.nameCat} </Link>;
                }
              })
            }
          </ul>
        </div>
      </div>
      <FormSeller isPopup={isOpenFormShop} popup={openFormShop} />
      {popup ? <Auth isPopup={isPopup} /> : null}
      <Cart openCart={openCart} isOpenCart={isOpenCart} />
      <div className="div-search" style={openBar ? { display: "flex" } : { display: "none" }}>
        <div className="block-searchs">
          <form>
            <span className="close" onClick={() => isOpenBar(false)}>&times;</span>
            <div className="div-input">
              <button onClick={searchProduct}> <FiSearch /> </button>
              <input type="search" placeholder="Rechercher vos produits"
                value={keyword}
                onChange={e => setKeyWord(e.target.value)} />
              <select className="group-cat"
                value={category}
                onChange={e => setCategory(e.target.value)}>
                <option disabled selected> Tout categorie </option>
                {categorieByStore && (
                  categorieByStore.map((list, index) => {
                    return <option value={list.idCat} key={index}>
                      {list.nameCat}
                    </option>;
                  })
                )}
              </select>
            </div>
          </form>
        </div>
      </div>
      <li className="icon-nav-mobile color-link" onClick={showMenu}>
        <FiMenu />
      </li>
      <a href='/' className='logo-link'>
        <img src={logo} />
      </a>
      <div className="div-nav">
        <nav>
          <div className="nav-ul">
            {categorieByStore &&
              categorieByStore.map((list, index) => {
                if (index < 4) {
                  return <div className="nav-li" key={index}>
                    <Link className="link color-link"
                      to={{ pathname: window.location.pathname, search: `categorie=${list.nameCat.replaceAll(" ", "-")}catId${list.idCat}$` }}
                      state={list}> {list.nameCat} </Link>
                    <div className="group-sub-link">
                      {
                        list.SubCategories && (
                          list.SubCategories.map((list2, index2) => {
                            return <div className="div-sub-link" key={index2}>
                              <Link className="sublink color-link"
                                to={{ pathname: window.location.pathname, search: `categorie=${list.nameCat.replaceAll(" ", "-")}catId${list.idCat}$subcategories=${list2.nameSubCat}subid${list2.idSubCat}$` }}
                                state={list}> {list2.nameSubCat} </Link>
                            </div>;
                          })
                        )
                      }
                    </div>
                  </div>;
                }
              })
            }
          </div>
        </nav>
        <div className="div-right">
        {store.length > 0 ? <button className="btn-create-shop">
            <a href={link? link:"#"} target="blank">Ouvrir ma boutique</a>
          </button> :
            <button className="btn-create-shop"
              onClick={clickCreateStore}>Créer ma boutique</button>}
          <div className="div-lang mg">
            <li>
              <i className="flag-icon flag-icon-fr"></i>
              <span className="color-link"> French</span>
            </li>
            <div className="div-choose-lang">
              <a href="#" className="color-link">English</a>
              <a href="#" className="color-link">French</a>
            </div>
          </div>
          {user ? (
            <div className="div-account-user">
              <li className="first-lis color-link">Mon Compte</li>
              <div className="bv">
                <div className="box-account-user">
                  <p>
                    Bienvenue !!
                    <b> {userData.firstname + " " + userData.lastname} </b>
                  </p>
                  <ul className="menu-vertical">
                    <a href={ACCOUNT_WHISHLIST}> <FiHeart /> Mes Favoris </a>
                    <a href={ACCOUNT_ORDER}> <FaLayerGroup /> Mes Commandes </a>
                    <a href={ACCOUNT_HOME}> <AiOutlineUser /> Mon Profil </a>
                  </ul>
                  <button className="btn-deconnect"> Deconnexion </button>
                </div>
              </div>
            </div>
          ) : (
            <a href="#" className="link-connect mg color-link"
              onClick={() => isPopup(true)
              }> Connexion </a>
          )}
          <button className="btn-search mg color-link" onClick={() => isOpenBar(true)}> <FiSearch /> </button>
          <button className="btn-cart mg color-link"
            onClick={() => isOpenCart(true)}>
            <FiShoppingCart />
          </button>
        </div>
      </div>

    </div>
  );
};

export default HeaderShop;
