import React from "react";

import "./LayoutHome.scss";
import HeaderCartPay from "../../components/header/headers/HeaderCartPay";
import Footer2 from "../../components/footer/Footer2";

const LayoutCart = ({children}) => {
  return (
    <>
      <HeaderCartPay title={"Mon Panier"} />
      <div className="body-page">
        {children}
      </div>
      <Footer2 />
    </>
  );
};

export default LayoutCart;
