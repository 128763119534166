import React, { useContext, useEffect, useState } from "react";
import { FaBuffer, FaBuysellads, FaCheck, FaList, FaUserCircle, FaWarehouse } from "react-icons/fa";
import { FiChevronDown, FiChevronRight, FiLink, FiList, FiLogOut, FiUser } from "react-icons/fi";
import { FcShop } from "react-icons/fc";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";

import "./LayoutSeller.scss";

import logo from "../../../assets/images/png/logo.png";
import GeneralContext, { GlobalContext } from "../../../context/GlobalContext";
import { PopupLayout } from "../../../components/popupLayout/PopupLayout";
import { API_URL, config } from "../../../settings/constant";
import AuthContext from "../../../context/AuthContext";
import { BsCreditCard } from "react-icons/bs";
import axios from "axios";

const LayoutSeller = ({ children }) => {
  const [popup, isPopup] = useState(false);
  const [popup2, isPopup2] = useState(false);

  const { logoutUser, user } = useContext(AuthContext);
  const { update_status_store, userData } = useContext(GeneralContext);

  const [storeActive, setStoreActive] = useState("");
  const [storeActiveIndex, setStoreActiveIndex] = useState();
  const [selects, isSelects] = useState(false);
  const [linkWebSite, setLinkWebSite] = useState("");

  const [id, setId] = useState(0);
  const [id2, setId2] = useState(0);

  const getStore = async () => {
    if (user) {
      let id = user.id;
      try {
        const response = await axios.get(`${API_URL}user/stores/${id}`, config);
        const data = response.data;
        if (response.status === 200) {
          let find = data.find(
            (item) => item.is_active === true,
          );
          if (find) {
            setStoreActive(find.companyName.replace(/[-'_\s]/, "-").trim());
            setLinkWebSite(`${origin}/${find.companyName.replaceAll(" ", "")}/${find.store_id}`);
          }
        }
      } catch (error) {
        return error;
      }
    }
  };

  /* menu link */
  const menu = [
    {
      label: "Tableau de bord",
      url: storeActive ? `/Tableau-de-bord/${storeActive}` : "",
      icon: <FaWarehouse />,
      index: 0
    },
    {
      label: "Profile",
      url: storeActive ? `/Tableau-de-bord/${storeActive}/profile` : "",
      icon: <FiUser />,
      index: 1
    },
    {
      label: "Produits",
      url: "",
      icon: <FiList />,
      index: 20,
      submenu: [
        { label2: "Liste des produits", url2: storeActive ? `/Tableau-de-bord/${storeActive}/produits` : "", index: 3 },
        { label2: "Ajouter un produit", url2: storeActive ? `/Tableau-de-bord/${storeActive}/nouveau-produit` : "", index: 4 },
        { label2: "Corbeille", url2: storeActive ? `/Tableau-de-bord/${storeActive}/produits/cobeille` : "", index: 5 },
      ],
    },
    {
      label: "Commandes",
      url: storeActive ? `/Tableau-de-bord/${storeActive}/commandes` : "",
      icon: <FaBuffer />,
      index: 6
    },
    {
      label: "Ventes",
      url: storeActive ? `/Tableau-de-bord/${storeActive}/ventes` : "",
      icon: <FaBuysellads />,
      index: 7
    },
    {
      label: "Paiement",
      url: storeActive ? `/Tableau-de-bord/${storeActive}/demande-des-transactions` : "",
      icon: <BsCreditCard />,
      index: 8
    }
  ];

  /* functon navigate of tabs */
  const navigateUrl = (url, index) => {
    sessionStorage.setItem("menuId", index);
    const allLink = document.querySelectorAll(".link-p");
    const allSub = document.querySelectorAll(".sub-ul");

    if (url) {
      window.location.href = url;
    }

    let ids = parseInt(sessionStorage.getItem("menuId"));

    if (ids === 20 ) {
      if (allSub[0].style.height == "0px") {
        allSub[0].style.height = "max-content";
        setId(1);
      } else {
        setId(0);
        allSub[0].style.height = "0px";
      }
    }
    for (let i = 0; i < allSub.length; i++) {
      allSub[i].classList.remove("open-sub-menu");
    }
  };

  const dropMenu = () => {
    const allSub = document.querySelectorAll(".sub-ul");
    let ids = parseInt(sessionStorage.getItem("menuId"));

    if (ids == 20 || ids == 3 || ids == 4 || ids == 5) {
      if (allSub[0].style.height === "0px") {
        allSub[0].style.height = "max-content";
        setId(1);
      } else {
        setId(0);
        allSub[0].style.height = "0px";
      }
    }
  };

  // show menu mobile nav
  const showMenu = () => {
    const menu = document.querySelector(".nav-bar");
    if (menu.style.width == "230px") {
      menu.style.width = "0px";
    } else {
      menu.style.width = "230px";
      menu.style.position = "absolute";
    }
  };

  /* handle select store */
  const handleSelectStore = () => {
    update_status_store(storeActiveIndex, isSelects);

    if (selects) {
      sessionStorage.setItem("menuId", 0);
      window.location.href = storeActive ? `/Tableau-de-bord/${storeActive}` : "";
    }
  };

  useEffect(() => {
    dropMenu();
    getStore();
  },[]);


  return (
    <GlobalContext>
      <div className="dashboard-seller-home">
        <PopupLayout closes={popup}>
          <div className='div-select-store'>
            <span className='close-x' onClick={() => isPopup(false)}>&times;</span>
            <h1> Sélectionner une boutique </h1>
            <button onClick={handleSelectStore}>ok</button>
          </div>
        </PopupLayout>
        <PopupLayout closes={popup2}>
          <div className='card-share'>
            <span className='close' onClick={() => isPopup2(false)}>&times;</span>
            <p>
              Partagez le lien de votre boutique e-commerce à vos amis
              sur whatsapp, Facebook...
            </p>
            <div className='socio-share'>
              <FacebookShareButton
                url={linkWebSite}>
                <FacebookIcon size={28} round />
              </FacebookShareButton>
              <WhatsappShareButton
                url={linkWebSite}>
                <WhatsappIcon size={28} round />
              </WhatsappShareButton>
              <CopyToClipboard text={linkWebSite}
                onCopy={() => alert("lien copié")}>
                <button className='copy-link'>Copier le lien</button>
              </CopyToClipboard>
            </div>
          </div>
        </PopupLayout>
        <div className="main">
          <div className="main-center">
            <div className="nav-bar">
              <span className="close-mobile-nav"
                onClick={showMenu}>&times;</span>
              <div className="logo z-index bg-white">
                <img src={logo} />
              </div>
              <div className="shop z-index ">
                <FcShop />
                <h4> {storeActive} </h4>
              </div>
              <div className="nav z-index">
                {
                  menu.map(({ label, url, index, icon, submenu }, key) => {
                    return <div className="nav-ul" key={key}>
                      <p onClick={() => navigateUrl(url, index)}
                        className={`link-p ${sessionStorage.getItem("menuId") == index ? "active-menu" : ""}`}>
                        <span> {icon} {label} </span>
                        {index == 20 ? (id == 1 ? <FiChevronDown /> : <FiChevronRight />) : null}
                        {index == 21 ? (id2 == 1 ? <FiChevronDown /> : <FiChevronRight />) : null}
                      </p>
                      {submenu && (
                        <div className="sub-ul">
                          {
                            submenu.map((list, index2) => {
                              return <li
                                className={`link-p ${sessionStorage.getItem("menuId") == list.index ? "active-menu" : ""}`}
                                onClick={() => navigateUrl(list.url2, list.index)}
                                key={index2}>
                                {list.label2}
                              </li>;
                            })
                          }
                        </div>
                      )}
                    </div>;
                  })
                }
              </div>
            </div>
            <div className="body">
              <div className="head">
                <li>
                  <FaList onClick={showMenu} />
                  <h3> Bienvenu dans votre espace personnel </h3>
                </li>
                <div className="block-right">
                  <button className='btn-share-link'
                    onClick={() => isPopup2(true)}> <FiLink /> Partager le lien de ma boutique</button>
                  <div className="user-prof">
                    <div>
                      <span>  <input type="text" value ={userData.firstname ? userData.firstname + " " + userData.lastname:"Utilisateur"} /> </span>
                    </div>
                    {userData.image_profil ? <img src={`${API_URL}${userData.image_profil}`} /> : <FaUserCircle />}
                    <button onClick={logoutUser}> <FiLogOut /> Déconnexion </button>
                  </div>
                </div>
              </div>
              <div className="content-body">
                <div className="block-content">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    </GlobalContext>
  );
};

export default LayoutSeller;
