import React from "react";

import "./About.scss";

import beauty from "../../../assets/images/image-about-first-s.png";
import beauty2 from "../../../assets/images/png/beautiful.jpg";
import { FaPaperPlane, FaTrash } from "react-icons/fa";
import { FiShoppingCart } from "react-icons/fi";

const About = () => {
    return (
        <div className="about-page">
            <div className="section-one-about">
                <div className="slid d1">
                    <div className="slid-child">
                        <div>
                            <h1>
                                À propos de nous
                            </h1>
                            <p>
                                “Authentique As You See, pour vous rendre authentique
                                en vous revêtant d’articles spéciaux et originaux”
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <section className="section-two-about">
                <img src={beauty} />
                <div className="div-text">
                    <h5>NOTRE VISION</h5>
                    <h2>
                        Rendre authentique et contribuer à l’essor de la mode africaine
                    </h2>
                    <p>
                        L’Afrique, continent le plus riche du monde présente une diversité
                        culturelle unique au monde. Notre mission est de contribuer à la
                        promotion des articles du continent.
                    </p>
                    <div className="stats">
                        <div className="chiffre">
                            <h3> 12,623</h3>
                            <p> CLIENTS SATISFAITS </p>
                        </div>
                        <p>
                            Des clients satisfaits dans le monde entier et en pleine croissance.
                        </p>
                    </div>
                </div>
            </section>
            <section className="section-three-about">
                <h5>2022</h5>
                <h2>Notre histoire</h2>
                <div className="div-text">
                    <div className="left-text">
                        <p>
                            Lorsque l’ambition est mêlée à l’ancrage des réalités
                            culturelles, cela ne peut que donner naissance à cette entreprise.
                            Née de la volonté de promouvoir les articles de luxe pour les Africains,
                            AUTASYS  est celle plateforme en ligne dont le but est de mettre à la disposition
                            de tout ce qui en expriment le besoin, des articles authentiques et spéciaux.
                        </p>
                        <p>
                            Présenter ses produits sur AUTASYS, c’est dans un premier temps
                            s’assurer de leur originalité et dans un second temps,
                            c’est faire bénéficier à sa clientèle d’un service de qualité.
                        </p>
                    </div>
                    <div className="right-text">
                        <p>
                            AUTASYS défend plusieurs valeurs aussi importantes pour ses fondatrices
                            que pour le développement du contient.
                        </p>
                        <p>
                            Au nombre de ces dernières, on compte:
                        </p>
                        <li>L’authenticité des articles</li>
                        <li>La probité des différents fournisseurs</li>
                        <li>Le respect de l’utilisateur</li>
                        <li>La transparence dans le parcours client</li>
                        <p>
                            Nous vous plongeons dans un monde authentique et unique.
                        </p>
                    </div>
                </div>
            </section>
            <section className="section-two-about">
                <div className="div-text">
                    <h5>POUR L’AVENIR</h5>
                    <h2>
                        Croissance soutenue par la qualité
                    </h2>
                    <p>
                        Nous axons notre croissance sur la qualité des articles que nous présentons sur notre site.
                        Par ailleurs, nous sommes conscient du fait, qu’un client satisfait est la meilleure
                        publicité qui soit.
                    </p>
                    <div className="stats">
                        <div className="chiffre">
                            <h3> 107 </h3>
                            <p> PAYS </p>
                        </div>
                        <p>
                            Nous sommes présents dans différents pays à travers le monde.
                        </p>
                    </div>
                </div>
                <img src={beauty2} />
            </section>
            <section className="section-four-about">
                <h5>PRINCIPAUX AVANTAGES</h5>
                <div className="div-flex">
                    <div>
                        <FaTrash />
                        <p>Commerce direct durable</p>
                    </div>
                    <div>
                        <FiShoppingCart />
                        <p>Parcours client transparent et sécurisé</p>
                    </div>
                    <div>
                        <FaPaperPlane />
                        <p>Service de livraison disponible et réactif</p>
                    </div>
                </div>
            </section>
        </div >
    );
};

export default About;