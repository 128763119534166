import React from "react";

import "../Footer.scss";

const FooterAuth = () => {

  return (
    <footer className="footer2">
      <p>&copy; 2023 AUTASYS</p>
    </footer>
  );
};

export default FooterAuth;
