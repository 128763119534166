import React, { useContext, useEffect } from "react";

import "./Cart.scss";

import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import GeneralContext from "../../context/GlobalContext";
import { API_URL, CART_SHOPPING } from "../../settings/constant";

export const Cart = ({ openCart, isOpenCart }) => {
  const openCartt = { width: "100%" };
  const closeCart = { width: "0%" };

  const { shoppingCartItem, updateShoppingCartItemQty, deleteShoppingCartItem } = useContext(GeneralContext);

  // total price
  const totalPrice = () => {
    if (shoppingCartItem.length > 0) {
      let price = 0;
      shoppingCartItem.forEach(element => {
        price += element.quantity * element.amount;
      });

      return price;
    }
  };

  useEffect(() => {
  }, [shoppingCartItem]);

  return (
    <div className="cart-display" style={openCart ? openCartt : closeCart}>
      <div className="cart-box">
        <div className="head-cart">
          <h4>Panier <input type="text" value={shoppingCartItem ? shoppingCartItem.length : 0} /> </h4>
          <span className="close"
            onClick={() => isOpenCart(false)}>&times;</span>
        </div>
        <div className="cart-content">
          {shoppingCartItem && shoppingCartItem.length === 0 ? (
            ""
          ) : (
            shoppingCartItem.map((list, index) => {
              return <div className="card-prod-cart" key={index}>
                <img src={`${API_URL}${list.image_prod}`} />
                <div className="infos-prod-cart">
                  <span className="close"
                    onClick={() => deleteShoppingCartItem(list.id_prod, list.colors, list.sizes)}>&times;</span>
                  <h4> {list.name_prod} </h4>
                  <p className="caract-img">
                    {
                      list.colors && (
                        <p className="color">
                          Couleur: <b>{list.colors}</b>
                        </p>
                      )
                    }
                    {
                      list.sizes && (
                        <p className="size">
                          Taille: <b> {list.sizes} </b>
                        </p>
                      )
                    }
                  </p>
                  <div className="quantity">
                    <div className="qty">
                      <button onClick={() => updateShoppingCartItemQty(list.id_prod, list.colors, list.sizes, "decrement")}>
                        <AiOutlineMinus /> </button>
                      <span> <input type="text" value={list.quantity} /> </span>
                      <button onClick={() => updateShoppingCartItemQty(list.id_prod, list.colors, list.sizes, "increment")}>
                        <AiOutlinePlus /> </button>
                    </div>
                    <b> {list.amount * list.quantity} FCFA </b>
                  </div>
                </div>
              </div>;
            })
          )
          }
        </div>
        <div className="cart-foot">
          <p className="subprice">
            <span>Sous-total : </span>
            <b> {totalPrice()} <sup> FCFA</sup> </b>
          </p>
          <button onClick={() => window.location.href = CART_SHOPPING}> Ouvrir le panier </button>
        </div>
      </div>
    </div>
  );
};