import React, { useContext, useEffect, useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { SummaryOrder } from "../../../components/summaryOrder/SummaryOrder";
import GeneralContext from "../../../context/GlobalContext";
import { API_URL, PRODUCT_CATEGORY } from "../../../settings/constant";

import "./cart.scss";

const Cart = () => {
  const [price, setPrice] = useState(null);

  const { shoppingCartItem, updateShoppingCartItemQty, deleteShoppingCartItem } = useContext(GeneralContext);

  useEffect(() => {

  }, [shoppingCartItem]);

  return (
    <div className="cart-page">
      <h1> Cart </h1>
      <div className="cart-container">
        <div className="cart-item">
          <div className="head-cart">
            <a href={PRODUCT_CATEGORY}><FiChevronLeft /> Continuer votre achat</a>
          </div>
          <div className="items-box">
            {shoppingCartItem.length === 0 ? (
              ""
            ) : (
              shoppingCartItem.map((list, index) => {
                return <div className="item-box-cart" key={index}>
                  <img src={`${API_URL}${list.image_prod}`} />
                  <div className="infos-prod-cart">
                    <span className="close"
                      onClick={() => deleteShoppingCartItem(list.id_prod, list.colors, list.sizes)}>&times;</span>
                    <h4> {list.name_prod} </h4>
                    <p className="caract-img">
                      {
                        list.colors && (
                          <p className="color">
                            Couleur: <b>{list.colors}</b>
                          </p>
                        )
                      }
                      {
                        list.sizes && (
                          <p className="size">
                            Taille: <b> {list.sizes} </b>
                          </p>
                        )
                      }
                    </p>
                    <div className="quantity">
                      <div className="qty">
                        <button onClick={() => updateShoppingCartItemQty(list.id_prod, list.colors, list.sizes, "decrement")}>
                          <AiOutlineMinus /> </button>
                        <input type="text" value={list.quantity} />
                        <button onClick={() => updateShoppingCartItemQty(list.id_prod, list.colors, list.sizes, "increment")}>
                          <AiOutlinePlus /> </button>
                      </div>
                      <b> {list.amount * list.quantity} <sup>CFA</sup> </b>
                    </div>
                  </div>
                </div>;
              })
            )}
          </div>
        </div>
        <SummaryOrder setPrice={setPrice} />
      </div>
    </div>
  );
};

export default Cart;