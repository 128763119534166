import React, { useContext, useEffect, useState } from "react";
import { validInputText } from "../../controlFields/ControlField";
import { ShowPasswdText } from "../../showPasswdText/ShowPasswdText";

import "../auth.scss";
import AuthContext from "../../../context/AuthContext";

const ResetFormAdmin = () => {

  const { reset_pwd } = useContext(AuthContext);

  const [pwd, setPwd] = useState(null);
  const [pwdC, setPwdC] = useState(null);
  const [email, setEmail] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    validInputText(pwd, "pwd-error", "pwd");

    if (validInputText(pwd, "pwd-error", "pwd")) {
      if (pwdC == pwd) {
        reset_pwd(email, pwd, "admin");
      } else {
        const pwdC = document.getElementById("pwdC-error");
        pwdC.innerText = "Mot de passe incorrect";
      }
    }
  };

  useEffect(() => {
    let path = window.location.search.substring(1);
    if (path) {
      setEmail(path);
    }
  }, [email]);


  return (
    <div className="login-block">
      <form className="form-login-register form-resett">
        <h3 className="title-reset-pwd"
          style={{ marginBottom: "1.5rem" }}> Définissez votre Mot de passe</h3>
        <span className="notice-pwd" id="notice-pwd">
          Au mois six (06) caractères alphanumérique
          (Ex: Mon012x0)
        </span>
        <div className="div-field">
          <ShowPasswdText id="password" />
          <input type="password" placeholder="Nouveau mot de passe..."
            name="password"
            id="password"
            value={pwd}
            onChange={e => setPwd(e.target.value)} />
          <span className="span" id="pwd-error"></span>
        </div>
        <div className="div-field">
          <input type="password" placeholder="Confirmer le mot de passe..."
            name="passwordC"
            id="passwordC"
            value={pwdC}
            onChange={e => setPwdC(e.target.value)} />
          <ShowPasswdText id="passwordC" />
          <span className="span" id="pwdC-error"></span>
        </div>
        <div className="btn-submit">
          <button onClick={handleSubmit} type="submit">Réinitialiser</button>
        </div>
      </form>
    </div>
  );
};

export default ResetFormAdmin;
