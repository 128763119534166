import React from "react";

import "./HeadFilter.scss";
import { FaFilter } from "react-icons/fa";
import Select from "../select/Select";
import { CgMenuGridR } from "react-icons/cg";
import { CiBoxList } from "react-icons/ci";

export const HeadFilter = ({ isFilterBox, setView, label }) => {

  const optionTri = [
    { label: "Tri par défault" },
    { label: "Tri par popularité" },
    { label: "Tri par notes moyennes" },
    { label: "Tri du plus récent au plus anciens" },
    { label: "Tri par tarif croissant" },
    { label: "Tri par tarif décroissant" },
  ];

  return (
    <div className="head-product">
      <p className="page-name">
        Produits / <input type="text" value={label} />
      </p>
      <div className="filters-div">
        <button className="filter btn" onClick={() => isFilterBox(true)}>
          <FaFilter />
        </button>
        {/*   <Select defaultText={"Trier par défault"}
          listOption={optionTri} /> */}
        <button className="btn" onClick={() => setView(0)}>
          <CgMenuGridR />
        </button>
        <button className="btn" onClick={() => setView(1)}>
          <CiBoxList />
        </button>
      </div>
    </div>
  );
};