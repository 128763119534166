import React, { useContext, useState } from "react";
import AuthContext from "../../../context/AuthContext";
import { validInputText } from "../../controlFields/ControlField";

import "../auth.scss";
import { HOME, REGISTTER, RESETFORM } from "../../../settings/constant";

const Login = ({ setForm }) => {
  const { loginUser } = useContext(AuthContext);
  const [done, isDone] = useState(false);
  const [value, setValue] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    validInputText(value.email, "email-error", "email");
    validInputText(value.password, "pwd-error", "pwd");

    if (validInputText(value.email, "email-error", "email")) {
      const email = value.email;
      const password = value.password;
      loginUser(email, password, HOME, isDone);
    }
  };

  return (
    <>
      <div className="login-block">
        <form className="form-login form-login-register">
          <h2> Connexion </h2>
          {done ? <p className="text-error"> Identifiant incorrect ! </p> : null}
          <div className="div-field">
            <label>Email</label>
            <input type="email" placeholder="votre email..."
              name="email"
              value={value.email}
              onChange={handleChange}
              onKeyUp={() => validInputText(value.email, "email-error", "email")} />
            <span className="span" id="email-error"></span>
          </div>
          <div className="div-field">
            <a href={RESETFORM}>Mot de passe oublier ?</a>
            <label>Mot de passe</label>
            <input type="password" placeholder="Créer votre mot de passe..."
              name="password"
              id="password"
              value={value.password}
              onChange={handleChange} />
            <span className="span" id="pwd-error"></span>
          </div>
          <div className="btn-submit">
            <button type="submit" onClick={handleSubmit}>Connexion</button>
          </div>
          <div className="foot-form">
            Vous n'avez toujours pas un compte ?
            <a href={REGISTTER} >Inscrivez-vous</a>
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;
