import React, { useContext } from "react";
import { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { ACTIVE_ACCOUNT_PAGE, API_URL, DASHBOARD_ADMIN_HOME, DASHBOARD_ADMIN_LOGIN, HOME, LOGIN, config } from "../settings/constant";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { clearCart } from "../store/cart/cartSlice";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(() =>
    localStorage.getItem("accessToken") ?
      jwt_decode(localStorage.getItem("accessToken")).user :
      null,
  );

  const userIdLocal = localStorage.getItem("userId__");


  const [userAdmin, setUserAdmin] = useState(() =>
    localStorage.getItem("accessTokenAdmin") ?
      jwt_decode(localStorage.getItem("accessTokenAdmin")).user :
      null,
  );

  const cartItem = useSelector((state) => state.cart.cartItem);

  const [loading, setLoading] = useState(true);

  /* register user */
  const registerUser = async (isPopup, isDone, setMsg, firstname, lastname, country, phone,
    email, password, link, isLose, role, storeId,
    descStore, nameStore) => {
    let links = window.location.origin + ACTIVE_ACCOUNT_PAGE;
    isPopup(true);
    try {
      const response = await axios.post(`${API_URL}account/signup`, {
        firstname: firstname,
        lastname: lastname,
        country: country,
        phone: phone,
        email: email,
        password: password,
        role: role,
        link: links,
      }, config);

      if (response.status === 200) {
        isLose(true);
        isPopup(false);
      } else if (response.status === 201) {
        if (role === "seller") {
          createStore(storeId, descStore, nameStore, response.data.userId);
        } else {
          window.location.href = ACTIVE_ACCOUNT_PAGE;
        }
      } else {
        isLose(true);
        isDone(true);
        setMsg("Erreur, ! Veuillez réessayer");
      }
    } catch (error) {
      isDone(true);
      setMsg("Erreur ! Veuillez réessayer");
      return error;
    }
  };

  // active account
  const activeAccount = async (code, isPopup, isSuccess, msg) => {
    isPopup(true);
    try {
      const response = await axios.post(`${API_URL}account/active`, {
        codeActiveAccount: code
      });
      if (response.status === 200) {
        isSuccess(true);
        isPopup(false);
      } else {
        isPopup(false);
        msg.innerText = "Ce code est erroné ou le compte n'existe pas. Veuillez créer un compte.";
      }
    } catch (error) {
      isPopup(false);
      msg.innerText = "Erreur! Vérifier votre connexion internet.";
      return error;
    }
  };

  /* login */
  const loginUser = async (email, password, link, isDone) => {
    try {
      await axios.post(`${API_URL}account/signin`, {
        email: email,
        password: password,
      }, config)
        .then((response) => {
          if (response.status === 204) {
            isDone(true);
          } else if (response.status === 200) {
            const data = response.data;
            const token = jwt_decode(data.accessToken);
            setUser(token.user);
            localStorage.setItem("refreshToken", JSON.stringify(data.refreshToken));
            localStorage.setItem("accessToken", JSON.stringify(response.data.accessToken));
            // create cart in database
            if (cartItem && cartItem.length > 0) {
              createCart(token.user.id);
            } else { window.location.href = HOME; }
          }
        });
    } catch (error) {
      return error;
    }
  };

  const createCart = async (id) => {
    try {
      const response = await axios.post(`${API_URL}shoppingCartLogin/create/${id}`, { cart: cartItem }, config);
      if (response.status === 201) {
        dispatch(clearCart());
        window.location.href = HOME;
      }
    } catch (error) {
      return error;
    }
  };

  /* login */
  const loginAdmin = async (email, password, isDone) => {
    try {
      await axios.post(`${API_URL}account/signin-admin`, {
        email: email,
        password: password,
      }, config)
        .then((response) => {
          if (response.status === 204) {
            isDone(true);
          } else if (response.status === 200) {
            const data = response.data;
            const token = jwt_decode(data.accessToken);
            setUserAdmin(token.user);
            localStorage.setItem("refreshTokenAdmin", JSON.stringify(data.refreshToken));
            localStorage.setItem("accessTokenAdmin", JSON.stringify(response.data.accessToken));
            window.location.href = DASHBOARD_ADMIN_HOME;
          }
        });
    } catch (error) {
      isDone(true);
      return error;
    }
  };

  /* reset password */
  const reset_pwd = async (email, password, type) => {
    try {

      if (type === "admin") {
        const response = await axios.put(`${API_URL}account/change-password-admin`, {
          password: password,
          email: email
        }, config);
        if (response.status === 200) {
          window.location.href = DASHBOARD_ADMIN_LOGIN;
        }
      }
      else {
        const response = await axios.put(`${API_URL}account/change-password`, {
          password: password,
          email: email
        }, config);
        if (response.status === 200) {
          window.location.href = LOGIN;
        }
      }

    } catch (error) {
      return error;
    }
  };

  /* add user admin */
  const addUserAdmin = async (email, isDone, isPopup, setMsgSucces, msg) => {
    isPopup(true);
    try {
      const response = await axios.post(`${API_URL}account/add-user-admin`, {
        email: email,
        origin: window.location.origin
      });
      if (response.status === 201) {
        isDone(true);
        setMsgSucces("Utilisateur créer avec succès !");
      } else {
        isPopup(false);
        msg.innerText = "Cet email existe déjà.";
      }
    } catch (error) {
      isPopup(false);
      msg.innerText = "Echec de la création de l'utilisateur. Réessayer";
      return error;
    }
  };

  /* create store */
  const createStore = async (descStore,
    companyName,
    country,
    mainCategory,
    phoneCompany,
    userId,
    isDone,
    createBy
  ) => {
    try {
      const response = await axios.post(`${API_URL}create/store/${userId}`, {
        desc_store: descStore,
        companyName: companyName,
        mainCategory: mainCategory,
        phoneCompany: phoneCompany,
        country: country
      });
      let data = response.data;
      if (response.status === 201) {
        if (createBy) {// si c'est créer par l'admin
          isDone(true);
        } else { // sinon si c'est créer par l'utilisateur
          window.location.href = `/Tableau-de-bord/${data.companyName.replaceAll(" ", "")}`;
        }
      }
    } catch (error) {
      return error;
    }
  };

  /* verify account user */
  const verifyAccount = async (storeId, nameStore, descStore, email, setStep) => {
    try {
      const response = await axios.get(`${API_URL}account/verify/${email}`, config);
      if (response.status === 200) {
        createStore(storeId, descStore, nameStore, response.data.id);
      } else {
        setStep(2);
      }
    } catch (error) {
      return error;
    }
  };

  const logoutUser = () => {
    setUser(null);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    window.location.href = HOME;
  };

  const logoutAdmin = () => {
    setUserAdmin(null);
    localStorage.removeItem("accessTokenAdmin");
    localStorage.removeItem("refreshTokenAdmin");
    window.location.href = DASHBOARD_ADMIN_LOGIN;
  };

  /* reset status store logout user */
  const logoutStore = async () => {
    localStorage.removeItem("accessTokenSeller");
    localStorage.removeItem("refreshTokenSeller");
    window.location.href = DASHBOARD_ADMIN_LOGIN;
  };

  // save email newsletter
  const save_email_newsletter = async (email, isDone) => {
    let date = new Date();
    let datecurrent = date.toLocaleString();
    try {
      const response = await axios.post(`${API_URL}newsletter/save-email`, {
        email: email,
        date: datecurrent
      });
      if (response.status === 200) {
        window.location.reload();
      } else {
        isDone();
      }
    } catch (error) {
      return error;
    }
  };

  // getnewsletter
  const [emailNewsletter, setEmailNewsletter] = useState([]);
  const getNewsletter = async () => {
    try {
      const response = await axios.get(`${API_URL}newsletter/get-email`, config);
      if (response.status === 200) {
        setEmailNewsletter(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  // download file excel
  const downloadFileExcelEmail = async () => {
    try {
      const response = await fetch(`${API_URL}newsletter/generate-file-email`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        },
        responseType: "blob"
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);

        // Créer un lien de téléchargement et le déclencher
        const link = document.createElement("a");
        link.href = url;
        link.download = "liste_des_adresses_mail.xlsx";
        link.click();

        // Libérer l'URL d'objet
        URL.revokeObjectURL(url);
      } else {
        console.error("Erreur lors du téléchargement du fichier Excel");
      }
    } catch (error) {
      return error;
    }
  };

  const updateUserAccount = async (formData, formData2, msg_res, isDone, isPopup, isFail, setMsg) => {
    isPopup(true);

    try {
      const response = await axios.put(`${API_URL}account/update-user/${user.id}`, formData);
      if (response.status === 200) {
        updateStoreInfo(formData2, msg_res, isDone, setMsg);
      } else {
        isPopup(false);
        isFail(false);
      }
    } catch (error) {
      isFail(false);
      return error;
    }
  };

  /* update store */
  const updateStoreInfo = async (formData, msg_res, isDone, setMsg) => {
    try {
      const response = await axios.put(`${API_URL}store/update`, formData);
      if (response.status === 200) {
        isDone(true);
        setMsg("Profil mise à jour avec succès !");
      }
    } catch (error) {
      return error;
    }
  };


  const updateUserAccount2 = async (formData, msg_res) => {
    try {
      const response = await axios.put(`${API_URL}account/update-user/${user.id}`, formData);
      if (response.status === 200) {
        msg_res.innerText = "Profil mise à jour avec succès";
      }
    } catch (error) {
      return error;
    }
  };

  const contextData = {
    user,
    userAdmin,
    userIdLocal,
    setUserAdmin,
    addUserAdmin,
    reset_pwd,
    setUser,
    registerUser,
    activeAccount,
    loginUser,
    logoutAdmin,
    loginAdmin,
    logoutUser,
    logoutStore,
    updateUserAccount,
    updateUserAccount2,
    verifyAccount,
    save_email_newsletter,
    emailNewsletter,
    getNewsletter,
    downloadFileExcelEmail,
    createStore,
  };

  useEffect(() => {
    let tok = localStorage.getItem("accessToken");
    if (tok) {
      setUser(jwt_decode(tok).user);
    }

    let tokAdmin = localStorage.getItem("accessTokenAdmin");
    if (tokAdmin) {
      setUserAdmin(jwt_decode(tokAdmin).user);
    }
    setLoading(false);
  }, [loading]);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
