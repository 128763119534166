import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "flag-icon-css/css/flag-icons.min.css";
import "./whishlist.scss";

import GeneralContext from "../../../../context/GlobalContext";
import { API_URL } from "../../../../settings/constant";

const WhishListAccount = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const { whishlist} = useContext(GeneralContext);

  const active = {
    color: "var(--orange)",
    borderBottom: "1px solid var(--orange)",
  };


  useEffect(() => {
  }, []);

  const returnTabs = () => {
    if (tabIndex === 0) {
      if (whishlist.length > 0) {
        return (
          <div className="favoris-product">
            {whishlist.map((list, index) => {
              return <Link to={{ pathname: `/produit/details/${list.Product.name_prod.replaceAll(" ", "-")}=${list.Product.id_prod}` }} state={list} key={index}>
                <div className="card-favoris" key={index}>
                  <div className="img">
                    <img src={`${API_URL}${list.Product.image_prod}`} />
                  </div>
                  <div className="infos-div">
                    <h3> {list.Product.name_prod} </h3>
                    <p className="shop-infos">
                      {Math.round(list.Product.price_prod)} {list.Product.currency}
                    </p>
                  </div>
                </div>
              </Link>;
            })
            }
          </div>
        );
      }
    }
  };

  return (
    <div className="favoris-dashboard-buyer">
      <div className="favoris-blocks parent">
        <div className="favoris-tabs">
          <div className="btn-tabs-switch">
            <button style={tabIndex === 0 ? active : null}
              onClick={() => setTabIndex(0)}> Products </button>
          </div>
          <p> Vos produits favoris nous aide à vous présenter les types de produits que vous aimez le plus </p>
          {returnTabs()}
        </div>
      </div>
    </div>
  );
};

export default WhishListAccount;
