import React, { useContext, useState } from "react";
import { validInputText } from "../../controlFields/ControlField";

import "../auth.scss";
import GeneralContext from "../../../context/GlobalContext";
import { LOGIN } from "../../../settings/constant";
import { Spinner2 } from "../../spinner2/Spinner2";

const ResetPwd = () => {
  const [email, setEmail] = useState(null);
  const { sendEmail } = useContext(GeneralContext);
  const [step, setStep] = useState(0);
  const [lose, isLose] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setStep(1);
    if (validInputText(email, "email-error", "email")) {
      sendEmail(email, setStep, isLose);
    }
  };

  const returnStep = () => {
    if (step == 0) {
      return (
        <form className="form-login form-login-register">
          <h3 className="title-reset-pwd"> Réinitialiser votre Mot de passe</h3>
          {lose ? <p className="msg-text">Cette email n'exist pas..</p>: null}
          <div className="div-field ">
            <label>Renseigner l'email de votre compte</label>
            <input type="email" placeholder="votre email..."
              name="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              onKeyUp={() => validInputText(email, "email-error", "email")} />
            <span className="span" id="email-error"></span>
          </div>
          <div className="btn-submit">
            <button type="submit" onClick={handleSubmit}>Continuer</button>
          </div>
        </form>
      );
    } else if (step == 1) {
      return <Spinner2 />;
    } else if (step == 2) {
      return (
        <div className="succes-div-send">
          <p>
            Un mail a été envoyer à votre adresse renseigner. Cliquer
            sur le lien se trouvant dans le message pour Réinitialiser votre mot de passe.
            puis connectez-vous.
          </p>
          <a href={LOGIN}> Connexion </a>
        </div>
      );
    } else if (step === 3) {
      return (
        <div className="lose-div">
          <p>Echec de l'envoie de l'email. Veuillez réessayer de nouveau</p>
          <button onClick={() => window.location.reload()} >Réessayer</button>
        </div>
      );
    }
  };

  return (
    <div className="login-block">
      {returnStep()}
    </div>
  );
};

export default ResetPwd;
