import React, { useContext, useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Shop.scss";

import CardProducts from "../../../components/cardProducts/CardProducts";
import GeneralContext from "../../../context/GlobalContext";

import CardProductList from "../../../components/cardProductList/cardProductList";
import { HeadFilter } from "../../../components/headFilter/HeadFIlter";
import { Link, useLocation } from "react-router-dom";
import FilterBoxShop from "../../../components/filterBox/FilterBoxShop";
import { API_URL } from "../../../settings/constant";
import back from "../../../assets/images/default-back.png";

import axios from "axios";

const Shop = (props) => {

  const location = useLocation();
  const data = location.state;

  const { productStore, getProductToStore,
    getProductByCategoriesToStore, getProductBySubCategoriesToStore,
  } = useContext(GeneralContext);

  const [popup, isPopup] = useState(false);
  const [category, setCategory] = useState(null);

  const [subcategorie, setSubCategorie] = useState(null);

  const [filterBox, isFilterBox] = useState(false);
  const [view, setView] = useState(0);
  const [cover, setCover] = useState(null);
  const [nameStore, setNameStore] = useState(null);

  const getDataStore = async () => {
    let id = window.location.pathname.split("/")[2];
    try {
      const response = await axios.get(`${API_URL}store/infos/${id}`);
      if (response.status === 200) {
        setCover(response.data.image_cover);
        setNameStore(response.data.companyName);
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {

    let search = decodeURIComponent(window.location.search.substring(1));
    let splitSearch = search.split("$");

    if (splitSearch.length === 2) {
      setCategory(splitSearch[0].split("=")[1].split("catId")[0].replaceAll("-", " "));
      let idCat = splitSearch[0].split("catId")[1];
      sessionStorage.setItem("cat", idCat);

      // get product by categorie to store
      getProductByCategoriesToStore(idCat);
    } else if (splitSearch.length === 3) {
      let cat = splitSearch[0].split("=")[1].split("catId")[0];
      let subcat = splitSearch[1].split("=")[1].split("subid")[0];
      let idSubCat = splitSearch[1].split("=")[1].split("subid")[1];
      setCategory(cat + " / " + subcat);
      sessionStorage.setItem("cat", cat);

      // get product by sub categorie to store
      getProductBySubCategoriesToStore(idSubCat);
    } else {
      setCategory(search.split("=")[1]);
      setSubCategorie(null);
    }

    let store_id = window.location.pathname.split("/")[2];
    if (store_id && !search) {
      getProductToStore(store_id);
    }

    getDataStore();
  }, [data, cover, nameStore]);

  return (
    <div className="shop-page">
      <FilterBoxShop filterBox={filterBox} isFilterBox={isFilterBox} />
      <section className="section-one-shop"
        style={{
          background: `url(${cover ? API_URL + cover : back})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat"
        }}>
        <div className="div-transparent">
          <h1> {nameStore} </h1>
        </div>
      </section>
      <section className="section-two-shop">
        <HeadFilter label={category ? "shop  / " + category : "shop /"} isFilterBox={isFilterBox} setView={setView} />

        {view === 0 ? (
          <div className="content-product">
            <CardProducts isPopup={isPopup} listProduct={productStore && productStore} />
          </div>
        ) :
          (
            <div className="content-product2">
              <CardProductList listProduct={productStore.length > 0 ? productStore : []} />
            </div>
          )}
      </section>
    </div>
  );
};

export default Shop;
