import React, { useContext, useEffect, useState } from "react";

import "./adress.scss";
import { validInputText } from "../../../../components/controlFields/ControlField";
import GeneralContext from "../../../../context/GlobalContext";
import { countries } from "../../../../components/countrylist/countrylist";
import { FiEdit3, FiTrash } from "react-icons/fi";
import { FaAddressBook, FaCheckCircle } from "react-icons/fa";

const Adress = () => {

  const { shippingAdress, delete_adress,
    createShippingAdress, updateShippingAdress } = useContext(GeneralContext);

  const [department, setDepartments] = useState("");

  // adresse
  const [shippingAdressId, setShippingAdressId] = useState(0);
  const [action, setAction] = useState("ajout");
  const [actionForm, setActionForm] = useState("Ajouter une nouvelle adresse");
  const [shipId, setShipId] = useState(null);
  const [fullname, setFullname] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [codePostale, setCodePostale] = useState("");
  const [numRue, setNumRue] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");

  // clear field
  const clearField = () => {
    setFullname(null);
    setCountry(null);
    setCity(null);
    setDepartments(null);
    setCodePostale(null);
    setAction("ajout");
  };

  //handle add and update adress
  const handleAddAndUpdateAdress = e => {
    e.preventDefault();
    // Ajoutez ici le code pour traiter les données du formulaire

    validInputText(fullname, "fullname-error", "text");
    validInputText(country, "countrys-error", "text");
    validInputText(city, "citys-error", "text");
    validInputText(email, "emails-error", "email");
    validInputText(telephone, "phone-error", "tel");

    console.log("yes0");

    if (validInputText(fullname, "fullname-error", "text") &&
      validInputText(country, "countrys-error", "text") &&
      validInputText(city, "citys-error", "text") &&
      validInputText(email, "emails-error", "email") &&
      validInputText(telephone, "phone-error", "tel")
    ) {
      console.log("yes1");

      if (action === "ajout") {
        console.log("yes");
        createShippingAdress(fullname, country, department, city, telephone, numRue, codePostale, email, clearField);
      } else {
        updateShippingAdress(shipId, fullname, country, department, city, telephone, numRue, codePostale, email, clearField);
      }
    }
  };

  /* Operation with adresse user */
  const update_adresse = (list) => {
    setShipId(list.id_addr);
    setFullname(list.fullname);
    setCountry(list.country);
    setCity(list.city);
    setDepartments(list.department);
    setNumRue(list.numRue);
    setEmail(list.email);
    setTelephone(list.telephone);
    setCodePostale(list.zipcode);

    setActionForm("Modifier l'adresse");
    window.location.href = "#form";
    setAction("update");
  };

  useEffect(() => {
  }, [action, shippingAdress, actionForm]);


  return (
    <div className="adress-account">
      <div className="block-addr">
        <div className="list-adress">
          <div className="ship-addr">
            <h4 className="title-bloc">
              Vos Adresses de livraison
            </h4>
            <div className="edit-form-div">
              {
                shippingAdress.length > 0 ? (
                  shippingAdress.map((list, index) => {
                    return (
                      <div key={index} className={`infos-addr ${shippingAdressId == list.id_addr ? "select-adr" : ""}`}
                        onClick={() => setShippingAdressId(list.id_addr)}>
                        <div>
                          <p>
                            <b> {list.fullname} </b>
                          </p>
                          <p> {list.telephone} </p>
                          <p> {list.country}, {list.department}, {list.city}, {list.zipcode} </p>
                        </div>
                        <div>
                          <button onClick={() => update_adresse(list)}> <FiEdit3 /> Modifier </button>
                          <button onClick={() => delete_adress(list.id_addr)}> <FiTrash /> Supprimer </button>
                        </div>
                        <span className="check"> <FaCheckCircle /> </span>
                      </div>
                    );
                  })
                ) : (
                  <p className="text-addr">
                    <FaAddressBook />
                    Auncune adresse de livraison
                  </p>
                )

              }
            </div>
          </div>
        </div>
        <div className="form-addr">
          <form onSubmit={handleAddAndUpdateAdress} id="form">
            <div className="step step1">
              <h4 className="title-bloc">
                {actionForm} <div></div>
              </h4>
              <div className="div-input inputs">
                <p>
                  <input
                    type="text"
                    placeholder="Nom et Prénom complet"
                    value={fullname}
                    onChange={(e) => setFullname(e.target.value)}
                  />
                  <span id="fullname-error"></span>
                </p>
              </div>
              <div className="div-input inputs">
                <p>
                  <select value={country}
                    onChange={e => setCountry(e.target.value)}>
                    <option selected>Sélectionner un pays</option>
                    {countries.map((list, index) => {
                      return <option value={list.name} key={index}>
                        {list.name}
                      </option>;
                    })}
                  </select>
                  <span id="countrys-error"></span>
                </p>
              </div>
              <div className="div-input inputs">
                <p>
                  <input
                    type="text"
                    placeholder="département"
                    value={department}
                    onChange={(e) => setDepartments(e.target.value)}
                  />
                  <span id="depart-error"></span>
                </p>
              </div>
              <div className="div-input">
                <p>
                  <input
                    type="text"
                    placeholder="Ville"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                  <span id="citys-error"></span>
                </p>
                <p>
                  <input
                    type="text"
                    placeholder="Code postal"
                    value={codePostale}
                    onChange={(e) => setCodePostale(e.target.value)}
                  />
                  <span id="codep-error"></span>
                </p>
              </div>
              <div className="div-input inputs">
                <p>
                  <input
                    type="text"
                    placeholder="Numéro et nom de rue"
                    value={numRue}
                    onChange={(e) => setNumRue(e.target.value)}
                  />
                  <span id="numrue-error"></span>
                </p>
              </div>
              <div className="div-input inputs">
                <p>
                  <input
                    type="text"
                    placeholder="E-mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <span id="emails-error"></span>
                </p>
              </div>
              <div className="div-input inputs">
                <p>
                  <input
                    type="text"
                    placeholder="Téléphone"
                    value={telephone}
                    onChange={(e) => setTelephone(e.target.value)}
                  />
                  <span id="phone-error"></span>
                </p>
              </div>
              <button type="submit">Valider</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Adress;