import React, { useContext, useEffect, useState } from "react";
import { FiEdit3, FiTrash2 } from "react-icons/fi";

import { HeadTabs } from "../../../../components/headTabs/HeadTabs";
import Pagination from "../../../../components/pagination2/Pagination";
import { PopupLayout } from "../../../../components/popupLayout/PopupLayout";
import SearchBar from "../../../../components/searchBar2/SearchBar";
import GeneralContext from "../../../../context/GlobalContext";
import { API_URL } from "../../../../settings/constant";

import "../sale/Sale.scss";

const OrderSeller = () => {
  const [searchWord, setSearchWord] = useState("");
  const startNbPage = 5;
  const [nextPage, setNextPage] = useState(startNbPage);
  const [previouspage, setPreviousPage] = useState(0);
  const [resultSearch, setResultSearch] = useState([]);

  const { orderss, store, getSaleStore,
    updateSaleStatus, deleteSaleStore } = useContext(GeneralContext);

  const [closes, isClose] = useState(false);
  const [delivr, isDelivr] = useState();
  const [orderId, setOrderId] = useState();
  const [dateDelivr, setDateDelivre] = useState(null);

  const search = async () => {
    if (searchWord) {
      const tab = orderss.filter(
        (item) => (item.user.firstname + " " + item.user.lastname).toLowerCase().includes(searchWord.toLowerCase()) ||
          item.Product.name_prod.toLowerCase().includes(searchWord.toLowerCase()) ||
          (item.is_paid ? "Payé" : "Non payé").toLowerCase().includes(searchWord.toLowerCase()) ||
          (item.is_delivered ? "livré" : "En attente").toLowerCase().includes(searchWord.toLowerCase())
      );

      if (tab.length > 0) {
        setResultSearch(tab);
      } 
    }
  };

  // update sale
  const handleUpdateSale = async (e) => {
    e.preventDefault();
    updateSaleStatus(orderId, delivr, dateDelivr);
  };

  const returnStore = () => {
    if (store) {
      let find = store.find(
        (item) => item.is_active === true,
      );

      if (find) {
        getSaleStore(find.store_id);
      }
    }
  };

  const UpdateSale = (id, order_id, p, d) => {
    setOrderId(order_id);
    isDelivr(d);
    isClose(true);
  };


  useEffect(() => {
    returnStore();
    search();
  }, [store, searchWord]);

  return (
    <div className="sale-dashboard-buyer">
      <PopupLayout closes={closes}>
        <div className="div-form-edit">
          <button className="close-x" onClick={() => isClose(false)} >&times;</button>
          <h2>Marquer cette commande</h2>
          <p className='text-alert'></p>
          <form>
            <p>
              <label>Status livraison : </label>
              <select value={delivr} onChange={(e) => isDelivr(e.target.value)}>
                <option value={false}>En attente</option>
                <option value={true}>Livré</option>
              </select>
            </p>
            {
              delivr ? (
                <p>
                  <label> Date de livraison : </label>
                  <input type="datetime-local"
                    value={dateDelivr}
                    onChange={e => setDateDelivre(e.target.value)} />
                </p>
              ) : null
            }
            <button onClick={handleUpdateSale}>Marquer comme livré</button>
          </form>
        </div>
      </PopupLayout>
      <HeadTabs title={`Mes Commandes ( ${orderss && orderss.length} )`} />
      <div className='sale parent'>
        <div className="child">
          <SearchBar placeholderText="Rechercher par nom produit, acheteur et status"
            searchWord={searchWord} allList={orderss.length > 0 ? orderss : null}
            setList={setResultSearch}
            setWord={setSearchWord} />
          <table className="table-data">
            <thead>
              <tr>
                <th> N° </th>
                <th> Nom Acheteur </th>
                <th> Nom du Produit </th>
                <th> Image </th>
                <th> Quantité </th>
                <th> Prix Total </th>
                <th> Status paiement</th>
                <th> Status livraison </th>
                <th> Adresse de livraison </th>
                <th> Actions </th>
              </tr>
            </thead>
            <tbody>
              {
                resultSearch.length == 0 ? (
                  orderss.length > 0 && (
                    orderss.map((list, index) => {
                        if (previouspage <= index && (nextPage - 1) >= index) {
                          return <tr key={index}>
                            <td> {index + 1} </td>
                            <td> {`${list.user.firstname} ${list.user.lastname}`} </td>
                            <td> {list.Product.name_prod} </td>
                            <td> <img src={`${API_URL}${list.Product.image_prod}`} /> </td>
                            <td> {list.qty} </td>
                            <td> {list.amount} </td>
                            <td> {list.is_paid ? "Payé" : "Non Payé"} </td>
                            <td> {list.is_delivered ? "livré" : "En attente"} </td>
                            <td>
                             {list.Order.ShippingAddress && (
                                <>
                                  {list.Order.ShippingAddress.fullname} <br />
                                  {list.Order.ShippingAddress.telephone}<br />
                                  {list.Order.ShippingAddress.country},
                                  &nbsp;{list.Order.ShippingAddress.department},
                                  &nbsp;{list.Order.ShippingAddress.city}
                                </>
                              )} 
                            </td>
                            <td>
                              <button onClick={() => UpdateSale(index + 1, list.id_oi, list.is_paid, list.is_delivered)}> <FiEdit3 /> </button>
                              {list.is_delivered ? <button onClick={() => deleteSaleStore(list.id_oi)}> <FiTrash2 /> </button> : null}
                            </td>
                          </tr>;
                        }
                    })
                  )
                ) : (
                  resultSearch.map((list, index) => {
                      return <tr key={index}>
                        <td> {index + 1} </td>
                        <td> {`${list.user.firstname} ${list.user.lastname}`} </td>
                        <td> {list.Product.name_prod} </td>
                        <td> <img src={`${API_URL}${list.Product.image_prod}`} /> </td>
                        <td> {list.qty} </td>
                        <td> {list.amount} </td>
                        <td> {list.is_paid ? "Payé" : "Non Payé"} </td>
                        <td> {list.is_delivered ? "livré" : "En attente"} </td>
                        <td>
                          {list.Order.ShippingAddress.fullname} <br />
                          {list.Order.ShippingAddress.telephone}<br />
                          {list.Order.ShippingAddress.country},
                          &nbsp;{list.Order.ShippingAddress.department},
                          &nbsp;{list.Order.ShippingAddress.city}
                        </td>
                        <td>
                          <button onClick={() => UpdateSale(index + 1, list.id_oi, list.is_paid, list.is_delivered)}> <FiEdit3 /> </button>
                          {list.is_delivered ? <button onClick={() => deleteSaleStore(list.id_oi)}> <FiTrash2 /> </button> : null}
                        </td>
                      </tr>;
                  })
                )
              }
            </tbody>
          </table>
          {resultSearch.length == 0 ? (
            <Pagination nb={startNbPage}
              countTab={orderss.length > 0 ? orderss.length : 0}
              setNextt={setNextPage}
              setPrevious={setPreviousPage}
              nextt={nextPage}
              previous={previouspage} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default OrderSeller;
