import React, { useContext, useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { FiChevronLeft, FiEdit3, FiTrash } from "react-icons/fi";
import Feexpay from "@feexpay/react-sdk";
import "./Checkout.scss";

import gif_success from "../../../assets/images/icon_success.gif";
import { PopupLayout } from "../../../components/popupLayout/PopupLayout";
import GeneralContext from "../../../context/GlobalContext";
import { ACCOUNT_ORDER, API_KEY_FEEXPAY, API_URL, CART_SHOPPING, CONDITION_GENERAL_PAGE, HOME, ID_FEEXPAY, ORDER } from "../../../settings/constant";
import SelectCountryField from "../../../components/selectCountryField/SelectCountryField";
import { validInputText } from "../../../components/controlFields/ControlField";
import { Spinner2 } from "../../../components/spinner2/Spinner2";
import AuthContext from "../../../context/AuthContext";

const Checkout = () => {
  const [popup5, isPopup5] = useState(false);
  const [popup6, isPopup6] = useState(false);
  const [popup8, isPopup8] = useState(false);
  const [popup9, isPopup9] = useState(false);
  const [done, isDone] = useState(false);
  const [done2, isDone2] = useState(false);
  const { save_sale, shoppingCartItem,
    shippingAdress, delete_adress,
    createShippingAdress, updateShippingAdress,
    sendInvoice } = useContext(GeneralContext);
  const { user } = useContext(AuthContext);

  const [action, setAction] = useState("ajout");

  // data carte bancaire send api pay
  const [department, setDepartments] = useState("");

  // adresse
  const [actionForm, setActionForm] = useState("Ajouter une nouvelle adresse");
  const [shipId, setShipId] = useState(null);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [country, setCountry] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [city, setCity] = useState("");
  const [codePostale, setCodePostale] = useState("");
  const [numRue, setNumRue] = useState("");
  const [appartment, setAppartment] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");

  const [invoiceInfos, setInvoiceInfos] = useState();
  const [saveItemSale, isSaveItemSale] = useState(false);

  // handle valid shop
  const handleValidShop = () => {
    if (shipId) {
      save_sale(shipId, totalPrice(), shoppingCartItem, setInvoiceInfos, isSaveItemSale, isPopup5, isPopup6, isDone);
    } else {
      if (shippingAdress) {
        alert("Choisissez une adresse de livraison...");
      } else {
        alert("Ajouter une adresse de livraison...");
      }
    }
  };

  const sendInvoiceShop = () => {
    if (invoiceInfos) {
      sendInvoice(invoiceInfos.order_id, invoiceInfos.createdAt,
        invoiceInfos.id_addr, isPopup8, isPopup9, isDone2);
    }
  };

  const clearField = () => {
    setFirstname(null);
    setLastname(null);
    setCountry(null);
    setCity(null);
    setDepartments(null);
    setCodePostale(null);
    setAction("ajout");
  };

  //handle add and update adress
  const handleAddAndUpdateAdress = e => {
    e.preventDefault();
    // Ajoutez ici le code pour traiter les données du formulaire

    validInputText(firstname, "firstname-error", "text");
    validInputText(lastname, "lastname-error", "text");
    validInputText(country, "country-error", "text");
    validInputText(city, "citys-error", "text");
    validInputText(email, "emails-error", "email");
    validInputText(telephone, "phone-error", "tel");

    if (validInputText(firstname, "firstname-error", "text") &&
      validInputText(lastname, "lastname-error", "text") &&
      validInputText(country, "country-error", "text") &&
      validInputText(city, "citys-error", "text") &&
      validInputText(email, "emails-error", "email") &&
      validInputText(telephone, "phone-error", "tel")) {

      if (action === "ajout") {
        createShippingAdress(firstname + " " + lastname, country, department, city, telephone, numRue, codePostale, email, clearField);
      } else {
        updateShippingAdress(shipId, firstname + " " + lastname, country, department, city, telephone, numRue, codePostale, email, clearField);
      }
    }
  };

  // total price
  const totalPrice = () => {
    if (shoppingCartItem.length > 0) {
      let price = 0;
      shoppingCartItem.forEach(element => {
        price += element.quantity * element.amount;
      });

      return price;
    }
  };

  /* Operation with adresse user */
  const update_adresse = (list) => {
    setShipId(list.id_addr);
    setFirstname(list.fullname.split(" ")[0]);
    setLastname(list.fullname.split(" ")[1]);
    setCountry(list.country);
    setCity(list.city);
    setDepartments(list.department);
    setNumRue(list.numRue);
    setEmail(list.email);
    setTelephone(list.telephone);

    setActionForm("Modifier l'adresse");
    window.location.href = "#form";
    setAction("update");
  };

  useEffect(() => {
  }, [action, shippingAdress, actionForm, shoppingCartItem]);


  return (
    <div className="checkout-page">
      <PopupLayout closes={popup5}>
        <div className="loading-pay">
          {!done ? <Spinner2 /> : (
            <div className="msg-succes-done">
              <img className="gif-succes" src={gif_success} />
              <p>
                Achat enregistré avec succès. Passez au paiement.
              </p>
              <Feexpay
                token={API_KEY_FEEXPAY}
                id={ID_FEEXPAY}
                amount={totalPrice()}
                callback={() => sendInvoiceShop()}
              />
            </div>
          )}

        </div>
      </PopupLayout>
      <PopupLayout closes={popup6}>
        <div className="loading-pay">
          <div className="msg-succes-done">
            <p>
              Validation de l'achat échoué. Vérifiez votre connexion, puis réessayer.
            </p>
            <button className="btn-click" onClick={() => window.location.reload()}>
              Réessayer
            </button>
          </div>
        </div>
      </PopupLayout>
      <PopupLayout closes={popup8}>
        <div className="loading-pay">
          {done2 ? (
            <div className="msg-succes-done">
              <p>
                Facture d'achat envoyée avec succès.
                Vous serez contacté pour la livraison dans quelques minutes.
              </p>
              <button className="btn-click" onClick={() => window.location.href = ACCOUNT_ORDER}>
                Voir la liste de vos commandes
              </button>
            </div>
          ) : (
            <>
              <p>Facturation en cour ...</p>
            </>
          )}
        </div>
      </PopupLayout>
      <PopupLayout closes={popup9}>
        <div className="loading-pay">
          <div className="msg-succes-done">
            <p>
              Facture d'achat non envoyé. contactez-nous sur <a href="https://autasys.com/contact">contact@autasys.com</a>
            </p>
          </div>
        </div>
      </PopupLayout>
      <div className="div-left-page">
        <div className="form-facturation">
          <div className="head-form-checkout">
            <h3>Détails de facturation</h3>
            <a href={CART_SHOPPING}>
              <FiChevronLeft /> Retour au panier
            </a>
          </div>
          {shippingAdress && shippingAdress.length > 0 ? (
            <div className="ship-addr">
              <h4 className="title-bloc">
                Vos Adresses de livraison <div></div>
              </h4>
              <div className="edit-form-div">
                {
                  shippingAdress.map((list, index) => {
                    return (
                      <div key={index} className={`infos-addr ${shipId == list.id_addr ? "select-adr" : ""}`}
                        onClick={() => setShipId(list.id_addr)}>
                        <div>
                          <p>
                            <b> {list.fullname} </b>
                          </p>
                          <p> {list.telephone} </p>
                          <p> {list.country}, {list.department}, {list.city}, {list.zipcode} </p>
                        </div>
                        <div>
                          <button onClick={() => update_adresse(list)}> <FiEdit3 /> Modifier </button>
                          <button onClick={() => delete_adress(list.id_addr)}> <FiTrash /> Supprimer </button>
                        </div>
                        <span className="check"> <FaCheckCircle /> </span>
                      </div>
                    );
                  })

                }
              </div>
            </div>
          ) : null}
          <form onSubmit={handleAddAndUpdateAdress} id="form">
            <div className="step step1">
              <h4 className="title-bloc">
                {actionForm} <div></div>
              </h4>
              <div className="div-input">
                <p>
                  <label>
                    Prénom <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Prénom"
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                  />
                  <span className="err" id="lastname-error"></span>
                </p>
                <p>
                  <label>
                    Nom de famille <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Nom complet"
                    value={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                  />
                  <span className="err" id="firstname-error"></span>
                </p>
              </div>
              <div className="div-input inputs">
                <p>
                  <label>
                    Pays/région de la société enregistrée <span>*</span>{" "}
                  </label>
                  <SelectCountryField
                    country_name={country}
                    setCountry={setCountry}
                    setDialCode={setDialCode}
                  />
                  <span className="err" id="country-error"></span>
                </p>
              </div>
              <div className="div-input inputs">
                <p>
                  <label>
                    Département <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="département"
                    value={department}
                    onChange={(e) => setDepartments(e.target.value)}
                  />
                  <span id="depart-error"></span>
                </p>
              </div>
              <div className="div-input">
                <p>
                  <label>
                    Ville <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Ville"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                  <span className="err" id="citys-error"></span>
                </p>
                <p>
                  <label>
                    Code postal <span>(facultatif)</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Code postal"
                    value={codePostale}
                    onChange={(e) => setCodePostale(e.target.value)}
                  />
                  <span className="err" id="codep-error"></span>
                </p>
              </div>
              <div className="div-input">
                <p>
                  <label>
                    Numéro et nom de rue <span> (facultatif)</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Numéro et nom de rue"
                    value={numRue}
                    onChange={(e) => setNumRue(e.target.value)}
                  />
                  <span className="err" id="numrue-error"></span>
                </p>
                <p>
                  <label>
                    Appartement, suite, maison <span> (facultatif) </span>
                  </label>
                  <input
                    type="text"
                    placeholder="Appartement, suite, maison"
                    value={appartment}
                    onChange={(e) => setAppartment(e.target.value)}
                  />
                  <span className="err" id="appt-error"></span>
                </p>
              </div>
              <div className="div-input inputs">
                <p>
                  <label>
                    E-mail <span>*</span>{" "}
                  </label>
                  <input
                    type="text"
                    placeholder="E-mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <span className="err" id="emails-error"></span>
                </p>
              </div>
              <div className="div-input inputs">
                <p>
                  <label>
                    Téléphone <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Téléphone"
                    value={telephone}
                    onChange={(e) => setTelephone(e.target.value)}
                  />
                  <span className="err" id="phone-error"></span>
                </p>
              </div>
              <button type="submit">Valider</button>
            </div>
          </form>
        </div>
      </div>
      <div className="div-order-summary">
        <div className="div-card-summary">
          <div className="product-item">
            {shoppingCartItem && shoppingCartItem.length > 0 ? (
              shoppingCartItem.map((list, index) => (
                <div className="cart-item" key={index}>
                  <img src={`${API_URL}${list.Product.image_prod}`} />
                  <h3> {list.Product.name_prod} </h3>
                  <h4> {list.quantity * list.amount} <sup>CFA</sup> </h4>
                  <span>x{list.quantity}</span>
                </div>
              ))
            ) : null}
          </div>
          <p>
            <span>Sous total :</span>
            <b> {totalPrice()} <sup>CFA</sup> </b>
          </p>
          <p>
            <span>Total :</span>
            <b> {totalPrice()} <sup>CFA</sup></b>
          </p>
          <span className="text-cond">
            Vos données personnelles seront utilisées pour traiter votre commande, soutenir votre expérience sur ce site Web et à d'autres fins décrites dans notre
            &nbsp;<a href="">politique de confidentialité.</a>
          </span>
          <div className="di">
            <input type="checkbox" value="" />
            <span>
              J'ai lu et j'accepte les <a href={CONDITION_GENERAL_PAGE}>conditions générales</a> de ce site web.
            </span>
          </div>
          {saveItemSale ? (
            <Feexpay
              token="fp_J8tUIl4nZaWbqSyPd8ySb5GTC0QpSSmQp9ZHw4756O23OzdoUO8sqGGBwtCvuW8"
              id="649d6cccef6b9dce7c6537d6"
              amount={totalPrice()}
              callback={() => alert("ok")}
            />
          ) : (
            <button className="btn-valid" onClick={handleValidShop} > Valider la commande </button>
          )}

        </div>
      </div>
    </div>
  );
};

export default Checkout;
