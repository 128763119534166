import React, { useEffect, useContext, useState } from "react";
import { AiOutlineLink } from "react-icons/ai";
import { SlActionRedo } from "react-icons/sl";
import { HiOutlineCursorClick } from "react-icons/hi";
import "./Home.scss";
import "flag-icon-css/css/flag-icons.css";

import { HeadTabs } from "../../../../components/headTabs/HeadTabs";
import GeneralContext from "../../../../context/GlobalContext";
import { API_URL, DASHBOARD_SELLER_ADD_PRODUCTS, DASHBOARD_SELLER_PRODUCTS } from "../../../../settings/constant";

const HomeSeller = () => {

  const { store, statsStore, getStatsStore,
    get_code_country } = useContext(GeneralContext);
  const [storeActive, setStoreActive] = useState("");

  // return infos for profil complete
  const returnInfos = () => {
    if (statsStore) {
      const storess = statsStore.store;
      if (storess) {
        if (statsStore.store.user) {
          const users = statsStore.store.user;
          if ((!users.firstname && !users.lastname) || !users.country || !users.city || !users.phone || !storess.desc_store) {
            return (
              <div className="info-account">
                <div className="div-percent">
                  <p>
                    Votre profile est à 75%. Veuillez complèté votre profil
                  </p>
                  <div className="bars"></div>
                </div>
              </div>
            );
          } else {
            return (
              <div className="info-account">
                <p>
                  <span> {users.firstname + " " + users.lastname} </span>
                  <span> <i className={`flag-icon flag-icon-${get_code_country(users.country)}`}></i> {users.country}, {users.city} </span>
                </p>
                <img src={`${API_URL}${users.image_profil}`} />
              </div>
            );
          }
        }
      }
    }
  };

  const getStoreActive = () => {
    if (store) {
      let find = store.find(
        (item) => item.is_active === true,
      );

      if (find) {
        setStoreActive(find.companyName.replace(/[-'_`\s]/, "").trim());
      }
    }
  };

  /* return link store */
  const getLinkStore = () => {
    let link = "";
    let origin = window.location.origin;
    if (store && store.length > 0) {
      let find = store.find(
        (item) => item.is_active === true,
      );

      if (find) {
        link = `${origin}/${find.companyName.replaceAll(" ", "")}/${find.store_id}`;
      }
    }
    return link;
  };

  useEffect(() => {
    getStoreActive();
    getStatsStore();
  }, [store]);

  return (
    <div className="home-dashboard-buyer">
      <HeadTabs title={"Tableau de bord"} />
      <div className="block-dashboard">
        <div className="block-cards">
          <div className="cards">
            <p>
              <input type="text" value={statsStore ? (statsStore.products ? statsStore.products.length : 0) : 0} />
              <span>Produits</span>
            </p>
            <div className="barss"></div>
          </div>
          <div className="cards">
            <p>
              <input type="text" value={statsStore ? (statsStore.saleNoDelivered ? statsStore.saleNoDelivered.length : 0) : 0} />
              <span>Commandes</span>
            </p>
            <div className="barss"></div>
          </div>
          <div className="cards">
            <p>
              <input type="text" value={statsStore ? (statsStore.saleDelivered ? statsStore.saleDelivered.length : 0) : 0} />
              <span>Ventes</span>
            </p>
            <div className="barss"></div>
          </div>
          <div className="cards">
            {returnInfos()}
          </div>
        </div>
        <div className="card-graph">
          <div className="link-visits">
            <h3>Action rapide <SlActionRedo /></h3>
            <div className="div-action-rapide">
              <a href={storeActive ? `/Tableau-de-bord/${storeActive}/nouveau-produit` : null}><HiOutlineCursorClick /> Ajouter un produit </a>
              <a href={storeActive ? `/Tableau-de-bord/${storeActive}/produits` : null}> <HiOutlineCursorClick /> Mes produits </a>
              <a href={storeActive ? `/Tableau-de-bord/${storeActive}/ventes` : null}> <HiOutlineCursorClick /> Mes Ventes </a>
              <a href={storeActive ? `/Tableau-de-bord/${storeActive}/commandes` : null}> <HiOutlineCursorClick /> Commande reçu </a>
            </div>
            <div className="link-divs">
              <a className="visit-store" href={getLinkStore()} target="blank">
                <AiOutlineLink />
                Visitez ma boutique
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSeller;
