/* All path to page */

export const LOGIN = "/connexion";
export const REGISTTER = "/inscription";
export const ACTIVE_ACCOUNT_PAGE = "/activation-de-mon-compte";
export const RESETFORM = "/reinitialiser-votre-mot-de-passe";
export const RESETPWD = "/reinitialiser-votre-mot-de-passe/reset";
export const HOME = "/";
export const PRODUCT_CATEGORY = "/categories/produits";
export const PRODUCT_CATEGORY_PROD = "/categories/produits/:category/:produit";
export const SEARCH_PRODUCT_PAGE = "/produits/rechercher";
export const CART_SHOPPING = "/mon-panier";
export const PRODUCT_DETAILS = "/produit/details/:title";
export const CHECKOUT = "/payement";
export const PROMO_PAGE = "/nos-offres";
export const MESSAGE = "/messages";
export const ORDER = "/commandes";
export const WHISHLIST = "/mes-favoris";
export const ARTICLE = "/article/:title";
export const ABOUT_PAGE = "/a-propos-de-nous";
export const CONDITION_GENERAL_PAGE = "/conditions-generales-utilisation";
export const POLITIC_REMBOURSEMENT_PAGE = "/politiques-de-remboursement";
export const POLITIC_CONFIDENTIALITe_PAGE = "/politiques-de-confidentialite";
export const CONTACT_PAGE = "/contact";
export const FAQ_PAGE = "/foire-aux-questions";
export const DOCUMENTATION = "/documentation-vendeur";
export const DOCUMENTATION_ADMIN = "/documentation-admin";

/* shop route */
export const SHOP_HOME = "/:name/:id";

/* user account route */
export const ACCOUNT_HOME = "/mon-compte/tableau-de-bord";
export const ACCOUNT_ORDER = "/mon-compte/commandes";
export const ACCOUNT_DOWNLOAD = "/mon-compte/telechargements";
export const ACCOUNT_ADRESSE = "/mon-compte/adresses";
export const ACCOUNT_DETAILS = "/mon-compte/details-du-compte";
export const ACCOUNT_WHISHLIST = "/mon-compte/favoris";
export const ACCOUNT_ADRESS = "/mon-compte/adresses";

/* route dashboard seller */
export const DASHBOARD_SELLER_HOME = "/Tableau-de-bord/:name_store";
export const DASHBOARD_SELLER_PROFIL = "/Tableau-de-bord/:name_store/profile";
export const DASHBOARD_SELLER_PRODUCTS = "/Tableau-de-bord/:name_store/produits";
export const DASHBOARD_SELLER_ADD_PRODUCTS = "/Tableau-de-bord/:name_store/nouveau-produit";
export const DASHBOARD_SELLER_COBEILLE_PRODUCT = "/Tableau-de-bord/:name_store/produits/cobeille";
export const DASHBOARD_SELLER_COBEILLE_VENTE = "/Tableau-de-bord/:name_store/ventes/cobeille";
export const DASHBOARD_SELLER_SALLE = "/Tableau-de-bord/:name_store/ventes";
export const DASHBOARD_SELLER_ORDER = "/Tableau-de-bord/:name_store/commandes";
export const DASHBOARD_SELLER_TRANSACTIONS_REQUEST = "/Tableau-de-bord/:name_store/demande-des-transactions";
export const CART_LINK_PAGE = "/:name/:id/payement=panier?";

// admin routes
export const DASHBOARD_ADMIN_RESETPWD = "/Tableau-de-bord/administrateur/réinitialiser-mot-de-passe";
export const DASHBOARD_ADMIN_RESETFORM = "/Tableau-de-bord/administrateur/réinitialiser-mot-de-passe/reset";
export const DASHBOARD_ADMIN_HOME = "/Tableau-de-bord/administrateur";
export const DASHBOARD_ADMIN_LOGIN = "/Tableau-de-bord/administrateur/connexion";
export const DASHBOARD_ADMIN_ARTICLE = "/Tableau-de-bord/administrateur/articles";
export const DASHBOARD_ADMIN_SHOP = "/Tableau-de-bord/administrateur/boutiques";
export const DASHBOARD_ADMIN_SETTINGS = "/Tableau-de-bord/administrateur/reglages";
export const DASHBOARD_ADMIN_ORDER = "/Tableau-de-bord/administrateur/Commandes";
export const DASHBOARD_ADMIN_SALE = "/Tableau-de-bord/administrateur/ventes";
export const DASHBOARD_ADMIN_CATEGORY = "/Tableau-de-bord/administrateur/Categories";
export const DASHBOARD_ADMIN_PRODUCTS = "/Tableau-de-bord/administrateur/produits";
export const DASHBOARD_ADMIN_ADD_PRODUCTS = "/Tableau-de-bord/administrateur/nouveau-produit";
export const DASHBOARD_ADMIN_COBEILLE_PRODUCT = "/Tableau-de-bord/administrateur/produits/cobeille";
export const DASHBOARD_ADMIN_TRANSACTION = "/Tableau-de-bord/administrateur/demande-de-transaction";
export const DASHBOARD_ADMIN_NEWSLETTER = "/Tableau-de-bord/administrateur/newsletter";

/* API URL */
export const API_URL = "https://backend.autasys.com/";

/* identifiant feexpay */
export const API_KEY_FEEXPAY = "fp_J8tUIl4nZaWbqSyPd8ySb5GTC0QpSSmQp9ZHw4756O23OzdoUO8sqGGBwtCvuW8F";
export const ID_FEEXPAY = "649d6cccef6b9dce7c6537d6";

export const config = {
  headers: {
    "Access-Control-Allow-Origin": "https://autasys.com",
    "Content-Type": "application/json",
  },
};
