import React, { useContext, useEffect, useState } from "react";
import { FiDelete, FiEdit3, FiTrash } from "react-icons/fi";
import { MdOutlinePublish } from "react-icons/md";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import GeneralContext from "../../../../context/GlobalContext";
import { validInputText } from "../../../../components/controlFields/ControlField";
import SearchBar from "../../../../components/searchBar2/SearchBar";
import Pagination from "../../../../components/pagination2/Pagination";
import { FaFileImage } from "react-icons/fa";
import { BoxAlertSucess } from "../../../../components/boxAlertSucess/BoxAlertSucess";
import { API_URL } from "../../../../settings/constant";
import { createSlug } from "../../../../components/createSlug/CreatSlug";

const AddArticle = () => {
  const [searchWord, setSearchWord] = useState("");
  const startNbPage = 6;
  const [nextPage, setNextPage] = useState(startNbPage);
  const [previouspage, setPreviousPage] = useState(0);
  const [resultSearch, setResultSearch] = useState([]);

  const [fail, isFail] = useState(false);
  const [id_at, setIdAt] = useState(null);

  const { articleAdmin, getArticleAdmin, addArticle, updateArticle,
    deleteArticle, publishArticle,
    NoPublishArticle, getCategoriesArticle } = useContext(GeneralContext);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [title, setTitle] = useState(null);
  const [category, setCategory] = useState(null);
  const [description, setDescription] = useState(null);
  const [msgSucces, setMsgSucces] = useState("");
  const [done, isDone] = useState(false);
  const [popup1, isPopup1] = useState(false);
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);

  // search 
  const searchArticle = () => {
    if (searchWord) {
      const tab = articleAdmin.filter(
        (item) => item.nameCat.toLowerCase().includes(searchWord.toLowerCase()) ||
          item.title.toLowerCase().includes(searchWord.toLowerCase())
      );

      if (tab.length > 0) {
        setResultSearch(tab);
      } else {
        setResultSearch([]);
      }
    }
  };

  // handl select multiple image
  const handleFileSelect = (event) => {
    const files = event.target.files;
    let msg = document.getElementById("img-err");
    if (files.length > 4) {
      // Si plus de 5 fichiers sélectionnés, vous pouvez afficher un message d'erreur ou effectuer une action appropriée
      msg.innerText = "Vous ne pouvez qu'en sélectionner 3";
      return;
    } else if (files.length === 1) {
      msg.innerText = files.length + " fichier image sélectionné.";
    } else if (files.length > 1) {
      msg.innerText = files.length + " fichiers images sélectionnés.";
    }
    setSelectedFiles([...files]);
  };

  // update 
  const setInfosArticle = (list) => {
    setTitle(list.title);
    setCategory(list.id_cat);
    setDescription(list.description);
    setImage1(list.image1);
    setImage2(list.image2);
    setImage3(list.image3);
    setIdAt(list.id_at);

    let text = document.getElementById("text");
    let btn = document.getElementById("btn");
    text.innerText = "Modification";
    btn.innerText = "Modifier";
  };

  // handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    let img_err = document.getElementById("img-err");
    let desc_err = document.getElementById("desc-err");

    let date = new Date();
    let dateCurrent = date.toLocaleString();

    validInputText(title, "title-err", "text2");

    if (validInputText(title, "title-err", "text2")) {
        if (description) {
          if ((selectedFiles.length > 0 && selectedFiles.length < 4) || image1) {
            const slug = createSlug(title);
            const formData = new FormData();
            selectedFiles.forEach((file) => {
              formData.append("images", file);
            });
            formData.append("category", category);
            formData.append("title", title);
            formData.append("slug", slug);
            formData.append("description", description);
            formData.append("createdAt", dateCurrent);
  
            if (id_at) {
              updateArticle(id_at, formData, setMsgSucces, isFail, isDone, isPopup1);
            } else {
              addArticle(formData, setMsgSucces, isFail, isDone, isPopup1);
            }
          } else {
            img_err.innerText = "Sélectionner au moins une image";
          }
        } else {
          desc_err.innerText="la description de l'article est obligatoire";
        }
    }
  };

  useEffect(() => {
    // search automatik
    searchArticle();

    // get article
    getArticleAdmin();

    // get categorie article
    getCategoriesArticle();

  }, [description]);

  return (
    <div className="add-article box-art">
      <BoxAlertSucess popup={popup1}
        text={msgSucces}
        isDone={isDone}
        done={done}
        handleBtn={isPopup1}
        link={"#"} />
      <div className="child">
        <SearchBar placeholderText="Rechercher par titre"
          searchWord={searchWord} allList={articleAdmin.length > 0 ? articleAdmin : null}
          setList={setResultSearch}
          setWord={setSearchWord} />
        <table className="table-data">
          <thead>
            <tr>
              <th> N° </th>
              <th> Titre </th>
              <th> Date de création </th>
              <th> Status </th>
              <th> Actions </th>
            </tr>
          </thead>
          <tbody>
            {
              resultSearch.length == 0 ? (
                articleAdmin.length > 0 && (
                  articleAdmin.map((list, index) => {
                    if (previouspage <= index && (nextPage - 1) >= index) {
                      return <tr key={index}>
                        <td> {index + 1} </td>
                        <td> {list.title} </td>
                        <td> {list.createdAt} </td>
                        <td> {list.published ? "Publier" : "Non Publier"} </td>
                        <td>
                          {!list.published ? (
                            <button onClick={() => publishArticle(list.id_at, setMsgSucces, isFail, isDone, isPopup1)}> <MdOutlinePublish /> <br /> Publier </button>
                          ) : (
                            <button onClick={() => NoPublishArticle(list.id_at, setMsgSucces, isFail, isDone, isPopup1)}> <FiDelete /><br />  Retirer </button>
                          )}
                          <button onClick={() => setInfosArticle(list)}> <FiEdit3 /> <br /> Editer </button>
                          <button onClick={() => deleteArticle(list.id_at, setMsgSucces, isFail, isDone, isPopup1)}> <FiTrash /> Supprimer </button>
                        </td>
                      </tr>;
                    }
                  })
                )
              ) : (
                resultSearch.map((list, index) => {
                  return <tr key={index}>
                    <td> {index + 1} </td>
                    <td> {list.title} </td>
                    <td> {list.description} </td>
                    <td> {list.createdAt} </td>
                    <td> {list.published ? "Ne pas publier" : "Publier"} </td>
                    <td>
                      <button onClick={() => publishArticle(list.id_at, setMsgSucces, isFail, isPopup1)}> Publier </button>
                      <button onClick={() => setInfosArticle(list)}> <FiEdit3 /> </button>
                      <button onClick={() => deleteArticle(list.id_at, setMsgSucces, isFail, isPopup1)}> <FiDelete /> Supprimer </button>
                    </td>
                  </tr>;
                })
              )

            }
          </tbody>
        </table>
        {resultSearch.length == 0 ? (
          <Pagination nb={startNbPage}
            countTab={articleAdmin.length > 0 ? articleAdmin.length : 0}
            setNextt={setNextPage}
            setPrevious={setPreviousPage}
            nextt={nextPage}
            previous={previouspage} />
        ) : null}
      </div>
      <div className="add-categorie">
        <form className="forms">
          <h3 id="text"> Nouvelle article </h3>
          {fail ? <p className="msg-text">
            Echec de l'opération ! Réessayer
          </p> : null}
          <div className="div-input">
            <input type="text"
              placeholder="Titre ...."
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
            <span className="err" id="title-err"></span>
          </div>
          {/* <div className="div-input">
            <select
              value={category}
              onChange={e => setCategory(e.target.value)}
            >
              <option selected disabled> Choisissez une catégorie </option>
              {categoryArticle && categoryArticle.map((list, index) => {
                return <option value={list.id_cat} key={index}> {list.nomCat} </option>;
              })}
            </select>
            <span className="err" id="cat-err"></span>
          </div> */}
          <ReactQuill className="editor" theme="snow" value={description} onChange={setDescription} />
          <span className="err" id="desc-err"></span>
            <div className="div-select-file">
            <div className="div-file">
              <FaFileImage />
              <span>Télécharger Jusqu’à 4 images</span>
              <input type="file" multiple
                onChange={handleFileSelect} accept="*.jpg,*.png,*.jpeg" />
            </div>
            <div className="show-img">
              {selectedFiles && selectedFiles.map((list, index) => {
                return <span key={index}> {list.name} / </span>;
              })}
            </div>
          </div>
          {image1 && (
            <div className="images">
              <img src={`${API_URL}${image1}`} />
              {
                image2 && <img src={`${API_URL}${image2}`} />
              }
              {
                image3 && <img src={`${API_URL}${image3}`} />
              }
            </div>
          )}
          <span className="err" id="img-err"></span>
          <p>Vous pouvez sélectionner jusqu'à 4 images</p>
          <button id="btn" onClick={handleSubmit}>
            Ajouter
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddArticle;