import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "./CardProducts.scss";

import GeneralContext from "../../context/GlobalContext";
import { FiHeart } from "react-icons/fi";
import { PopupLayout } from "../popupLayout/PopupLayout";
import { BoxDetailsProd } from "../boxDetailsProd/boxDetailsProd";
import { API_URL } from "../../settings/constant";

const CardProducts = ({ listProduct }) => {
  const [popup, isPopup] = useState(false);
  const [data, isData] = useState();
  const { add_favoris, whishlist } = useContext(GeneralContext);

  const activebtn = () => {
    let all = document.querySelectorAll(".card-products .svg");
    if (whishlist.length > 0) {
      for (let i = 0; i < whishlist.length; i++) {
        for (let j = 0; j < listProduct.length; j++) {
          if (whishlist[i].Product.id_prod === listProduct[j].id_prod) {
            all[j].classList.add("active-whishlist-btn");
          }
        }
      }
    }
  };

  useEffect(() => {
    activebtn();
  }, []);

  return (
    <>
      <PopupLayout closes={popup}>
        <div className="detail-show-popup">
          <span className="close" onClick={() => isPopup(false)}>&times;</span>
          <BoxDetailsProd data={data} />
        </div>
      </PopupLayout>
      {
        listProduct && (
          listProduct.map((list, index) => {
            return <div className="card-products" key={index}>
              <div className="div-prod">
                <button className="btn-heart" onClick={() => add_favoris(list.id_prod, ".card-products .svg", index, activebtn)}>
                  <FiHeart className='svg' />
                </button>
                <Link to={{ pathname: `/produit/details/${list.name_prod.replaceAll(" ", "-")}=${list.id_prod}` }} state={list}>
                  <img src={`${API_URL}${list.image_prod}`} />
                </Link>
                <button className="btn-view" onClick={() => {
                  isPopup(true);
                  isData(list);
                }}> Voir le produit </button>
              </div>
              <div className="info-prod-card">
                <h4> {list.name_prod} </h4>
                <h5> {Math.round(list.price_prod)} {list.currency} </h5>
              </div>
            </div>;
          })
        )
      }
    </>
  );
};

export default CardProducts;
