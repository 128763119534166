import React, { useEffect } from "react";

import "./InputDialCode.scss";


const InputDialCode = ({ dialCode, setPhone }) => {

  return (
    <div className='input-phone'>
      <input type="text" value={dialCode} className="dial-code" />
      <input type="tel" placeholder='00 00 00 00 ' className="input-phone"
        onChange={e => setPhone(e.target.value)} />
    </div>
  );
};

export default InputDialCode;