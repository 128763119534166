import React from "react";
import { FiChevronDown, FiChevronsDown } from "react-icons/fi";

import "./Documentation.scss";

import logo from "../../../assets/images/png/logo.png";
import { DASHBOARD_ADMIN_LOGIN, DOCUMENTATION } from "../../../settings/constant";

import form_seller from "./../../../assets/images/form-seller-admin.png";
import article from "./../../../assets/images/article.png";
import transaction from "./../../../assets/images/transaction.png";
import categorie from "./../../../assets/images/categorie.png";
import newsletter from "./../../../assets/images/newsletter.png";

const DocumentationAdmin = () => {
    return (
        <div className="DocumentationPage">
            <div className="DocumentationPage-sidebar">
                <img className="DocumentationPage-logo" src={logo} />
                <ul className="DocumentationPage-menu">
                    <h2 className="DocumentationPage-rubrique">Administrateur <FiChevronDown /></h2>
                        <li>
                            <a href="#creation-boutique-vendeur">Création de boutique vendeur</a>
                        </li>
                        <li>
                            <a href="#ajout-de-categorie">Ajout de catégorie de produit</a>
                        </li>
                        <li>
                            <a href="#ajout-de-produit">Ajout d'un produit</a>
                        </li>
                        <li>
                            <a href="#enregistre-une-vente">Enregistrement d'une vente</a>
                        </li>
                        <li>
                            <a href="#enregistre-un-article">Enregistrement d'un article</a>
                        </li>
                        <li>
                            <a href="#newsletter">Gestion de la Newsletter</a>
                        </li>
                        <li>
                            <a href="#transaction">Gestion des Transactions</a>
                        </li>
                </ul>
            </div>
            <div className="DocumentationPage-body">
                <h1 className="DocumentationPage-h1">Documentation administrateur de <b>Autasys.com</b></h1>
                <ul className="DocumentationPage-menu2">
                    <li>
                        <a href="#creation-boutique-vendeur">Création de boutique vendeur</a>
                    </li>
                    <li>
                        <a href="#ajout-de-categorie">Ajout de catégorie de produit</a>
                    </li>
                    <li>
                        <a href="#ajout-de-produit">Ajout d'un produit</a>
                    </li>
                    <li>
                        <a href="#enregistre-une-vente">Enregistrement d'une vente</a>
                    </li>
                    <li>
                        <a href="#enregistre-un-article">Enregistrement d'un article</a>
                    </li>
                    <li>
                        <a href="#newsletter">Gestion de la Newsletter</a>
                    </li>
                    <li>
                        <a href="#transaction">Gestion des Transactions</a>
                    </li>
                </ul>
                <div className="DocumentationPage-mainContaint">
                    <p className="DocumentationPage-infos">
                        Connectez-vous au tableau de bord administrateur à travers ce lien <a href={DASHBOARD_ADMIN_LOGIN}>Tableau de bord administrateur</a> 
                        <br/>Informations de connexion:<br/> Email : autasys@gmail.com <br/> Mot de passe: réinitialiser le mot de passe 
                    </p>
                    <div className="BlockContaint" id="creation-boutique-vendeur">
                        <h3 className="BlockContaint-title">1-  Créaction de boutique vendeur</h3>
                        <p className="BlockContaint-description">
                            Pour créer une boutique depuis le tableau de bord, suivez ces étapes :<br/>

                            1. Rendez-vous sur la page des boutiques en cliquant sur la rubrique **Boutique**. <br/>

                            2. Cliquez sur le bouton **Créer une Boutique**, puis remplissez le formulaire et validez.<br/>

                            3. Un compte utilisateur est automatiquement créé et lié à la boutique.<br/>

                            4. Un e-mail de confirmation est envoyé à l'adresse e-mail renseignée lors de la création de la boutique.<br/>

                            5. Procédez à la confirmation du compte créé en cliquant sur le lien de confirmation reçu par e-mail.<br/>

                            6. Connectez-vous sur le site pour accéder au tableau de bord vendeur.<br/>
                        </p>
                        <img src={form_seller} alt="" />
                    </div>
                    <div className="BlockContaint" id="ajout-de-categorie">
                        <h3 className="BlockContaint-title">2-  Ajout de catégorie de produit</h3>
                        <p className="BlockContaint-description">
                        Pour ajouter une nouvelle catégorie, suivez ces étapes :<br/>
                            1. Cliquez sur la rubrique **Catégorie**.<br/>
                            2. Ajoutez une nouvelle catégorie. <br/>             
                        </p>
                        <img src={categorie} alt="" />
                    </div>
                    <div className="BlockContaint" id="ajout-de-produit">
                        <h3 className="BlockContaint-title">3-  Ajout de produit</h3>
                        <p className="BlockContaint-description">
                            Confère <a href={`${DOCUMENTATION}#ajout-de-produit`}>Documentation vendeur</a>
                        </p>
                    </div>
                    <div className="BlockContaint" id="enregistre-une-vente">
                        <h3 className="BlockContaint-title">4-  Enrégistrer une vente</h3>
                        <p className="BlockContaint-description">
                            Confère <a href={`${DOCUMENTATION}#enregistre-une-vente`}>Documentation vendeur</a>
                        </p>
                    </div>
                    <div className="BlockContaint" id="enregistre-un-article">
                        <h3 className="BlockContaint-title">5-  Enrégistrer un article</h3>
                        <p className="BlockContaint-description">
                            Pour enregistrer un article, suivez ces étapes :<br/>
                            1. Cliquez sur la rubrique **Article**.<br/>
                            2. Procédez à l'ajout.                        
                        </p>
                        <img src={article} alt="" />
                    </div>
                    <div className="BlockContaint" id="newsletter">
                        <h3 className="BlockContaint-title">6-  Newsletter</h3>
                        <p className="BlockContaint-description">
                        Pour recueillir les adresses e-mail de la newsletter, suivez ces étapes :<br/>
                            1. Cliquez sur la rubrique **Newsletter**.<br/>
                            2. Vous verrez la liste des adresses e-mail inscrites pour la newsletter et pourrez les télécharger.
                        </p>
                        <img src={newsletter} alt="" />
                    </div>
                    <div className="BlockContaint" id="transaction">
                        <h3 className="BlockContaint-title">7-  Transaction</h3>
                        <p className="BlockContaint-description">
                        Rendez-vous sur la page de la rubrique **Transaction** et procédez aux diverses opérations.<br/>
                            1. Si vous approuvez une demande, cela signifie que cette demande sera satisfaite par la suite.<br/>
                            2. Si vous satisfaites une demande, cela signifie que vous avez déjà répondu à cette demande.
                        </p>
                        <img src={transaction} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DocumentationAdmin;