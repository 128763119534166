import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomeSeller from "./pages/dashboard/dashboardSeller/home/Home";
import LayoutSeller from "./pages/dashboard/dashboardSeller/LayoutSeller";
import AddProduct from "./pages/dashboard/dashboardSeller/products/AddProduct";
import Cobeille from "./pages/dashboard/dashboardSeller/products/Cobeille";
import ListProduct from "./pages/dashboard/dashboardSeller/products/ListProducts";
import ProfilSeller from "./pages/dashboard/dashboardSeller/profil/ProfilSeller";
import Sale from "./pages/dashboard/dashboardSeller/sale/Sale";
import Trash from "./pages/dashboard/dashboardSeller/sale/Trash";
import PrivateRoute from "./PrivateRoute";

import {
  ABOUT_PAGE,
  ACCOUNT_ADRESS,
  ACCOUNT_DETAILS,
  ACCOUNT_DOWNLOAD,
  ACCOUNT_HOME,
  ACCOUNT_ORDER,
  ACCOUNT_WHISHLIST,
  ACTIVE_ACCOUNT_PAGE,
  ARTICLE,
  CART_SHOPPING,
  CHECKOUT,
  CONDITION_GENERAL_PAGE,
  CONTACT_PAGE,
  DASHBOARD_ADMIN_ADD_PRODUCTS,
  DASHBOARD_ADMIN_ARTICLE,
  DASHBOARD_ADMIN_CATEGORY,
  DASHBOARD_ADMIN_COBEILLE_PRODUCT,
  DASHBOARD_ADMIN_HOME,
  DASHBOARD_ADMIN_LOGIN,
  DASHBOARD_ADMIN_NEWSLETTER,
  DASHBOARD_ADMIN_ORDER,
  DASHBOARD_ADMIN_PRODUCTS,
  DASHBOARD_ADMIN_RESETFORM,
  DASHBOARD_ADMIN_RESETPWD,
  DASHBOARD_ADMIN_SALE,
  DASHBOARD_ADMIN_SETTINGS,
  DASHBOARD_ADMIN_SHOP,
  DASHBOARD_ADMIN_TRANSACTION,
  DASHBOARD_SELLER_ADD_PRODUCTS,
  DASHBOARD_SELLER_COBEILLE_PRODUCT,
  DASHBOARD_SELLER_COBEILLE_VENTE,
  DASHBOARD_SELLER_HOME,
  DASHBOARD_SELLER_ORDER,
  DASHBOARD_SELLER_PRODUCTS,
  DASHBOARD_SELLER_PROFIL,
  DASHBOARD_SELLER_SALLE,
  DASHBOARD_SELLER_TRANSACTIONS_REQUEST,
  DOCUMENTATION,
  DOCUMENTATION_ADMIN,
  FAQ_PAGE,
  HOME,
  LOGIN,
  POLITIC_CONFIDENTIALITe_PAGE,
  POLITIC_REMBOURSEMENT_PAGE,
  PRODUCT_CATEGORY,
  PRODUCT_CATEGORY_PROD,
  PRODUCT_DETAILS,
  PROMO_PAGE,
  REGISTTER,
  RESETFORM,
  RESETPWD,
  SEARCH_PRODUCT_PAGE,
  SHOP_HOME,
} from "./settings/constant";
import OrderSeller from "./pages/dashboard/dashboardSeller/order/Order";
import Home from "./pages/site/home";
import LayoutHome from "./layouts/layoutSite/LayoutHome";
import Products from "./pages/site/product/Product";
import ProductDetails from "./pages/site/productDetails/ProductDetails";
import LayoutHome2 from "./layouts/layoutSite/LayoutHome2";
import Cart from "./pages/site/cart/cart";
import HomeAccount from "./pages/site/account-pages/home/home";
import Account from "./pages/site/account-pages";
import OrderAccount from "./pages/site/account-pages/order/order";
import WhishlistAccount from "./pages/site/account-pages/whishlist/whishlist";
import Adress from "./pages/site/account-pages/adress/adress";
import ProfilAccount from "./pages/site/account-pages/profil/profil";
import Download from "./pages/site/account-pages/download/download";
import Login from "./components/authForms/login/Login2";
import LayoutAuth from "./layouts/layoutSite/LayoutAuth";
import Register from "./components/authForms/register/Register2";
import Shop from "./pages/site/shop/Shop";
import Checkout from "./pages/site/checkout/Checkout";
import LayoutCheckout from "./layouts/layoutSite/LayoutCheckout";
import LayoutCart from "./layouts/layoutSite/LayoutCart";
import PromotionPage from "./pages/site/promotion/promotion";
import LayoutAdmin from "./pages/dashboard/dashboardAdmin/LayoutAdmin";
import HomeAdmin from "./pages/dashboard/dashboardAdmin/home/Home";
import ShopAdmin from "./pages/dashboard/dashboardAdmin/shop/Shop";
import Categories from "./pages/dashboard/dashboardAdmin/category/Categories";
import Article from "./pages/dashboard/dashboardAdmin/article/Article";
import LoginAdmin from "./components/authForms/login/LoginAdmin";
import PrivateRouteAdmin from "./PrivateRouteAdmin";
import Settings from "./pages/dashboard/dashboardAdmin/settings/Settings";
import ResetForm from "./components/authForms/resetPwd/ResetForm";
import ResetPwd from "./components/authForms/resetPwd/ResetPwd";
import ResetPwdAdmin from "./components/authForms/resetPwd/ResetPwdAdmin";
import ResetFormAdmin from "./components/authForms/resetPwd/ResetFormAdmin";
import SearchPage from "./pages/site/searchPage/SearchPage";
import LayoutShop from "./layouts/layoutSite/LayoutShop";
import ListProductAdmin from "./pages/dashboard/dashboardAdmin/products/ListProducts";
import AddProductAdmin from "./pages/dashboard/dashboardAdmin/products/AddProduct";
import CobeilleAdmin from "./pages/dashboard/dashboardAdmin/products/Cobeille";
import About from "./pages/site/about/About";
import ConditionGeneral from "./pages/site/conditionGeneral/ConditionGeneral";
import PrivacyPolicy from "./pages/site/privacyPolicy/PrivacyPolicy";
import PoliticyRemb from "./pages/site/politicyRemb/PoliticyRemb";
import Contact from "./pages/site/contact/Contact";
import Faq from "./pages/site/faq/Faq";
import ArticlePage from "./pages/site/articlePage/ArticlePage";
import RevenuFonds from "./pages/dashboard/dashboardSeller/revenu/RevenuFonds";
import ActiveAccount from "./components/authForms/activeAccount/ActiveAccount";
import TransactionsAdmin from "./pages/dashboard/dashboardAdmin/transaction/Transaction";
import NewsletterPage from "./pages/dashboard/dashboardAdmin/newsletter/Newsletter";
import OrderAdmin from "./pages/dashboard/dashboardAdmin/order/Order";
import SaleAdmin from "./pages/dashboard/dashboardAdmin/sale/Sale";
import Documentation from "./pages/site/documentation/Documentation";
import DocumentationAdmin from "./pages/site/documentation/Documentation-admin";


const ROUTES = () => {
  return (
    <Router>
      <Routes>

        {/* home page */}
        <Route path={LOGIN} element={
          <LayoutAuth>
            <Suspense fallback="...">
              <Login />
            </Suspense>
          </LayoutAuth>
        } />

        {/* register page */}
        <Route path={REGISTTER} element={
          <LayoutAuth>
            <Suspense fallback="...">
              <Register />
            </Suspense>
          </LayoutAuth>
        } />

        {/* active account page */}
        <Route path={ACTIVE_ACCOUNT_PAGE} element={
          <LayoutAuth>
            <Suspense fallback="...">
              <ActiveAccount />
            </Suspense>
          </LayoutAuth>
        } />

        {/* reset pwd page */}
        <Route path={RESETPWD} element={
          <LayoutAuth>
            <Suspense fallback="...">
              <ResetForm />
            </Suspense>
          </LayoutAuth>
        } />

        <Route path={RESETFORM} element={
          <LayoutAuth>
            <Suspense fallback="...">
              <ResetPwd />
            </Suspense>
          </LayoutAuth>
        } />

        {/* home page */}
        <Route path={HOME} element={
          <LayoutHome>
            <Suspense fallback="...">
              <Home />
            </Suspense>
          </LayoutHome>
        } />

        {/* category page */}
        <Route path={PRODUCT_CATEGORY} element={
          <LayoutHome>
            <Suspense fallback="...">
              <Products />
            </Suspense>
          </LayoutHome>
        } />

        {/* produit detais page */}
        <Route path={PRODUCT_DETAILS} element={
          <LayoutHome2>
            <Suspense fallback="...">
              <ProductDetails />
            </Suspense>
          </LayoutHome2>
        } />

        {/* produit page */}
        <Route path={PRODUCT_CATEGORY_PROD} element={
          <LayoutHome>
            <Suspense fallback="...">
              <Products />
            </Suspense>
          </LayoutHome>
        } />

        {/* produit page */}
        <Route path={PROMO_PAGE} element={
          <LayoutHome>
            <Suspense fallback="...">
              <PromotionPage />
            </Suspense>
          </LayoutHome>
        } />

        {/* search produit page */}
        <Route path={SEARCH_PRODUCT_PAGE} element={
          <LayoutHome>
            <Suspense fallback="...">
              <SearchPage />
            </Suspense>
          </LayoutHome>
        } />

        {/* cart page */}
        <Route path={CART_SHOPPING} element={
          <LayoutCart>
            <Suspense fallback="...">
              <Cart />
            </Suspense>
          </LayoutCart>
        } />

        {/* checkout page */}
        <Route path={CHECKOUT} element={
          <LayoutCheckout>
            <Suspense fallback="...">
              <Checkout />
            </Suspense>
          </LayoutCheckout>
        } />

        {/* about page */}
        <Route path={ABOUT_PAGE} element={
          <LayoutHome>
            <Suspense fallback="...">
              <About />
            </Suspense>
          </LayoutHome>
        } />


        {/* condiction générale */}
        <Route path={CONDITION_GENERAL_PAGE} element={
          <LayoutHome>
            <Suspense fallback="...">
              <ConditionGeneral />
            </Suspense>
          </LayoutHome>
        } />

        {/* privacy policy */}
        <Route path={POLITIC_CONFIDENTIALITe_PAGE} element={
          <LayoutHome>
            <Suspense fallback="...">
              <PrivacyPolicy />
            </Suspense>
          </LayoutHome>
        } />

        {/* condiction générale */}
        <Route path={POLITIC_REMBOURSEMENT_PAGE} element={
          <LayoutHome>
            <Suspense fallback="...">
              <PoliticyRemb />
            </Suspense>
          </LayoutHome>
        } />

        {/* contact page */}
        <Route path={CONTACT_PAGE} element={
          <LayoutHome>
            <Suspense fallback="...">
              <Contact />
            </Suspense>
          </LayoutHome>
        } />

        {/* page faq */}
        <Route path={FAQ_PAGE} element={
          <LayoutHome>
            <Suspense fallback="...">
              <Faq />
            </Suspense>
          </LayoutHome>
        } />

        {/* page faq */}
        <Route path={ARTICLE} element={
          <LayoutHome>
            <Suspense fallback="...">
              <ArticlePage />
            </Suspense>
          </LayoutHome>
        } />

        {/* user page */}
        {/* account home page */}
        <Route path={ACCOUNT_HOME} element={
          <Account title={"Accueil"} >
            <HomeAccount />
          </Account>
        } />

        {/* account order page */}
        <Route path={ACCOUNT_ORDER} element={
          <Account title={"Mes Commandes"}>
            <OrderAccount />
          </Account>
        } />

        {/* account whishlist page */}
        <Route path={ACCOUNT_WHISHLIST} element={
          <PrivateRoute>
            <Account title={"Mes Favoris"}>
              <WhishlistAccount />
            </Account>
          </PrivateRoute>
        } />

        {/* account adress page */}
        <Route path={ACCOUNT_ADRESS} element={
          <Account title={"Mes Adresses"}>
            <Adress />
          </Account>
        } />

        {/* account profil page */}
        <Route path={ACCOUNT_DETAILS} element={
          <Account title={"Mon Profil"}>
            <ProfilAccount />
          </Account>
        } />

        {/* account download page */}
        <Route path={ACCOUNT_DOWNLOAD} element={
          <PrivateRoute>
            <Account>
              <Download />
            </Account>
          </PrivateRoute>
        } />

        {/* documentation */}
        <Route path={DOCUMENTATION} element={
          <Documentation />
        } />
        <Route path={DOCUMENTATION_ADMIN} element={
          <DocumentationAdmin />
        } />

        {/* shop routing */}
        {/* shop home */}
        <Route path={SHOP_HOME} element={
          <LayoutShop>
            <Suspense fallback="...">
              <Shop />
            </Suspense>
          </LayoutShop>
        } />

        {/* Dashboard seller */}
        <Route path={DASHBOARD_SELLER_HOME} element={
          <PrivateRoute>
            <LayoutSeller>
              <Suspense fallback="...">
                <HomeSeller />
              </Suspense>
            </LayoutSeller>
          </PrivateRoute>
        } />

        <Route path={DASHBOARD_SELLER_PROFIL} element={
          <PrivateRoute>
            <LayoutSeller>
              <Suspense fallback="...">
                <ProfilSeller />
              </Suspense>
            </LayoutSeller>
          </PrivateRoute>
        } />

        <Route path={DASHBOARD_SELLER_SALLE} element={
          <PrivateRoute>
            <LayoutSeller>
              <Suspense fallback="...">
                <Sale />
              </Suspense>
            </LayoutSeller>
          </PrivateRoute>
        } />

        {/* page commande */}
        <Route path={DASHBOARD_SELLER_ORDER} element={
          <PrivateRoute>
            <LayoutSeller>
              <Suspense fallback="...">
                <OrderSeller />
              </Suspense>
            </LayoutSeller>
          </PrivateRoute>
        } />

        <Route path={DASHBOARD_SELLER_PRODUCTS} element={
          <PrivateRoute>
            <LayoutSeller>
              <Suspense fallback="...">
                <ListProduct />
              </Suspense>
            </LayoutSeller>
          </PrivateRoute>
        } />

        <Route path={DASHBOARD_SELLER_ADD_PRODUCTS} element={
          <PrivateRoute>
            <LayoutSeller>
              <Suspense fallback="...">
                <AddProduct />
              </Suspense>
            </LayoutSeller>
          </PrivateRoute>
        } />

        <Route path={DASHBOARD_SELLER_COBEILLE_PRODUCT} element={
          <PrivateRoute>
            <LayoutSeller>
              <Suspense fallback="...">
                <Cobeille />
              </Suspense>
            </LayoutSeller>
          </PrivateRoute>
        } />

        <Route path={DASHBOARD_SELLER_COBEILLE_VENTE} element={
          <PrivateRoute>
            <LayoutSeller>
              <Suspense fallback="...">
                <Trash />
              </Suspense>
            </LayoutSeller>
          </PrivateRoute>
        } />

        <Route path={DASHBOARD_SELLER_TRANSACTIONS_REQUEST} element={
          <PrivateRoute>
            <LayoutSeller>
              <Suspense fallback="...">
                <RevenuFonds />
              </Suspense>
            </LayoutSeller>
          </PrivateRoute>
        } />

        {/* routing dashboard admin */}
        {/* reset pwd page admin*/}
        <Route path={DASHBOARD_ADMIN_RESETFORM} element={
          <LayoutAuth>
            <Suspense fallback="...">
              <ResetFormAdmin />
            </Suspense>
          </LayoutAuth>
        } />

        <Route path={DASHBOARD_ADMIN_RESETPWD} element={
          <LayoutAuth>
            <Suspense fallback="...">
              <ResetPwdAdmin />
            </Suspense>
          </LayoutAuth>
        } />

        <Route path={DASHBOARD_ADMIN_LOGIN} element={
          <Suspense fallback="...">
            <LoginAdmin />
          </Suspense>
        } />

        <Route path={DASHBOARD_ADMIN_HOME} element={
          <PrivateRouteAdmin>
            <LayoutAdmin>
              <Suspense fallback="...">
                <HomeAdmin />
              </Suspense>
            </LayoutAdmin>
          </PrivateRouteAdmin>
        } />

        <Route path={DASHBOARD_ADMIN_SHOP} element={
          <PrivateRouteAdmin>
            <LayoutAdmin>
              <Suspense fallback="...">
                <ShopAdmin />
              </Suspense>
            </LayoutAdmin>
          </PrivateRouteAdmin>
        } />

        <Route path={DASHBOARD_ADMIN_CATEGORY} element={
          <PrivateRouteAdmin>
            <LayoutAdmin>
              <Suspense fallback="...">
                <Categories />
              </Suspense>
            </LayoutAdmin>
          </PrivateRouteAdmin>
        } />

        <Route path={DASHBOARD_ADMIN_ARTICLE} element={
          <PrivateRouteAdmin>
            <LayoutAdmin>
              <Suspense fallback="...">
                <Article />
              </Suspense>
            </LayoutAdmin>
          </PrivateRouteAdmin>
        } />

        <Route path={DASHBOARD_ADMIN_SETTINGS} element={
          <PrivateRouteAdmin>
            <LayoutAdmin>
              <Suspense fallback="...">
                <Settings />
              </Suspense>
            </LayoutAdmin>
          </PrivateRouteAdmin>
        } />

        <Route path={DASHBOARD_ADMIN_PRODUCTS} element={
          <PrivateRouteAdmin>
            <LayoutAdmin>
              <Suspense fallback="...">
                <ListProductAdmin />
              </Suspense>
            </LayoutAdmin>
          </PrivateRouteAdmin>
        } />

        <Route path={DASHBOARD_ADMIN_ADD_PRODUCTS} element={
          <PrivateRouteAdmin>
            <LayoutAdmin>
              <Suspense fallback="...">
                <AddProductAdmin />
              </Suspense>
            </LayoutAdmin>
          </PrivateRouteAdmin>
        } />

        <Route path={DASHBOARD_ADMIN_COBEILLE_PRODUCT} element={
          <PrivateRouteAdmin>
            <LayoutAdmin>
              <Suspense fallback="...">
                <CobeilleAdmin />
              </Suspense>
            </LayoutAdmin>
          </PrivateRouteAdmin>
        } />

        <Route path={DASHBOARD_ADMIN_ORDER} element={
          <PrivateRouteAdmin>
            <LayoutAdmin>
              <Suspense fallback="...">
                <OrderAdmin />
              </Suspense>
            </LayoutAdmin>
          </PrivateRouteAdmin>
        } />

        <Route path={DASHBOARD_ADMIN_SALE} element={
          <PrivateRouteAdmin>
            <LayoutAdmin>
              <Suspense fallback="...">
                <SaleAdmin />
              </Suspense>
            </LayoutAdmin>
          </PrivateRouteAdmin>
        } />

        <Route path={DASHBOARD_ADMIN_TRANSACTION} element={
          <PrivateRouteAdmin>
            <LayoutAdmin>
              <Suspense fallback="...">
                <TransactionsAdmin />
              </Suspense>
            </LayoutAdmin>
          </PrivateRouteAdmin>
        } />

        <Route path={DASHBOARD_ADMIN_NEWSLETTER} element={
          <PrivateRouteAdmin>
            <LayoutAdmin>
              <Suspense fallback="...">
                <NewsletterPage />
              </Suspense>
            </LayoutAdmin>
          </PrivateRouteAdmin>
        } />

      </Routes>
    </Router>
  );
};

export default ROUTES;
