import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";

import "./index.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-multi-carousel/lib/styles.css";

import GeneralContext from "../../../context/GlobalContext";

import slide1 from "../../../assets/images/png/home-img.jpg";
import img1 from "../../../assets/images/png/banner-bag.jpg";
import img2 from "../../../assets/images/png/ship-free.jpg";

import { FiArrowRight } from "react-icons/fi";
import { API_URL, PRODUCT_CATEGORY, PROMO_PAGE} from "../../../settings/constant";

const Home = (props) => {
  const { allProducts, userData, articles, getArticle } = useContext(GeneralContext);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2
    }
  };

  const responsive2 = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  useEffect(() => {
    // get article
    getArticle();
  }, [userData]);


  return (
    <div className="home-page">
      <div className="section-one">
      <Carousel responsive={responsive2} autoPlay={true} infinite={true} showDots className="slider-img">
          <div className="slid d1">
            <div className="slid-child">
              <div>
                <h1>
                  DES collections uniques et authentiques
                </h1>
                <p>
                  Donnez un touche d'authenticité à votre style
                </p>
              </div>
            </div>
            <img src={slide1} />
          </div>
          <div className="slid d2">
            <div className="slid-child">
              <div>
                <h1>
                  ELEGANCE ET EXCEPTION
                </h1>
                <p>
                  Découvrez notre catalogue
                </p>
              </div>
            </div>
          </div>
          <div className="slid d3">
            <div className="slid-child">
              <div>
                <h1>
                  ALLURES ET GLAMOUR
                </h1>
                <p>
                  Retrouvez des articles uniques et exceptionnels
                </p>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
      <section className="section-two">
        <div className="s2-block1">
          <div className="bloc1">
            <img src={img1} />
            <div className="text-div">
              <h5> Articles pour Femme</h5>
              <h2>Black Friday</h2>
              <a href={PROMO_PAGE}> Voir les articles en promotion <FiArrowRight /> </a>
            </div>
          </div>
          <div className="bloc2">
            <img src={img2} />
            <div className="text-div">
              <h5> Achats Rapides</h5>
              <h2>Livraison gratuite</h2>
              <a href={PRODUCT_CATEGORY}> Acheter maintenant <FiArrowRight /> </a>
            </div>
          </div>
        </div>
        <div className="s2-block2">
          <div className="div-left">
            <h6> Articles pour Femme</h6>
            <h4> Vente en promotion </h4>
            <p>
              Jusqu’à 70% de réduction et livraison gratuite.
            </p>
            <a href={PRODUCT_CATEGORY} className="btn-sale"> Parcourir les ventes </a>
          </div>
          <div className="div-slides">
            <Carousel autoPlay={true} responsive={responsive} className="slides-show">
              {
                allProducts.length > 0 &&
                allProducts.map((list, index) => {
                  if (!list.is_delete){
                    return (
                      <div className="div-prod" key={index}>
                        <Link to={{ pathname: `/produit/details/${list.name_prod.replaceAll(" ", "-")}=${list.id_prod}` }} state={list}>
                          <div className="imgs">
                            <img src={`${API_URL}${list.image_prod}`} />
                            <div className="info-prod">
                              <h5> {list.name_prod} </h5>
                              <b className="price"> {Math.round(list.price_prod)} FCFA</b>
                              <button> Acheter </button>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  }
                })}
            </Carousel>
          </div>
        </div>
      </section>
      <section className="section-three">
        <h1 className="title-section"> Notre collection </h1>
        <p>
          Nous vous proposons une gamme d’articles
          soigneusement sélectionnés pour vous rendre
          authentique.
        </p>
        <div className="s3-block-product">
          {allProducts && allProducts.map((list, index) => {
            if(list.is_collection) {
              return (
                <div className="s3-card-prod" key={index}>
                  <div className="prod-img">
                    <Link to={{ pathname: `/produit/details/${list.name_prod.replaceAll(" ", "-")}=${list.id_prod}` }} state={list}>
                      <img src={`${API_URL}${list.image_prod}`} />
                    </Link>
                  </div>
                  <h3>SEASONS</h3>
                  <h4> {list.name_prod} </h4>
                </div>
              );
            }
          })}
        </div>
      </section>
      <section className="section-three section-four">
        <h1 className="title-section"> Tenues tendances </h1>
        <p>
          Nous évoluons au fil du temps et nous restons
          tendance avec les nouvelles créations.
        </p>
        <div className="s3-block-product">
          {allProducts && allProducts.map((list, index) => {
            if (list.is_tendance) {
                return (
                  <div className="s3-card-prod" key={index}>
                    <div className="prod-img">
                      <Link to={{ pathname: `/produit/details/${list.name_prod.replaceAll(" ", "-")}=${list.id_prod}` }} state={list}>
                        <img src={`${API_URL}${list.image_prod}`} />
                        <div></div>
                      </Link>
                    </div>
                    <h3> {list.name_prod} </h3>
                    <h4> {Math.round(list.price_prod)} Fcfa </h4>
                  </div>
                );
            }
          })}
        </div>
      </section>
      <section className="section-five">
        <h1 className="title-section"> Dernières actualités </h1>
        <p>
          Restez informez et ne ratez rien de nos actualités, conseils et promotions.
        </p>
        {articles && (
          <Carousel responsive={responsive} autoPlay={true} infinite={true} className="slider-actuality">
          {articles && articles.map((list, index) => {
            return <div className="card-actuality" key={index}>
              <div className="cards-act">
                <Link to={{ pathname: `/article/${list.slug}catid=${list.id_at}` }} state={list}>
                  <img src={`${API_URL}${list.image1}`} />
                </Link>
                <h4> {list.title} ?</h4>
                <p>by <b>Admin_autasys</b>on {list.createdAt}</p>
              </div>
            </div>;
          })}
        </Carousel>)}
      </section>
    </div>
  );
};

export default Home;
