import React, { useContext } from "react";

import "./home.scss";
import GeneralContext from "../../../../context/GlobalContext";

const HomeAccount = () => {

  const { userData } = useContext(GeneralContext);

  return (
    <div className="home-account">
      <p>
        Bienvenue {userData ? userData.firstname + " " + userData.lastname : null}
      </p>
      <p>
        À partir du tableau de bord de votre compte, 
        vous pouvez visualiser vos commandes récentes, 
        gérer vos adresses de livraison et de facturation 
        ainsi que changer votre mot de passe et les détails de votre compte.
      </p>
    </div>
  );
};

export default HomeAccount;