import React, { useEffect, useContext } from "react";

import "./Home.scss";
import "flag-icon-css/css/flag-icons.css";

import { HeadTabs } from "../../../../components/headTabs/HeadTabs";
import GeneralContext from "../../../../context/GlobalContext";
import {  HOME } from "../../../../settings/constant";

const HomeAdmin = () => {

  const { store, statsSite, getStatsSite } = useContext(GeneralContext);

  
  useEffect(() => {
    // get stats site
    getStatsSite();
  }, [store]);

  return (
    <div className="home-dashboard-buyer">
      <HeadTabs title={"Tableau de bord"} />
      <div className="block-dashboard">
        <div className="block-cardss">
          <div className="stats-card">
            <p>
              <input type="text" value={statsSite ? (statsSite.store ? statsSite.store : 0) : 0} />
              <span>Boutiques</span>
            </p>
            <div className="barss"></div>
          </div>
          <div className="stats-card">
            <p>
              <input type="text" value={statsSite ? (statsSite.products ? statsSite.products : 0) : 0} />
              <span>Produits</span>
            </p>
            <div className="barss"></div>
          </div>
          <div className="stats-card">
            <p>
              <input type="text" value={statsSite ? (statsSite.sale ? statsSite.sale : 0) : 0} />
              <span>Ventes</span>
            </p>
            <div className="barss"></div>
          </div>
          <div className="stats-card">
            <p>
              <input type="text" value={statsSite ? (statsSite.user ? statsSite.user : 0) : 0} />
              <span> Utilisateurs </span>
            </p>
            <div className="barss"></div>
          </div>
        </div>
        <div className="card-graph">
          <div className="div1s">
            <a href={HOME} target="blank">Aller sur le site </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeAdmin;
