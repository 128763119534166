import React from "react";
import { FiChevronDown, FiChevronsDown } from "react-icons/fi";

import "./Documentation.scss";

import logo from "../../../assets/images/png/logo.png";
import profil from "./../../../assets/images/profil.png";
import add_product from "./../../../assets/images/add-product.png";
import list_product from "./../../../assets/images/product-list.png";
import order from "./../../../assets/images/order.png";
import order_update from "./../../../assets/images/order-update.png";
import pay from "./../../../assets/images/pay.png";
import home from "./../../../assets/images/home.png";
import form_seller from "./../../../assets/images/form-seller.png";
import login from "./../../../assets/images/connexion.png";
import signup from "./../../../assets/images/signup.png";


const Documentation = () => {
    return (
        <div className="DocumentationPage">
            <div className="DocumentationPage-sidebar">
                <img className="DocumentationPage-logo" src={logo} />
                <ul className="DocumentationPage-menu">
                    <h2 className="DocumentationPage-rubrique">Vendeur <FiChevronDown /></h2>
                    <li>
                        <a href="#creation-compte">Créer un compte</a>
                    </li>
                    <li>
                        <a href="#creation-boutique-vendeur">Créer un boutique</a>
                    </li>
                    <li>
                        <a href="#completed-boutique-vendeur">Complèté Profil</a>
                    </li>
                    <li>
                        <a href="#ajout-de-produit">Ajouté un produit</a>
                    </li>
                    <li>
                        <a href="#enregistre-une-vente">Enrégistré une vente</a>
                    </li>
                    <li>
                        <a href="#demander-une-reversement">Paiement</a>
                    </li>
                </ul>
            </div>
            <div className="DocumentationPage-body">
                <h1 className="DocumentationPage-h1">Documentation vendeur de <b>Autasys.com</b></h1>
                <ul className="DocumentationPage-menu2">
                    <li>
                        <a href="#creation-compte">Créer un compte</a>
                    </li>
                    <li>
                        <a href="#creation-boutique-vendeur">Créer une boutique</a>
                    </li>
                    <li>
                        <a href="#completed-boutique-vendeur">Compléter le profil</a>
                    </li>
                    <li>
                        <a href="#ajout-de-produit">Ajouter un produit</a>
                    </li>
                    <li>
                        <a href="#enregistre-une-vente">Enregistrer une vente</a>
                    </li>
                    <li>
                        <a href="#demander-une-reversement">Demander un reversement</a>
                    </li>
                </ul>
                <div className="DocumentationPage-mainContaint">
                    <div className="BlockContaint" id="creation-compte">
                        <h3 className="BlockContaint-title">1-  Créaction de compte sur Autasys.com</h3>
                        <p className="BlockContaint-description">
                        Pour créer un compte sur Autasys.com, suivez ces étapes :<br/>
                        1. Accédez au site en vous rendant sur la page d'accueil.<br/>
                        2. Cliquez sur le bouton **Connexion** situé dans l'en-tête du site. Le formulaire de connexion s'affichera.<br/>
                        3. Si vous n'avez pas encore de compte, cliquez sur le bouton **Inscrivez-vous** situé en bas du formulaire de connexion.<br/>
                        4. Remplissez le formulaire d'inscription avec les informations requises, puis cliquez sur **Valider**.<br/>
                        5. Après avoir validé le formulaire, vous serez redirigé vers la page d'activation de compte.<br/>
                        6. Entrez le code que vous avez reçu par e-mail dans le champ prévu à cet effet, puis cliquez sur **Valider**.<br/>
                        7. Si tout se déroule correctement, vous serez dirigé vers la page d'accueil du site.<br/>
                        Félicitations, vous disposez désormais d'un compte sur Autasys.com.

                        </p>
                        <div className="BlockContaint-imgs">
                            <img src={home} alt="" />
                            <img src={login} alt="" />
                        </div>
                        <img src={signup} alt="" />
                    </div>
                    <div className="BlockContaint" id="creation-boutique-vendeur">
                        <h3 className="BlockContaint-title">2-  Création d'une Boutique sur Autasys.com</h3>
                        <p className="BlockContaint-description">
                            Pour créer une boutique sur Autasys.com, suivez ces étapes :<br/>
                            1. Assurez-vous d'avoir un compte utilisateur et connectez-vous préalablement.<br/>
                            2. Cliquez sur le bouton **Créer ma boutique** situé dans l'en-tête du site, à gauche.<br/>
                            3. Remplissez le formulaire et validez.<br/>
                            4. Vous serez alors redirigé vers votre tableau de bord vendeur.<br/>
                            Félicitations ! Votre boutique a été créée avec succès sur Autasys.com.<br/>
                        </p>
                        <div className="BlockContaint-imgs">
                            <img src={home} alt="" />
                            <img src={form_seller} alt="" />
                        </div>
                    </div>
                    <div className="BlockContaint" id="completed-boutique-vendeur">
                        <h3 className="BlockContaint-title">3-  Compléter Votre Profil Vendeur</h3>
                        <p className="BlockContaint-description">
                            Pour compléter votre profil vendeur, suivez ces étapes :<br/>
                            1. Connectez-vous pour accéder à votre tableau de bord vendeur.<br/>
                            2. Cliquez sur la rubrique **Profil**.<br/>
                            3. Une fois sur la page de profil, mettez à jour vos informations.
                        </p>
                        <img src={profil} alt="" />
                    </div>
                    <div className="BlockContaint" id="ajout-de-produit">
                        <h3 className="BlockContaint-title">4-  Ajout d'un Produit dans Votre Boutique</h3>
                        <p className="BlockContaint-description">
                            Pour ajouter un produit à votre boutique, suivez ces étapes :<br/>
                            1. Rendez-vous sur votre tableau de bord vendeur après vous être connecté.<br/>
                            2. Cliquez sur la rubrique **Produit**, puis sur la sous-rubrique **Ajouter un produit**.<br/>
                            3. Remplissez le formulaire, puis validez.<br/>
                            Ainsi, votre produit sera correctement créé. Dans la rubrique **Produit**, vous pouvez également consulter la liste des produits, 
                            modifier un produit, supprimer un produit et définir une réduction.<br/>
                        </p>
                        <div className="BlockContaint-imgs">
                            <img src={add_product} alt="" />
                            <img src={list_product} alt="" />
                        </div>
                    </div>
                    <div className="BlockContaint" id="enregistre-une-vente">
                        <h3 className="BlockContaint-title">5-  Enregistrement d'une Vente</h3>
                        <p className="BlockContaint-description">
                            Pour enregistrer une vente, suivez ces étapes :<br/>
                            1. Rendez-vous sur votre tableau de bord vendeur après vous être connecté.<br/>
                            2. Cliquez sur la rubrique **Commande** pour afficher la liste des commandes reçues.<br/>
                            3. Pour enregistrer une vente, modifiez le statut de livraison en "Livré". Pour ce faire, cliquez sur le bouton "Modifier" ou sur l'icône de crayon dans la colonne "Action" du tableau.<br/>
                            4. Un pop-up s'affichera pour la modification du statut de livraison. Confirmez la livraison.<br/>
                            Ainsi, vous avez enregistré une vente. Pour consulter vos ventes, rendez-vous dans la rubrique "Vente".<br/>
                        </p>
                        <div className="BlockContaint-imgs">
                            <img src={order} alt="" />
                            <img src={order_update} alt="" />
                        </div>
                    </div>
                    <div className="BlockContaint" id="demander-une-reversement">
                        <h3 className="BlockContaint-title">6-  Demande de Reversement</h3>
                        <p className="BlockContaint-description">
                            Pour demander un reversement, suivez ces étapes :<br/>
                            1. Rendez-vous sur votre tableau de bord vendeur après vous être connecté.<br/>
                            2. Cliquez sur la rubrique **Paiement** pour afficher la page de demande.<br/>
                            3. Vous verrez le prix total de vos ventes affiché.<br/>
                            4. Cliquez ensuite sur le bouton **Demander un reversement**, puis saisissez la somme que 
                            vous souhaitez retirer et validez.<br/>
                            Ainsi, votre demande a été envoyée avec succès.
                        </p>
                        <img src={pay} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Documentation;