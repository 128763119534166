import React, { useContext, useEffect, useState } from "react";
import Select from "../../../../components/select/Select";
import GeneralContext from "../../../../context/GlobalContext";

import "./order.scss";
import { API_URL } from "../../../../settings/constant";
import { PopupLayout } from "../../../../components/popupLayout/PopupLayout";
import { FiCheck, FiCheckCircle } from "react-icons/fi";

const OrderAccount = () => {

  const { orders, updateOrderStatus } = useContext(GeneralContext);

  const [filterWord, setFilterWord] = useState("");
  const [popup, isPopup] = useState(false);
  const [undo, setUndo] = useState(false);
  const [done, isDone] = useState(false);
  const [orderId, setOrderId] = useState(false);

  const options = [
    { label: "En attente", value: "" },
    { label: "Delivré", value: "" }
  ];


  return (
    <div className="order-dashboard-buyer">
      <PopupLayout closes={popup}>
        <div className="popup-order">
          <span className="close" onClick={() => isPopup(false)}>&times;</span>
          <div className="content">
            {!done ? (
              <>
                <p>
                  Marquer une commande comme livré revient à signalé que vous avez
                  bien reçu votre commande du fournisseur. Une fois mentionner, c'est considéré que le
                  fournisseur vous a livré le produit. Et cette action irréversible.
                  La commande disparaitra automatiquement de votre de commande.
                </p>
                {undo ? (<button className="btn-livre" onClick={() => updateOrderStatus(orderId, isDone)}>Marqué comme livrer</button>
                ) : (
                  <button className="btn-und" onClick={() => setUndo(true)}> J'ai compris !</button>
                )}
              </>
            ) : (
              <>
                <FiCheckCircle />
                <p>
                  Produit marquer comme livré avec succès.
                </p>
                <button className="close-btn" onClick={() => {
                  isPopup(false);
                  window.location.reload();
                }}>ok</button>
              </>
            )}
          </div>
        </div>
      </PopupLayout>
      <div className="orders">
        <div className='sort-div'>
          <Select defaultText="Trier par" listOption={options} setFilterWord={setFilterWord} />
        </div>
        <div className="table">
          <table className="table-data">
            <thead>
              <tr>
                <th> Vendeur </th>
                <th> Image</th>
                <th> Products </th>
                <th> Quantité </th>
                <th> Prix Total </th>
                <th> Status paiement </th>
                <th> Status livraison </th>
                <th> Action </th>
              </tr>
            </thead>
            <tbody>
              {
                orders && (
                  orders.map((list, index) => {
                    if (filterWord) {
                      if ((list.is_delivered ? "Delivré" : "En attente") === filterWord) {
                        return <tr key={index}>
                          <td> {list.Product.Store ? list.Product.Store.companyName:"Autasys"} </td>
                          <td> <img src={`${API_URL}${list.Product.image_prod}`} /> </td>
                          <td> {list.Product.name_prod} </td>
                          <td> {list.qty} </td>
                          <td> {list.amount} FCFA </td>
                          <td>  {list.is_paid ? "Payé" : "Non payé"}  </td>
                          <td> {list.is_delivered ? "Delivré" : "En attente"} </td>
                        </tr>;
                      }
                    } else {
                      return <tr key={index}>
                        <td> {list.Product.Store ? list.Product.Store.companyName:"Autasys"} </td>
                        <td> <img src={`${API_URL}${list.Product.image_prod}`} /> </td>
                        <td> {list.Product.name_prod} </td>
                        <td> {list.qty} </td>
                        <td> {list.amount} </td>
                        <td> {list.is_paid ? "Payé" : "Non payé"}  </td>
                        <td> {list.is_delivered ? "livré" : "En attente"} </td>
                        <td>
                          <button className="btn-mak"
                            onClick={() => {
                              setOrderId(list.id_oi);
                              isPopup(true);
                            }}>
                            Marquer comme livré
                          </button>
                        </td>
                      </tr>;
                    }
                  })
                )
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OrderAccount;
