import React, { useContext, useEffect, useState } from "react";
import { validInputText } from "../../controlFields/ControlField";

import "../auth.scss";
import AuthContext from "../../../context/AuthContext";
import { BoxAlertSucess } from "../../boxAlertSucess/BoxAlertSucess";
import { HOME, LOGIN } from "../../../settings/constant";

const ActiveAccount = () => {

    const { activeAccount } = useContext(AuthContext);

    const [code, setCode] = useState(null);
    const [done, isDone] = useState(false);
    const [popup, isPopup] = useState(false);
    const [msg, setMsg] = useState(null);
    const [succes, isSuccess] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        let err = document.getElementById("msg-form");

        validInputText(code, "code-error", "codeOtp");

        if (validInputText(code, "code-error", "codeOtp")) {
            activeAccount(code, isPopup, isSuccess, err);
        }
    };

    return (
        <div className="login-block">
            <BoxAlertSucess popup={popup}
            isDone={isDone} done={done}
            link={HOME} handleBtn={isPopup}
            text={msg} />
            {!succes ? (
                <form className="form-login-register form-resett">
                <h3 className="title-reset-pwd"
                    style={{ marginBottom: "1.5rem" }}> Activation de compte </h3>
                <span className="notice-pwd" id="notice-pwd">
                    Entrer le code à 04 chiffres qui vous a été envoyer par mail
                </span>
                <p id="msg-form"></p>
                <div className="div-field">
                    <input type="text" placeholder="Code OTP..."
                        name="code"
                        id="code"
                        value={code}
                        onChange={e => setCode(e.target.value)} />
                    <span className="span" id="code-error"></span>
                </div>
                <div className="btn-submit">
                    <button onClick={handleSubmit} type="submit">Activer mon compte</button>
                </div>
            </form>
            ):(
                <div className="msg-succes-active-account">
                    <p> Compte activé avec succès. Avec autasys, vendre et acheter en ligne devient plus facile, plus rapide et moins couteux.</p>
                    <button onClick={() => window.location.href=LOGIN}>Connectez-vous</button>
                </div>
            )}
        </div>
    );
};

export default ActiveAccount;
