import React, { useState, useEffect, useContext } from "react";

import { CART_SHOPPING, CHECKOUT } from "../../settings/constant";
import Auth from "../authForms/Auth";

import "./SummaryOrder.scss";
import GeneralContext from "../../context/GlobalContext";

export const SummaryOrder = ({ setPrice, handleValid }) => {
  const [page, setPage] = useState();

  const { shoppingCartItem } = useContext(GeneralContext);

  const getTotal = () => {
    let qtys = 0;
    let prices = 0;

    shoppingCartItem.forEach((item) => {
      qtys += item.quantity;
      prices += item.quantity * item.amount;
    });
    setPrice(prices);
    return { qtys, prices };
  };

  useEffect(() => {
    const path = window.location.pathname.split("/")[1];

    if (path == CART_SHOPPING.split("/")[1]) {
      setPage(1);
    } else if (path == CHECKOUT.split("/")[1]) {
      setPage(2);
    } else {
      setPage(0);
    }
  });

  const renderBtn = () => {
    if (page == 1) {
      return <button onClick={() => {
        window.location.href = CHECKOUT;
      }}> Commander </button>;
    } else if (page == 2) {
      return <button onClick={handleValid}>Valider l'achat </button>;
    }
  };

  return (
    <div className="order-cart">
      <h2> &nbsp;&nbsp;Sommaire </h2>
      <div className="infos-order">
        <p>
          <sapn>Prix Total:</sapn> <input type="text" value={getTotal().prices + " FCFA"} />
        </p>
        <p> <span>Grand Total:</span> <input type="text" value={getTotal().prices + " FCFA"} /></p>
        <p>
          {renderBtn()}
        </p>
      </div>
    </div>
  );
};
