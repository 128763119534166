import React, { useContext, useState } from "react";

import "../Header.scss";
import "flag-icon-css/css/flag-icons.css";

import logo from "../../../assets/images/png/logo.png";
import { FiChevronDown, FiMenu, FiSearch, FiShoppingCart } from "react-icons/fi";

import FormSeller from "../../formSeller/FormSeller";
import { LOGIN } from "../../../settings/constant";

const HeaderCartPay = ({ title }) => {

  return (
    <div className='header-home header2' id="hd">
      <a href='/' className='logo-link'>
        <img src={logo} />
      </a>
      <h2> {title} </h2>
    </div>
  );
};

export default HeaderCartPay;
