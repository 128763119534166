import React from "react";
import "./globals/globals.scss";
import "./globals/variables.scss";
import ROUTES from "./router";
import {AuthProvider} from "./context/AuthContext";
import {GlobalContext} from "./context/GlobalContext";
import { PrimeReactProvider } from "primereact/api";
        

function App() {
  return (
    <div className="parent-body">
      <PrimeReactProvider>
        <AuthProvider>
          <GlobalContext>
            <ROUTES />
          </GlobalContext>
        </AuthProvider>
      </PrimeReactProvider>
    </div>
  );
}

export default App;
