import React from "react";
import FooterHome from "../../components/footer/footers/FooterHome";
import HeaderHome from "../../components/header/headers/HeaderHome";

import "./LayoutHome.scss";

const LayoutHome2 = ({children}) => {
  return (
    <div className="layout-home2">
      <HeaderHome />
      <div className="body-page">
        {children}
      </div>
      <FooterHome />
    </div>
  );
};

export default LayoutHome2;
