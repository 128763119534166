import React, { useContext, useEffect, useState } from "react";

import "./HeadTabs.scss";

import GeneralContext from "../../context/GlobalContext";


export const HeadTabs = ({ title }) => {
  const { userData, store } = useContext(GeneralContext);

  const [popup, isPopup] = useState(false);
  const [storeActive, setStoreActive] = useState("");

  const setNotifys = () => {
    if (userData) {
      if (userData.firstname === "" || userData.lastname === "" || userData.city === ""
        || userData.country === "") {
        let text = `Votre profil utilisateur n'est pas complet. 
          Complèter votre profile dans l'onglet Profile.`;
        return text;
      }
    }
  };

  /* get store active */
  const getStoreActive = () => {
    if (store) {
      let find = store.find(
        (item) => item.is_active == true,
      );
      if (find) {
        setStoreActive(find);
      }
    }
  };


  useEffect(() => {
    setNotifys();
    getStoreActive();
  }, [store]);

  return (
    <h2 className="title-tabs">
      <p>
        <b> {title} </b>
      </p>
    </h2>
  );
};
