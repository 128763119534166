import React from "react";

import "./Footer.scss";

const Footer2 = () => {

  const setYear = () => {
    const date = new Date();
    const year = date.getFullYear();

    const el = document.getElementById("year");
    return year;
  };

  return (
    <>
      <footer className="footer2">
        <p>&copy; {setYear()} Tout droit réservé</p>
      </footer>
    </>
  );
};

export default Footer2;
