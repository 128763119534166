import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Product.scss";

import CardProducts from "../../../components/cardProducts/CardProducts";
import GeneralContext from "../../../context/GlobalContext";
import CardProductList from "../../../components/cardProductList/cardProductList";
import FilterBox from "../../../components/filterBox/FIlterBox";
import { HeadFilter } from "../../../components/headFilter/HeadFIlter";
import img_back from "../../../assets/images/imgs.png";


const Products = (props) => {
  const location = useLocation();
  const data = location.state;

  const { allProducts, getProductBySubCategorie,
    getProductByCategorie, getCategoryProduct  } = useContext(GeneralContext);
  const [categorys, setCategory] = useState(null);
  const [subcategorie, setSubCategorie] = useState(null);

  const [filterBox, isFilterBox] = useState(false);
  const [view, setView] = useState(0);


  useEffect(() => {

    let search = decodeURIComponent(window.location.search.substring(1));
    let splitSearch = search.split("$");

    if (splitSearch.length === 2) {
      setCategory(splitSearch[0].split("=")[1].split("catId")[0].replaceAll("-", " "));
      let idCat = splitSearch[0].split("catId")[1];
      sessionStorage.setItem("cat", idCat);

      // get product by categorie
      getProductByCategorie(idCat);
    } else if (splitSearch.length === 3) {
      let cat = splitSearch[0].split("=")[1].split("catId")[0];
      let subcat = splitSearch[1].split("=")[1].split("subid")[0];
      let idSubCat = splitSearch[1].split("=")[1].split("subid")[1];
      setCategory(cat + " / " + subcat);
      sessionStorage.setItem("cat", cat);

       // get product by sub categorie
      getProductBySubCategorie(idSubCat);
    } else {
      setCategory(search.split("=")[1]);
      setSubCategorie(null);
    }

    // get categorie
    getCategoryProduct();

  }, [data, categorys, subcategorie]);


  return (
    <div className="product-page">
      <FilterBox filterBox={filterBox} isFilterBox={isFilterBox}
         setCategory={setCategory}
        setSubCategorie={setSubCategorie} />
      <section className="section-one-product">
        <div className="div-transparent">
          <h1> <input type="text" value={categorys ? categorys : null} /> { } </h1>
        </div>
      </section>
      <section className="section-two-shop">
        <HeadFilter label={categorys ? categorys : null} isFilterBox={isFilterBox} setView={setView} />
        {view === 0 ? (
          <div className="content-product">
            <CardProducts listProduct={allProducts.length > 0 ? allProducts : []} />
          </div>
        ) :
          (
            <div className="content-product2">
              <CardProductList listProduct={allProducts.length > 0 ? allProducts : []} />
            </div>
          )}
      </section>
    </div>
  );
};

export default Products;
