import React, { useContext, useEffect, useState } from "react";

import "./Settings.scss";

import { HeadTabs } from "../../../../components/headTabs/HeadTabs";
import { PopupLayout } from "../../../../components/popupLayout/PopupLayout";
import AddUserAdmin from "../../../../components/authForms/register/AddUserAdmin";
import { DASHBOARD_ADMIN_HOME, DASHBOARD_ADMIN_RESETPWD } from "../../../../settings/constant";
import AuthContext from "../../../../context/AuthContext";

const Settings = () => {
  const { userAdmin } = useContext(AuthContext);

  const [popup, isPopup] = useState(false);
  const [popup2, isPopup2] = useState(false);

  useEffect(() => {
    if (userAdmin) {
      if (userAdmin.role != "admin") {
        isPopup2(true);
      }
    }
  }, []);

  return (
    <div className="settings-page">
      <HeadTabs title={"Paramètres"} />
      <PopupLayout closes={popup}>
        <AddUserAdmin />
      </PopupLayout>
      <PopupLayout closes={popup2}>
        <div className="alert">
          <p>
            Vous n'êtes pas autorisé à accéder a cette page.
            Seul l'administrateur principale peut y accéder.
          </p>
          <button onClick={() => window.location.href=DASHBOARD_ADMIN_HOME} >ok</button>
        </div>
      </PopupLayout>
      <div className="settings parent">
        <div className="btn-group">
          <button onClick={() => isPopup(true)}> Invité une personne </button>
          <a href={DASHBOARD_ADMIN_RESETPWD} target="blank"> Changer mon mot de passe </a>
        </div>
      </div>
    </div>
  );
};

export default Settings;
