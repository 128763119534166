import React, { useContext, useEffect, useState } from "react";

import "./currencyInput.scss";
import "flag-icon-css/css/flag-icons.css";
import { FiChevronDown, FiSearch } from "react-icons/fi";
import { currency } from "./currency";

const CurrencyInput = ({setCurrency, curren}) => {
  const [search, setSearch] = useState("");
  const [opens, isOpen] = useState(false);

    const [currencys, setCu] = useState("FCFA");

  const open = {display: "block"};
  const close = {display: "none"};

    useEffect(() => {
    },[currencys, search, curren]);

  return (
    <div className="select-country">
      <div className="selected">
        <div className="selected-item-options">
          <span>{currencys}</span>
        </div>
        <FiChevronDown className="arrow" onClick={() => isOpen(!opens)} />
      </div>
      <div className="options-select" style={opens ? open:close}>
        <div className='search-country-div'>
          <FiSearch />
          <input type="text" name="country"
            placeholder='Rechercher...'
            value={search}
            onChange={e => setSearch(e.target.value)} />
        </div>
        {currency.map((list, index) => {
          if (!search) {
            return <li className="option"
              onClick={() => {
                setCurrency(list.symbol);
                setCu(list.symbol);
                isOpen(!opens);
              }}
              key={index}>
              <span>  {list.name} ({list.symbol}) </span>
            </li>;
          } else {
            let a = list.name.toLowerCase();
            let b = search.toLowerCase();
            if (a.includes(b)) {
              return <li className="option"
                    onClick={() => {
                        setCurrency(list.symbol);
                        setCu(list.symbol);
                        isOpen(!opens);
                    }}
                key={index}>
              <span>  {list.name} ({list.symbol}) </span>
              </li>;
            }
          }
        })}
      </div>
    </div>
  );
};

export default CurrencyInput;