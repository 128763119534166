import React, { useContext, useEffect, useState } from "react";

import "./cardProductList.scss";

import GeneralContext from "../../context/GlobalContext";
import { FiHeart } from "react-icons/fi";
import { API_URL } from "../../settings/constant";
import { Link } from "react-router-dom";

const CardProductList = ({ listProduct, nextPage, prevPage, category }) => {

  const [popup, isPopup] = useState(false);
  const [data, isData] = useState();
  const { add_favoris, whishlist } = useContext(GeneralContext);

  const activebtn = () => {
    let all = document.querySelectorAll(".card-product-list .svg");
    if (whishlist.length > 0) {
      for (let i = 0; i < whishlist.length; i++) {
        for (let j = 0; j < listProduct.length; j++) {
          if (whishlist[i].Product.id_prod === listProduct[j].id_prod) {
            all[j].classList.add("active-whishlist-btn");
          }
        }
      }
    }
  };


  useEffect(() => {
    activebtn();
  }, [listProduct]);


  return (
    <>
      {
        listProduct && (
          listProduct.map((list, index) => {
            //if (prevPage <= index && (nextPage - 1) >= index) {
            return <Link to={{ pathname: `/produit/details/${list.name_prod.replaceAll(" ", "-")}=${list.id_prod}` }} state={list} key={index}>

            <div className="card-product-list" >
              <div className="div-img">
                <button className="btn-heart" onClick={() => add_favoris(list.id_prod, ".card-product-list .svg", index, activebtn)}>
                  <FiHeart className='svg' />
                </button>
                  <img src={`${API_URL}${list.image_prod}`} />
              </div>
              <div className="info-prod-list">
                <h4> {list.name_prod} </h4>
                <h5> {Math.round(list.price_prod)} {list.currency} </h5>
                <button className="btn-add-cart"
                  onClick={() => window.location.href = `/produit/details/${list.name_prod.replaceAll(" ", "-")}=${list.id_prod}`}> Voir les détails </button>
              </div>
            </div>
            </Link> ;
          })
        )
      }
    </>
  );
};

export default CardProductList;
