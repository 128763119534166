import React, { useContext, useState } from "react";
import AuthContext from "../../../context/AuthContext";
import { validInputText } from "../../controlFields/ControlField";

import "../auth.scss";
import { DASHBOARD_ADMIN_RESETFORM, DASHBOARD_ADMIN_RESETPWD, HOME, REGISTTER } from "../../../settings/constant";

const LoginAdmin = () => {
  const { loginAdmin } = useContext(AuthContext);
  const [done, isDone] = useState(false);
  const [value, setValue] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    validInputText(value.email, "email-error", "email");

    if (validInputText(value.email, "email-error", "email")) {
      const email = value.email;
      const password = value.password;
      loginAdmin(email, password, isDone);
    }
  };

  return (
    <div className="login-admin">
      <div className="login-block">
        <form className="form-login form-login-register">
          <h2> Connectez-vous </h2>
          {done ? <p className="text-error"> Identifiant incorrect ! </p> : null}
          <div className="div-field">
            <label>Email</label>
            <input type="email" 
              placeholder="Votre e-mail..."
              name="email"
              value={value.email}
              onChange={handleChange}
              onKeyUp={() => validInputText(value.email, "email-error", "email")} />
            <span className="span" id="email-error"></span>
          </div>
          <div className="div-field">
            <a href={DASHBOARD_ADMIN_RESETPWD}>Mot de passe oublié ?</a>
            <label>Mot de passe</label>
            <input type="password" placeholder="Votre mot de passe..."
              name="password"
              id="password"
              value={value.password}
              onChange={handleChange} />
            <span className="span" id="pwd-error"></span>
          </div>
          <div className="btn-submit">
            <button type="submit" onClick={handleSubmit}>Connexion</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginAdmin;
