import React, { useContext, useEffect, useState } from "react";
import { FiDelete, FiEdit3 } from "react-icons/fi";

import { HeadTabs } from "../../../../components/headTabs/HeadTabs";
import Pagination from "../../../../components/pagination2/Pagination";
import SearchBar from "../../../../components/searchBar2/SearchBar";
import GeneralContext from "../../../../context/GlobalContext";

import "./Categories.scss";
import { validInputText } from "../../../../components/controlFields/ControlField";

const Categories = () => {
  const [searchWord, setSearchWord] = useState("");
  const startNbPage = 8;
  const [nextPage, setNextPage] = useState(startNbPage);
  const [previouspage, setPreviousPage] = useState(0);
  const [resultSearch, setResultSearch] = useState([]);

  const { category, addCategorie,
    deleteCategory, updateCategorie } = useContext(GeneralContext);

  const [categorie, setCategorie] = useState(null);
  const [subCategorie, setSubCategorie] = useState(null);
  const [done, isDone] = useState(false);
  const [idCat, setIdCat] = useState(null);

  // serch automatik
  const search = async () => {
    if (searchWord) {
      const tab = category.filter(
        (item) => item.nameCat.toLowerCase().includes(searchWord.toLowerCase())
      );

      if (tab.length > 0) {
        setResultSearch(tab);
      }
    }
  };


  // return subcategorie
 /*  const returnSubCategries = (id) => {
    let sub = "";
    if (category && category.length) {
      const filter = category.find(
        (item) => id === item.idCat
      );

      if (filter) {
        const subcat = filter.SubCategories;
        if (subcat && subcat.length > 0) {
          for (let i = 0; i < subcat.length; i++) {
            sub += subcat[i].nameSubCat+" -";
          }
          return sub;
        } else {
          return "Aucun";
        }
      }
    }
  }; */

  // add and updatecategorie
  const handleAddCategoie = e => {
    e.preventDefault();

    validInputText(categorie, "cat-err", "text");

    const subs = [];
    if (validInputText(categorie, "cat-err", "text")) {
      /* if (subCategorie) {
        let split = subCategorie.split("/");
        if (split && split.length > 0) {
          for (let i = 0; i < split.length; i++) {
            subs.push(split[i].trim());
          }
        }
      } */
      if (idCat) {
        updateCategorie(idCat, categorie, subs, isDone);
      }
      else {
        addCategorie(categorie, subs, isDone);
      }
    }
  };

  // set infos categorie to update
  const updateCat = (list) => {
    setIdCat(list.idCat);
    setCategorie(list.nameCat);

    let text = document.getElementById("text");
    let btn = document.getElementById("btn");
    text.innerText = "Modification";
    btn.innerText = "Modifier";
    let subs = "";
    const sub = list.SubCategories;
    for (let i = 0; i < sub.length; i++) {
      subs += sub[i].nameSubCat + "/";
    }
    setSubCategorie(subs);
  };


  useEffect(() => {

    // search automatique
    search();
  }, [searchWord]);

  return (
    <div className="categorie-page">
      <HeadTabs title={"Categories"} />
      <div className='categorie parent'>
        <div className="child">
          <SearchBar placeholderText="Rechercher par nom categorie"
            searchWord={searchWord} allList={category.length > 0 ? category : null}
            setList={setResultSearch}
            setWord={setSearchWord} />
          <table className="table-data">
            <thead>
              <tr>
                <th> N° </th>
                <th> Nom Categorie </th>
                <th> Actions </th>
              </tr>
            </thead>
            <tbody>
              {
                resultSearch.length == 0 ? (
                  category.length > 0 && (
                    category.map((list, index) => {
                      if (!list.is_delivered) {
                        if (previouspage <= index && (nextPage - 1) >= index) {
                          return <tr key={index}>
                            <td> {index + 1} </td>
                            <td> {list.nameCat} </td>
                            {/* <td> {returnSubCategries(list.idCat)} </td> */}
                            <td>
                              <button onClick={() => updateCat(list)}> <FiEdit3 /> <br/> Editer </button>
                              <button onClick={() => deleteCategory(list.idCat)}> <FiDelete /> <br/> Supprimer </button>
                            </td>
                          </tr>;
                        }
                      }
                    })
                  )
                ) : (
                  resultSearch.map((list, index) => {
                    if (!list.is_delivered) {
                      return <tr key={index}>
                        <td> {index + 1} </td>
                        <td> {list.nameCat} </td>
                        <td>
                          <button onClick={() => updateCat(list)}> <FiEdit3 /> <br /> Editer </button>
                          <button onClick={() => deleteCategory(list.idCat)}> <FiDelete /> <br /> Supprimer </button>
                        </td>
                      </tr>;
                    }
                  })
                )

              }
            </tbody>
          </table>
          {resultSearch.length == 0 ? (
            <Pagination nb={startNbPage}
              countTab={category.length > 0 ? category.length : 0}
              setNextt={setNextPage}
              setPrevious={setPreviousPage}
              nextt={nextPage}
              previous={previouspage} />
          ) : null}
        </div>
        <div className="add-categorie">
          <form>
            <h3 id="text"> Nouveau Catégorie </h3>
            {done ? <p className="msg-text">
              échec !
            </p> : null}
            <div className="div-input">
              <label> Nom Catégorie </label>
              <input type="text" value={categorie}
                onChange={e => setCategorie(e.target.value)}
              />
              <span className="err" id="cat-err"></span>
            </div>{/* 
            <div className="div-input">
              <label> Sous-Catégorie (facultatif) </label>
              <textarea cols={5} rows={5}
                placeholder="ex: cat1/cat2/..."
                value={subCategorie}
                onChange={e => setSubCategorie(e.target.value)}
              ></textarea>
              <span className="err" id="subcat-err"></span>
            </div> */}
            <button id="btn" onClick={handleAddCategoie}>
              Ajouter
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Categories;
