import React from "react";
import {Navigate} from "react-router-dom";
import {useContext} from "react";
import AuthContext from "./context/AuthContext";
import { DASHBOARD_ADMIN_LOGIN } from "./settings/constant";

const PrivateRouteAdmin = ({children}) => {
  const {userAdmin} = useContext(AuthContext);
  if (!userAdmin) {
    return <Navigate to={DASHBOARD_ADMIN_LOGIN} replace/>;
  }
  return children;
};

export default PrivateRouteAdmin;
