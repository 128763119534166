import React, { useContext, useState } from "react";

import "./Contact.scss";
import GeneralContext from "../../../context/GlobalContext";
import { validInputText } from "../../../components/controlFields/ControlField";

import contact_img from "../../../assets/images/contact-us.webp";

const Contact = () => {
    const { sendMailByUser } = useContext(GeneralContext);

    const [fullname, setFullname] = useState(null);
    const [email, setEmail] = useState(null);
    const [msg, setMsg] = useState(null);

    // handle send msg
    const handleSubmit = e => {
        e.preventDefault();
        let err = document.getElementById("msg-form");

        validInputText(msg, "msg-err", "text");
        validInputText(fullname, "fn-err", "text");
        validInputText(email, "email-err", "email");

        if (validInputText(msg, "msg-err", "text") &&
            validInputText(fullname, "fn-err", "text") &&
            validInputText(email, "email-err", "email")) {
            sendMailByUser(msg, email, fullname, err);
        }
    };

    return (
        <div className="contact-page">
            <div className="contact-div-flex">
                <div className="img">
                    <img src={contact_img} />
                </div>
                <div className="form-contact">
                    <div className="head">
                        <div>
                            <h3>Visitez-nous</h3>
                            <p>
                                1 rue du docteur bienfait 51100 Reims,France
                            </p>
                        </div>
                        <div>
                            <h3>Restez informé</h3>
                            <p>
                                Nous sommes joignables par mail à l’adresse contact@autasys.com                            </p>
                        </div>
                    </div>
                    <form className="form-avis">
                        <p id="msg-form"></p>
                        <div className="div-fields">
                            <p>
                                <input type="text" placeholder="Nom *"
                                    value={fullname}
                                    onChange={e => setFullname(e.target.value)} />
                                <span className="span-error" id="fn-err"></span>
                            </p>
                        </div>
                        <div className="div-fields">
                            <p>
                                <input type="email" placeholder="E-mail*"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)} />
                                <span className="span-error" id="email-err"></span>
                            </p>
                        </div>
                        <div className="div-field">
                            <textarea cols={5} rows={5}
                            placeholder="Laissez votre message"
                                value={msg}
                                onChange={e => setMsg(e.target.value)}></textarea>
                            <span className="span-error" id="msg-err"></span>
                        </div>
                        <button className="btn-send"
                            onClick={handleSubmit}> Soumettre </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Contact;