import React, { useContext, useEffect, useState } from "react";
import CardProducts from "../../../components/cardProducts/CardProducts";
import { Spinner2 } from "../../../components/spinner2/Spinner2";
import GeneralContext from "../../../context/GlobalContext";

import "./SearchPage.scss";


const SearchPage = () => {
  const [nb, setNb] = useState(8);
  const [nextPage, setNextPage] = useState(nb);
  const [prevPage, setPrevPage] = useState(0);

  const [keyword, setKeyword] = useState("");

  const { productSearch, getProductSearch } = useContext(GeneralContext);

  useEffect(() => {
    let wd = window.location.search.substring(1).replaceAll("+", " ");
    setKeyword(wd.split("&")[0]);
    getProductSearch(wd.split("&")[0], wd.split("&")[1]);
  }, []);

  return (
    <div className="search-page">
      <h1>{
        productSearch.length != 0 ?
          `Résultat des recherches pour <<${keyword}>>` :
          `Aucun résultat pour <<${keyword}>>`
      }
      </h1>
      <div className="products">
        {productSearch.lenght != 0 ? <CardProducts
          listProduct={productSearch} nextPage={nextPage}
          prevPage={prevPage} /> :
          <Spinner2 />
        }
      </div>
    </div>
  );
};

export default SearchPage;