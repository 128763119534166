import React, { useContext, useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { FiEdit3, FiEye, FiPlus, FiTrash2 } from "react-icons/fi";
import { HeadTabs } from "../../../../components/headTabs/HeadTabs";
import Pagination from "../../../../components/pagination2/Pagination";
import SearchBar from "../../../../components/searchBar2/SearchBar";
import { API_URL, DASHBOARD_ADMIN_ADD_PRODUCTS } from "../../../../settings/constant";

import "./products.scss";
import GeneralContext from "../../../../context/GlobalContext";
import { PopupLayout } from "../../../../components/popupLayout/PopupLayout";
import { BoxAlertSucess } from "../../../../components/boxAlertSucess/BoxAlertSucess";


const ListProductAdmin = () => {
  const [popup, isPopup] = useState(false);
  const [popup2, isPopup2] = useState(false);

  const [searchWord, setSearchWord] = useState("");
  const [startNbPage, setStartNbPage] = useState(4);
  const [nextPage, setNextPage] = useState(startNbPage);
  const [previouspage, setPreviousPage] = useState(0);
  const [resultSearch, setResultSearch] = useState([]);
  const [storeName, setStoreName] = useState(null);

  const [reducePercent, setReducePercent] = useState();
  const [nameProd, setNameProd] = useState("");
  const [idProd, setIdProd] = useState("");

  const [done, isDone] = useState(false);

  const [msgPublish, setMsgPublish] = useState(null);

  const { productStore, getProductToStore,
    store, updateReduce, deleteProduct,
    updateProductTendanceOrCollection } = useContext(GeneralContext);


  /* return store active */
  const returnStore = () => {
    if (store.length > 0) {
      let find = store.find(
        (item) => item.is_active === true
      );

      if (find) {
        setStoreName(find.companyName.replaceAll(" ", ""));
      }
    }
  };

  /* search product */
  const search = async () => {
    if (searchWord) {
      const tab = productStore.filter(
        (item) => item.name_prod.toLowerCase().includes(searchWord.toLowerCase()) ||
          item.category.nameCat.toLowerCase().includes(searchWord),
      );

      if (tab.length != 0) {
        setResultSearch(tab);
      } else {
        setResultSearch([]);
      }
    }
  };

  /* update product */
  const update_product = (product) => {
    sessionStorage.setItem("product", JSON.stringify(product));
    window.location.href = DASHBOARD_ADMIN_ADD_PRODUCTS;
  };

  /* handle update product of reduce percent */
  const handleReduceUpdate = () => {
    updateReduce(idProd, reducePercent, isDone, setReducePercent);
  };

  /* delete product */
  const handleDelete = (id_prod) => {
    deleteProduct(id_prod, isDone, isPopup2, setMsgPublish);
  };

  /* set reduction of promotion */
  const setReduce = (name_prod, prod_id) => {
    isPopup(true);
    setNameProd(name_prod);
    setIdProd(prod_id);
  };

  /* render table column data */
  const renderColumnData = (tableData) => {
    return (
      tableData.map((list, index) => {
        if (previouspage <= index && (nextPage - 1) >= index) {
          if (!list.is_delete) {
            return <tr key={index}>
              <td> {index + 1} </td>
              <td> <img src={`${API_URL}${list.image_prod}`} /> </td>
              <td> {list.name_prod} </td>
              <td> {list.sizes ? list.sizes: "-"} </td>
              <td> {list.colors ? list.colors: "-"} </td>
              <td> {list.Category !== null ? list.Category.nameCat : null} </td>
              <td> {Math.round(list.price_prod)} {list.currency} </td>
              <td> {list.reduces} </td>
              <td>
                {
                  list.is_tendance ? (
                    <button onClick={() => updateProductTendanceOrCollection(list, "tendance", "retry", isDone, isPopup2, setMsgPublish)}> <FiPlus /> <br /> Retiré des produits Tendance </button>
                  ) : (
                    <button onClick={() => updateProductTendanceOrCollection(list, "tendance", "add", isDone, isPopup2, setMsgPublish)}> <FiPlus /> <br /> Ajouter aux produits Tendance </button>
                  )
                }
                {
                  list.is_collection ? (
                    <button onClick={() => updateProductTendanceOrCollection(list, "collection", "retry", isDone, isPopup2, setMsgPublish)}> <FiPlus /> <br /> Retiré des produits Collections </button>
                  ) : (
                    <button onClick={() => updateProductTendanceOrCollection(list, "collection", "add", isDone, isPopup2, setMsgPublish)}> <FiPlus /> <br /> Ajouter aux produits Collections </button>
                  )
                }
                <button onClick={() => setReduce(list.name_prod, list.id_prod)}> <FiEdit3 /> <br /> Promouvoir </button>
                <button onClick={() => update_product(list)}> <FiEdit3 /> <br /> Editer </button>
                <button onClick={() => handleDelete(list.id_prod)}> <FiTrash2 /> <br /> Supprimer </button>
              </td>
            </tr>;
          }
        }
      })
    );
  };


  useEffect(() => {
    returnStore();
    search();

    // get product  for admin
    getProductToStore(0);
  }, [store, searchWord]);


  return (
    <div className="product-dashboard-buyer">
      <HeadTabs title={"Produits"} />
      <PopupLayout closes={popup}>
        <div className='block-form-promo'>
          <span className='close-x' onClick={() => {
            isDone(false);
            isPopup(false);
            setReducePercent("");
            window.location.reload();
          }}>&times;</span>
          {
            !done ? (
              <>
                <p className='text'>
                  Pomouvoir un produit revient à mettre le
                  produit en promotion afin d'attirer plus de visiteurs
                </p>
                <div className='box'>
                  <p>
                    Nom Produit: <b> {nameProd} </b>
                  </p>
                  <p>
                    Réduction:
                    <input type="text" name='reduce'
                      placeholder='Pourcentage de réduction'
                      value={reducePercent}
                      onChange={e => setReducePercent(e.target.value)}
                    /> %
                  </p>
                  <button onClick={handleReduceUpdate}> Appliquer </button>
                </div>
              </>
            ) : (
              <>
                <BoxAlertSucess popup={popup}
                  text="Réduction Appliquée avec succès !"
                  handleBtn={isPopup} isDone={isDone} done={done}
                  link={"#"} />
              </>
            )
          }
        </div>
      </PopupLayout>
      <BoxAlertSucess popup={popup2}
        text={msgPublish}
        handleBtn={isPopup2} isDone={isDone} done={done}
        link={"#"} />

      <div className="product-box parent">
        <div className="btn-add-product">
          <button onClick={() => window.location.href = DASHBOARD_ADMIN_ADD_PRODUCTS}> 
            <FaPlus /> Nouveau produit
          </button>
        </div>
        <div className="list-product child">
          <>
            <SearchBar placeholderText="Rechercher par nom Product et categorie"
              searchWord={searchWord} allList={productStore}
              setList={setResultSearch}
              setWord={setSearchWord} />
            <table className="table-data">
              <thead>
                <tr>
                  <th> ID </th>
                  <th> Image </th>
                  <th> Nom du Produit </th>
                  <th> Tailles </th>
                  <th> Couleurs </th>
                  <th> Catégorie </th>
                  <th> Prix unitaire </th>
                  <th> Réduction </th>
                  <th> Actions </th>
                </tr>
              </thead>
              <tbody>
                {
                  productStore.length > 0 ? (
                    resultSearch.length == 0 ? (
                      productStore.length !== 0 && (
                        renderColumnData(productStore)
                      )
                    ) : (
                      renderColumnData(resultSearch)
                    )
                  ) : null
                }
              </tbody>
            </table>
            {resultSearch.length == 0 ? (
              <Pagination nb={startNbPage}
                countTab={productStore.length}
                setNextt={setNextPage}
                setPrevious={setPreviousPage}
                nextt={nextPage}
                previous={previouspage} />
            ) : null}
          </>

        </div>
      </div>
    </div>
  );
};

export default ListProductAdmin;
