import React from "react";
import {Navigate} from "react-router-dom";
import {useContext} from "react";
import AuthContext from "./context/AuthContext";
import { LOGIN } from "./settings/constant";

const PrivateRoute = ({children}) => {
  const {user} = useContext(AuthContext);
  if (!user) {
    return <Navigate to={LOGIN} replace/>;
  }
  return children;
};

export default PrivateRoute;
