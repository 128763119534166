import React, { useContext, useEffect, useState } from "react";

import "./profil.scss";
import { validInputText } from "../../../../components/controlFields/ControlField";
import AuthContext from "../../../../context/AuthContext";
import GeneralContext from "../../../../context/GlobalContext";
import { country } from "../../../../components/currencyList/countries";

const ProfilAccount = () => {

  const { updateUserAccount2 } = useContext(AuthContext);
  const { userData } = useContext(GeneralContext);

  const [lastname, setLastname] = useState(null);
  const [firstname, setFirstname] = useState(null);
  const [countrys, setCountrys] = useState(null);
  const [city, setCity] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);


  /* mise à jour du profile utilisateur */
  const updateUserProfile = async (e) => {
    e.preventDefault();

    let msg_res = document.getElementById("msg-res");

    validInputText(firstname, "firstname-error", "text");
    validInputText(lastname, "lastname-error", "text");
    validInputText(city, "city-error", "text");
    validInputText(phone, "phone-error", "tel2");
    validInputText(email, "email-error", "email");

    if (validInputText(firstname, "firstname-error", "text") &&
      validInputText(lastname, "lastname-error", "text") &&
      validInputText(city, "city-error", "text") &&
      validInputText(phone, "phone-error", "tel2") &&
      validInputText(email, "emails-error", "email")) {

      const formData = new FormData();
      formData.append("firstname", firstname);
      formData.append("lastname", lastname);
      formData.append("country", countrys);
      formData.append("city", city);
      formData.append("phone", phone);
      formData.append("email", email);

      updateUserAccount2(formData, msg_res,);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (userData) {
        setLastname(userData.lastname);
        setFirstname(userData.firstname);
        setCountrys(userData.country);
        setCity(userData.city);
        setEmail(userData.email);
        setPhone(userData.phone);
      }
    }, 1000);

  },[userData]);


  return (
    <div className="profil-account">
      <div className="div1">
        <form>
          <h3 className="title-h3"> Information du Compte </h3>
          <p id="msg-res"></p>
          <div className="div-input inputs">
            <p>
              <input type="text"
                name="lastname"
                placeholder="Lastname"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)} />
              <span className="span-error" id="firstname-error"></span>
            </p>
            <p>
              <input type="text"
                name="firstname"
                placeholder="Firstname"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)} />
              <span className="span-error" id="lastname-error"></span>
            </p>
          </div>
          <div className="div-input">
            <p>
              <select value={countrys}
                onChange={e => setCountrys(e.target.value)} >
                <option disabled selected>Choisissez une pays</option>
                {country.map((list, index) => {
                  return <option value={list.continentName} key={index}>
                    {list.countryName}
                  </option>;
                })}
              </select>
              <span className="span-error" id="country-error"></span>
            </p>
          </div>
          <div className="div-input">
            <p>
              <input type="text"
                name="city"
                placeholder="Ville"
                value={city}
                onChange={(e) => setCity(e.target.value)} />
            </p>
            <span className="span-error" id="city-error"></span>
          </div>
          <div className="div-input">
            <p>
              <input type="tel" name="phone"
                placeholder="Téléphone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)} />
              <span className="span-error" id="phone-error"></span>
            </p>
          </div>
          <div className="div-input">
            <p>
              <input type="text"
                name="email"
                placeholder="E-mail"
                value={email}
                onChange={e => setEmail(e.target.value)} />
            </p>
            <span className="span-error" id="emails-error"></span>
          </div>
          <button className="btn-update" onClick={updateUserProfile}> Mettre à jour </button>
        </form>
      </div>
    </div>
  );
};

export default ProfilAccount;