import React, { useContext, useEffect, useState } from "react";
import { FiDelete, FiEdit3 } from "react-icons/fi";

import GeneralContext from "../../../../context/GlobalContext";
import { validInputText } from "../../../../components/controlFields/ControlField";
import SearchBar from "../../../../components/searchBar2/SearchBar";
import Pagination from "../../../../components/pagination2/Pagination";
import { BoxAlertSucess } from "../../../../components/boxAlertSucess/BoxAlertSucess";

const AddCategorie = () => {
  const [searchWord, setSearchWord] = useState("");
  const [startNbPage, setStartNbPage] = useState(6);
  const [nextPage, setNextPage] = useState(startNbPage);
  const [previouspage, setPreviousPage] = useState(0);
  const [resultSearch, setResultSearch] = useState([]);

  const [done, isDone] = useState(false);
  const [categorie, setCategorie] = useState(null);
  const [fail, isFail] = useState(false);
  const [idCat, setIdCat] = useState(null);
  const [popup, isPopup] = useState(false);
  const [msgSucces, setMsgSucces] = useState("");

  const { categoryArticle, getCategoriesArticle, addCategorieArticle,
    deleteCategorieArticle, updateCategorieArticle } = useContext(GeneralContext);

  // search 
  const searchArticle = () => {
    if (searchWord) {
      const tab = categoryArticle.filter(
        (item) => item.nomCat.toLowerCase().includes(searchWord.toLowerCase())
      );

      if (tab.length > 0) {
        setResultSearch(tab);
      } else {
        setResultSearch([]);
      }
    }
  };

  // set infos data for update
  const updateCat = (list) => {
    setIdCat(list.id_cat);
    setCategorie(list.nomCat);

    let text = document.getElementById("text");
    let btn = document.getElementById("btn");
    text.innerText = "Modification";
    btn.innerText = "Modifier";
  };

  // add categorie
  const handleAddCategoie = e => {
    e.preventDefault();

    validInputText(categorie, "cat-err", "text");

    if (validInputText(categorie, "cat-err", "text")) {
      if (idCat) {
        updateCategorieArticle(idCat, categorie, setMsgSucces, isFail, isDone, isPopup);
      }
      else {
        addCategorieArticle(categorie, setMsgSucces, isFail, isDone, isPopup);
      }
    }
  };

  useEffect(() => {
    // search automatik
    searchArticle();

    // get article
    getCategoriesArticle();
  }, [searchWord]);

  return (
    <div className="add-article box-art">
      <BoxAlertSucess popup={popup}
        text={msgSucces}
        isDone={isDone}
        done={done}
        handleBtn={isPopup}
        link={"#"} />
      <div className="child">
        <SearchBar placeholderText="Rechercher par nom categorie d'article"
          searchWord={searchWord} allList={categoryArticle.length > 0 ? categoryArticle : null}
          setList={setResultSearch}
          setWord={setSearchWord} />
        <table className="table-data">
          <thead>
            <tr>
              <th> N° </th>
              <th> Nom Catégorie </th>
              <th> Date de création </th>
              <th> Actions </th>
            </tr>
          </thead>
          <tbody>
            {
              resultSearch.length == 0 ? (
                categoryArticle.length > 0 && (
                  categoryArticle.map((list, index) => {
                    if (previouspage <= index && (nextPage - 1) >= index) {
                      return <tr key={index}>
                        <td> {index + 1} </td>
                        <td> {list.nomCat} </td>
                        <td> {list.createdAt} </td>
                        <td>
                          <button onClick={() => updateCat(list)}> <FiEdit3 /> <br /> Editer </button>
                          <button onClick={() => deleteCategorieArticle(list.id_cat, setMsgSucces, isFail, isDone, isPopup)}> <FiDelete /> <br /> Supprimer </button>
                        </td>
                      </tr>;
                    }
                  })
                )
              ) : (
                resultSearch.map((list, index) => {
                  return <tr key={index}>
                    <td> {index + 1} </td>
                    <td> {list.nomCat} </td>
                    <td> {list.createdAt} </td>
                    <td>
                      <button onClick={() => updateCat(list)}> <FiEdit3 /> <br /> Editer </button>
                      <button onClick={() => deleteCategorieArticle(list.id_art)}> <FiDelete /> <br /> Supprimer </button>
                    </td>
                  </tr>;
                })
              )
            }
          </tbody>
        </table>
        {resultSearch.length == 0 ? (
          <Pagination nb={startNbPage}
            countTab={categoryArticle.length > 0 ? categoryArticle.length : 0}
            setNextt={setNextPage}
            setPrevious={setPreviousPage}
            nextt={nextPage}
            previous={previouspage} />
        ) : null}
      </div>
      <div className="add-categorie">
        <form>
          <h3 id="text"> Nouvelle Catégorie </h3>
          {fail ? <p className="msg-text">
            Echec de l'opération ! Réessayer
          </p> : null}
          <div className="div-input">
            <input type="text"
              placeholder="Nom du catégorie"
              value={categorie}
              onChange={e => setCategorie(e.target.value)}
            />
            <span className="err" id="cat-err"></span>
          </div>
          <button id="btn" onClick={handleAddCategoie}>
            Ajouter
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddCategorie;