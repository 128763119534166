import React, { useState } from "react";

import "./auth.scss";
import Login from "./login/Login";
import Register from "./register/Register";

const Auth = ({ isPopup }) => {

  const [form, setForm] = useState(0);

  const returnForm = () => {
    if (form === 0) {
      return <> <Login setForm={setForm} /> </>;
    } else if (form === 1) {
      return <> <Register setForm={setForm} /> </>;
    }
  };

  return (
    <div className="background-form">
      <div className="block-form">
        <span className="close" onClick={() => isPopup(false)}>&times;</span>
        <form className="form-login-register">
          {returnForm()}
        </form>
      </div>
    </div>
  );
};

export default Auth;
