import React from "react";
import {ImSpinner3} from "react-icons/im";

import "./Spinner2.scss";

export const Spinner2 = () => {
  return (
    <div className="spin-anim2">
      <div className="svg">
        <ImSpinner3 />
      </div>
    </div>
  );
};
