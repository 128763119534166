export const createSlug = (title) => {
// Étape 1 : Normalisation du texte
const normalizedTitle = title
    .toLowerCase()
    .replace(/[^\w\s-]/g, "") // Étape 2 : Suppression des caractères spéciaux sauf les espaces et les tirets
    .trim() // Supprime les espaces en début et fin de chaîne
    .replace(/\s+/g, "-") // Étape 3 : Remplace les espaces par des tirets
    .replace(/-+/g, "-"); // Étape 4 : Gère les tirets consécutifs

return normalizedTitle;
};
