import React, { useState } from "react";

import "./Faq.scss";
import { FiPlus } from "react-icons/fi";
import { FaBars, FaLess, FaLessThan } from "react-icons/fa";
import { CONTACT_PAGE, POLITIC_CONFIDENTIALITe_PAGE, POLITIC_REMBOURSEMENT_PAGE } from "../../../settings/constant";

const Faq = () => {
    const [open1, setOpen1] = useState();
    const [open2, setOpen2] = useState();
    const [open3, setOpen3] = useState();

    return (
        <div className="faq-page">
            <div className="section-one-faq">
                <div className="slid d1">
                    <div className="slid-child">
                        <div>
                            <h1>
                                FAQ
                            </h1>
                            <p>
                                Foire aux questions
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <section className="section-two-faq">
                <div className="div-quiz">
                    <h3>Achats de base</h3>
                    <div className="div-quizs">
                        <div className={`${open1 === 0 ? "open" : null}`}>
                            <li onClick={() => setOpen1(0)}>
                                {open1 === 0 ? <FaBars /> : <FiPlus />}
                                Quels sont les modes d'expédition disponibles ?
                            </li>
                            <p className="rep">
                                Vous pouvez discuter de la livraison directement avec le vendeur quelque soit votre pays.
                            </p>
                        </div>
                        <div className={`${open1 === 1 ? "open" : null}`}>
                            <li onClick={() => setOpen1(1)}>
                                {open1 === 1 ? <FaBars /> : <FiPlus />}
                                Quelles sont vos politiques d'expédition et de retour ?
                            </li>
                            <p className="rep">
                                Vous avez toutes les informations possibles dans notre <a href={POLITIC_REMBOURSEMENT_PAGE}>politique de remboursement</a> .
                            </p>
                        </div>
                        <div className={`${open1 === 2 ? "open" : null}`}>
                            <li onClick={() => setOpen1(2)}>
                                {open1 === 2 ? <FaBars /> : <FiPlus />}
                                Expédiez-vous à l'étranger ?
                            </li>
                            <p className="rep">
                                Oui. Les articles commandés peuvent être expédiés partout.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="div-quiz">
                    <h3> Paiements </h3>
                    <div className="div-quizs">
                        <div className={`${open2 === 0 ? "open" : null}`}>
                            <li onClick={() => setOpen2(0)}>
                                {open1 === 0 ? <FaBars /> : <FiPlus />}
                                Quels sont les modes de paiement acceptés ?
                            </li>
                            <p className="rep">
                                Nous acceptons tous les moyens de paiement mobile mais également les cartes VISA et MASTERCARD.
                            </p>
                        </div>
                        <div className={`${open2 === 1 ? "open" : null}`}>
                            <li onClick={() => setOpen2(1)}>
                                {open2 === 1 ? <FaBars /> : <FiPlus />}
                                Acceptez-vous les cartes de crédit internationales ?
                            </li>
                            <p className="rep">
                                Les cartes bancaires VISA et MASTERCARD sont acceptées.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="div-quiz">
                    <h3>Expédition et retours</h3>
                    <div className="div-quizs">
                        <div className={`${open3 === 0 ? "open" : null}`}>
                            <li onClick={() => setOpen3(0)}>
                                {open3 === 0 ? <FaBars /> : <FiPlus />}
                                Je veux retourner mon achat ! Que dois-je faire ?
                            </li>
                            <p className="rep">
                                Vous pouvez nous contacter directement à travers notre formulaire de contact.
                            </p>
                        </div>
                        <div className={`${open3 === 1 ? "open" : null}`}>
                            <li onClick={() => setOpen3(1)}>
                                {open3 === 1 ? <FaBars /> : <FiPlus />}
                                Combien de temps faut-il pour obtenir un remboursement ?
                            </li>
                            <p className="rep">
                                Vous pouvez vous référer à notre <a href={POLITIC_CONFIDENTIALITe_PAGE}>politique de remboursement</a>.
                            </p>
                        </div>
                        <div className={`${open3 === 2 ? "open" : null}`}>
                            <li onClick={() => setOpen3(2)}>
                                {open3 === 2 ? <FaBars /> : <FiPlus />}
                                Comment puis-je obtenir de l'aide si j'en ai besoin ?
                            </li>
                            <p className="rep">
                                Vous pouvez <a href={CONTACT_PAGE} >nous contacter</a> directement. Nos équipes vous apporteront l’assistance nécessaire.                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <div className="section-three-faq">
                <div className="div-block">
                    <h5> CONTACT </h5>
                    <div>
                        <h3>
                            Avez-vous encore d’autres questions?
                        </h3>
                        <a href={CONTACT_PAGE}>Contactez-nous</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Faq;