const ext_img = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
  export const verifyFile = (filename, id_err) => {
    if (filename) {
      let splits = filename.split(".");
      let ext = splits[splits.length - 1];
      const img_error = document.getElementById(id_err);

      let f = ext_img.find((item) =>
        ext === item  );
      if (f) {
        return true;
      } else {
        img_error.innerText = "Sélectionner uniquement les formats d'images indiquées.";
        return false;
      }
    } else return true;
  };