import React, { useContext, useEffect, useState } from "react";
import { PopupLayout } from "../popupLayout/PopupLayout";
import SelectCountryField from "../selectCountryField/SelectCountryField";
import InputDialCode from "../inputDialCode/InputDialCode";

import "./FormSeller.scss";
import { validInputText } from "../controlFields/ControlField";
import AuthContext from "../../context/AuthContext";
import GeneralContext from "../../context/GlobalContext";

const FormSeller = ({ isPopup, popup }) => {

  const { category } = useContext(GeneralContext);
  const { user, createStore } = useContext(AuthContext);

  const [step, setStep] = useState(0);
  const [email, setEmail] = useState(user ? user.email : null);
  const [companyName, setCompanyName] = useState(null);
  const [country, setCountry] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [categorys, setCategory] = useState(null);
  const [descStore, setDescStore] = useState(null);
  const [dialCode, setDialCode] = useState(null);

  // action create shop
  const handleSubmit = (e) => {
    e.preventDefault();

    let country_err = document.getElementById("country-error");
    let tel_err = document.getElementById("tel-error");

    validInputText(email, "email-error", "email");
    validInputText(phoneNumber, "tel-error", "tel");
    validInputText(categorys, "cat-error", "text");

    if (validInputText(email, "email-error", "email") &&
      validInputText(phoneNumber, "tel-error", "tel") &&
      validInputText(categorys, "cat-error", "text")) {
      if (country) {
        if (phoneNumber) {
          let cellphone = dialCode + phoneNumber;
          createStore(descStore, companyName, country, categorys, cellphone, user.id);
        } else {
          tel_err.innerText = "le numéro est important";
        }
      } else {
        country_err.innerText = "Définissez le pays de l'entreprise";
      }
    }
  };

  return (
    <PopupLayout closes={popup}>
      <div className='form-create-store1'>
        <span className='closes' onClick={() => {
          isPopup(!popup);
          setStep(0);
        }}>&times;</span>
        <form>
          {step === 0 && (
            <div className='step step1'>
              <h2>Créer votre boutique étape par étape</h2>
              <div className='div-input'>
                <p>
                  <label>E-mail <span>*</span> </label>
                  <input
                    type='text'
                    placeholder='E-mail'
                    value={user ? user.email : null}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <span className="err" id='email-error'></span>
                </p>
                <p>
                  <label>Nom de la boutique <span>*</span> </label>
                  <input
                    type='text'
                    placeholder='Nom de la boutique'
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                  <span className="err" id='comp-error'></span>
                </p>
              </div>
              <div className='div-input'>
                <p>
                  <label>Pays/région de la société enregistrée <span>*</span> </label>
                  <SelectCountryField
                    setCountry={setCountry}
                    setDialCode={setDialCode}
                  />
                  <span className="err" id='country-error'></span>
                </p>
                <p>
                  <label>Numéro de téléphone professionnel <span>*</span> </label>
                  <InputDialCode
                    setPhone={setPhoneNumber}
                    dialCode={dialCode}
                    onChange={(value) => setPhoneNumber(value)}
                  />
                  <span className="err" id='tel-error'></span>
                </p>
              </div>
              <div className='div-input'>
                <p>
                  <label> Catégorie principale de votre boutique <span>*</span> </label>
                  <select
                    value={categorys}
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    <option disabled selected>Sélectionner une catégorie</option>
                    {category.map((list, index) => {
                      return <option value={list.nameCat} key={index}>
                        {list.nameCat}
                      </option>;
                    })}
                  </select>
                  <span className="err" id='cat-error'></span>
                </p>
                <p>
                  <label> Descrivez un peu votre activité <span>*</span> </label>
                  <textarea cols={5} rows={5} placeholder="decrivez votre activité en quelques lignes"
                    value={descStore}
                    onChange={e => setDescStore(e.target.value)}>
                  </textarea>
                  <span className="err" id='desc-store-error'></span>
                </p>
              </div>
              <button onClick={handleSubmit}> Créer ma boutique </button>
            </div>
          )}
        </form>
      </div>
    </PopupLayout>
  );
};

export default FormSeller;
