import React, { useContext, useEffect, useState } from "react";
import { PopupLayout } from "../popupLayout/PopupLayout";
import SelectCountryField from "../selectCountryField/SelectCountryField";
import InputDialCode from "../inputDialCode/InputDialCode";
import { FiCheckCircle, FiChevronRight } from "react-icons/fi";

import "./FormSeller2.scss";
import { validInputText } from "../controlFields/ControlField";
import AuthContext from "../../context/AuthContext";
import GeneralContext from "../../context/GlobalContext";
import { Spinner2 } from "../spinner2/Spinner2";
import { ShowPasswdText } from "../showPasswdText/ShowPasswdText";

const FormSeller2 = ({ isPopup, popup }) => {

  const { createStoreByAdmin, category } = useContext(GeneralContext);
  const { user } = useContext(AuthContext);

  const [step, setStep] = useState(0);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(user ? user.email : null);
  const [storeName, setStoreName] = useState(null);
  const [country, setCountry] = useState(null);
  const [city, setCity] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [categorys, setCategory] = useState(null);
  const [descStore, setDescStore] = useState(null);
  const [dialCode, setDialCode] = useState(null);
  const [password, setPassword] = useState(null);

  const [emailExist, setEmailExist] = useState(false);
  const [done, isDone] = useState(false);
  const [launch, isLaunch] = useState(false);

  // action create shop
  const handleSubmit = (e) => {
    e.preventDefault();

    let country_err = document.getElementById("country-error");
    let tel_err = document.getElementById("tel-error");

    validInputText(email, "email-err", "email");
    validInputText(firstName, "fn-error", "text");
    validInputText(lastName, "ln-error", "text");
    validInputText(password, "pwd-error", "pwd");
    validInputText(city, "city-error", "text");
    validInputText(phoneNumber, "tel-error", "tel");
    validInputText(categorys, "cat-error", "text");

    if (validInputText(email, "email-err", "email") &&
      validInputText(firstName, "fn-error", "text") &&
      validInputText(lastName, "ln-error", "text") &&
      validInputText(password, "pwd-error", "pwd") &&
      validInputText(city, "city-error", "text") &&
      validInputText(phoneNumber, "tel-error", "tel") &&
      validInputText(categorys, "cat-error", "text")) {
      if (country) {
        if (phoneNumber) {
          isLaunch(true);
          let cellphone = dialCode + phoneNumber;
          createStoreByAdmin(firstName, lastName, email, country, city,
            password, storeName, cellphone, categorys, descStore, setEmailExist, isDone, isLaunch);
        } else {
          tel_err.innerText = "le numéro est important";
        }
      } else {
        country_err.innerText = "Définissez le pays de l'entreprise";
      }
    }
  };

  return (
    <PopupLayout closes={popup}>
      {launch ? (
        <div className="load">
          {!done ? <Spinner2 /> : (
            <>
              <FiCheckCircle />
              <p> 
                Création du compte et de la boutique fait avec succès. 
                Un email vous a été envoyer à l'adresse mail renseigné pour l'activation du compte.
              </p>
              <button onClick={() => {
                isPopup(false);
                window.location.reload();
              }}>Ok</button>
            </>
          )}
        </div>
      ) : (
        <div className='form-create-store'>
          <span className='closes' onClick={() => {
            isPopup(!popup);
            setStep(0);
          }}>&times;</span>
          <form>
            <div className='step step1'>
              <h2>Créer une boutique</h2>
              <h4>Informations du compte  </h4>
              {emailExist ? (
                <p className="msg-text">
                  Cet email est déjà utiliser pour un autre compte
                </p>
              ) : null}
              <div className='div-input'>
                <p>
                  <label>Prénom <span>*</span></label>
                  <input
                    type='text'
                    placeholder='Prénom'
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <span className="err" id='fn-error'></span>
                </p>
                <p>
                  <label>Nom de famille <span>*</span></label>
                  <input
                    type='text'
                    placeholder='Nom complet'
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  <span className="err" id='ln-error'></span>
                </p>
              </div>
              <div className='div-input'>
                <p>
                  <label>Pays/région <span>*</span> </label>
                  <SelectCountryField
                    setCountry={setCountry}
                    setDialCode={setDialCode}
                  />
                  <span className="err" id='country-error'></span>
                </p>
                <p>
                  <label>Ville <span>*</span> </label>
                  <input type="text" placeholder="Ville..."
                    value={city}
                    onChange={e => setCity(e.target.value)}
                  />
                  <span className="err" id='city-error'></span>
                </p>
              </div>
              <div className='div-input'>
                <p>
                  <label>E-mail <span>*</span> </label>
                  <input
                    type='text'
                    placeholder='E-mail'
                    value={email}
                    onChange={(e) => {
                      setEmailExist(false);
                      setEmail(e.target.value);
                    }}
                  />
                  <span className="err" id='email-err'></span>
                </p>
                <p>
                  <label>Numéro de téléphone <span>*</span> </label>
                  <InputDialCode
                    setPhone={setPhoneNumber}
                    dialCode={dialCode}
                    onChange={(value) => setPhoneNumber(value)}
                  />
                  <span className="err" id='tel-error'></span>
                </p>
              </div>
              <div className='div-input'>
                <p>
                  <ShowPasswdText id={"pwd"} />
                  <label> Créer un mot de passe <span>*</span> </label>
                  <input type="password"
                    id="pwd"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                  <span className="err" id='pwd-error'></span>
                </p>
                <p>
                  <label> Confirmer le mot de passe <span>*</span> </label>
                  <input type="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                </p>
              </div>
              <h4> Informations de la boutique </h4>
              <div className='div-input'>
                <p>
                  <label>Nom de la boutique <span>*</span> </label>
                  <input
                    type='text'
                    placeholder='Nom de la boutique'
                    value={storeName}
                    onChange={(e) => setStoreName(e.target.value)}
                  />
                  <span className="err" id='comp-error'></span>
                </p>
                <p>
                  <label> Catégorie principale du boutique <span>*</span> </label>
                  <select
                    value={categorys}
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    <option disabled selected>Sélectionner une catégorie</option>
                    {category.map((list, index) => {
                      return <option value={list.nameCat} key={index}>
                        {list.nameCat}
                      </option>;
                    })}
                  </select>
                  <span className="err" id='cat-error'></span>
                </p>
              </div>
              <div className='div-input'>
                <p>
                  <label> Descrivez un peu l'activité du boutique<span>*</span> </label>
                  <textarea cols={5} rows={5} placeholder="decrivez votre activité en quelques lignes"
                    value={descStore}
                    onChange={e => setDescStore(e.target.value)}>
                  </textarea>
                  <span className="err" id='desc-store-error'></span>
                </p>
              </div>
              <button onClick={handleSubmit}>Créer la boutique </button>
            </div>
          </form>
        </div>
      )}
    </PopupLayout>
  );
};

export default FormSeller2;
