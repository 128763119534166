import React, { useContext, useEffect, useState } from "react";
import { validInputText } from "../../controlFields/ControlField";
import { countries } from "../../countrylist/countrylist";
import SelectCountryField from "../../selectCountryField/SelectCountryField";
import { ShowPasswdText } from "../../showPasswdText/ShowPasswdText";
import AuthContext from "../../../context/AuthContext";

import "../auth.scss";
import GeneralContext from "../../../context/GlobalContext";
import { LOGIN } from "../../../settings/constant";
import { BoxAlertSucess } from "../../boxAlertSucess/BoxAlertSucess";

const AddUserAdmin = () => {
  const { addUserAdmin } = useContext(AuthContext);
  const { location } = useContext(GeneralContext);

  const [done, isDone] = useState(false);
  const [msgSucces, setMsgSucces] = useState("");
  const [popup, isPopup] = useState(false);
 
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordC, setPasswordC] = useState("");

  const [countryName, setCountryName] = useState(
    location ? location.country_name : "choisissez votre pays actuel"
  );


  const handleSubmit = (e) => {
    e.preventDefault();
    let msg = document.getElementById("err");
    validInputText(email, "email-error", "email");
    
    if (validInputText(email, "email-error", "email")) {
      addUserAdmin(email, isDone, isPopup, setMsgSucces, msg);
    }
  };


  return (
    <div className="login-block">
      <BoxAlertSucess popup={popup}
        text={msgSucces}
        isDone={isDone}
        done={done}
        handleBtn={isPopup}
        link={"#"} />
      <form className="form-login form-login-register">
        <h2> Nouveau utilisateur </h2>
        <p id="err"></p>
        <div className="div-field">
          <label>Email</label>
          <input
            type="email"
            placeholder="votre email..."
            name="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            onKeyUp={() => validInputText(email, "email-error", "email")}
          />
          <span className="span" id="email-error"></span>
        </div>
        <div className="btn-submit">
          <button onClick={handleSubmit} type="submit">
            Ajouter
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddUserAdmin;
