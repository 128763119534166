import React, { useContext, useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";

import "./Shop.scss";

import { HeadTabs } from "../../../../components/headTabs/HeadTabs";
import Pagination from "../../../../components/pagination2/Pagination";
import SearchBar from "../../../../components/searchBar2/SearchBar";
import GeneralContext from "../../../../context/GlobalContext";
import FormSeller2 from "../../../../components/formSeller2/FormSeller2";

const ShopAdmin = () => {
  const [searchWord, setSearchWord] = useState("");
  const startNbPage = 4;
  const [nextPage, setNextPage] = useState(startNbPage);
  const [previouspage, setPreviousPage] = useState(0);
  const [resultSearch, setResultSearch] = useState([]);

  const [popup, isPopup] = useState(false);

  const { allStore, store, getSaleStore, getAllStore,
    deleteStoreByAdmin } = useContext(GeneralContext);

  const [nbProduct, setNbProduct] = useState(0);
  const [nbSale, setNbSale] = useState(0);
  const [nbOrder, setNbOrder] = useState(0);

  // action search shop
  const search = async () => {
    if (searchWord) {
      const tab = allStore.filter(
        (item) => item.companyName.toLowerCase().includes(searchWord.toLowerCase())
      );

      if (tab.length > 0) {
        setResultSearch(tab);
      } else {
        setResultSearch([]);
      }
    }
  };

  const returnStore = () => {
    if (store.length > 0) {
      for (var i = 0; i < store.length; i++) {
        if (store[i].is_active == true) {
          getSaleStore(store[i].store_id);
          return 0;
        }
      }
    }
  };
  const returnOrder = (id) => {
    let nbOrder = 0;
    let nbSales = 0;
    if (allStore && allStore.length > 0) {
      const find = allStore.find((item) => item.store_id === id );
      if (find) {
        const tab = find.OrderItems;
        const order = tab.filter((item) => item.is_delivered === false);
        const sale = tab.filter((item) => item.is_delivered === true);
        nbOrder = order.length;
        nbSales = sale.length;
      }
    }
    return {nbOrder, nbSales};
  };

  useEffect(() => {
    returnStore();
    search();
    getAllStore();
  }, [store, searchWord, nbProduct, nbSale, nbOrder]);

  return (
    <div className="shop-admin-page">
      <FormSeller2 popup={popup} isPopup={isPopup} />
      <HeadTabs title={"Boutiques"} />
      <div className='shop-admin parent'>
        <div className="child">
          <div className="btn-group-action">
            <button onClick={() => isPopup(true)}> <FiPlus /> Créer une boutique </button>
          </div>
          <SearchBar placeholderText="Rechercher par nom boutique"
            searchWord={searchWord} allList={allStore.length > 0 ? allStore : null}
            setList={setResultSearch}
            setWord={setSearchWord} />
          <table className="table-data">
            <thead>
              <tr>
                <th> Nom Boutiques </th>
                <th> Compte </th>
                <th> Date création </th>
                <th> Nombres de produits </th>
                <th> Nombres de Commandes </th>
                <th> Nombres de Ventes </th>
                <th> Actions </th>
              </tr>
            </thead>
            <tbody>
              {
                resultSearch.length == 0 ? (
                  allStore.length > 0 && (
                    allStore.map((list, index) => {
                      if (previouspage <= index && (nextPage - 1) >= index) {
                        return <tr key={index}>
                          <td> {list.companyName} </td>
                          <td> {list.user && list.user.email} </td>
                          <td> {list.createdAt.split("T")[0]} </td>
                          <td> {list.Products.length} </td>
                          <td> {returnOrder(list.store_id).nbOrder} </td>
                          <td> {returnOrder(list.store_id).nbSales} </td>
                          <td>
                            <button onClick={() => deleteStoreByAdmin(list.store_id)}> Supprimer </button>
                          </td>
                        </tr>;
                      }
                    })
                  )
                ) : (
                  resultSearch.map((list, index) => {
                    return <tr key={index}>
                          <td> {index + 1} </td>
                          <td> {list.companyName} </td>
                          <td> {list.user.email} </td>
                          <td> {list.createdAt.split("T")[0]} </td>
                          <td> {list.Products ? list.Products.length : 0} </td>
                          <td>  </td>
                          <td></td>
                          <td>
                            <button onClick={() => deleteStoreByAdmin(list.store_id)}> Supprimer </button>
                          </td>
                    </tr>;
                  })
                )

              }
            </tbody>
          </table>
          {resultSearch.length == 0 ? (
            <Pagination nb={startNbPage}
              countTab={allStore.length > 0 ? allStore.length : 0}
              setNextt={setNextPage}
              setPrevious={setPreviousPage}
              nextt={nextPage}
              previous={previouspage} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ShopAdmin;
