import React, { useContext, useEffect, useState } from "react";
import { FiEdit3, FiTrash2 } from "react-icons/fi";

import "./RevenuFonds.scss";

import { HeadTabs } from "../../../../components/headTabs/HeadTabs";
import SearchBar from "../../../../components/searchBar2/SearchBar";
import GeneralContext from "../../../../context/GlobalContext";
import { validInputText } from "../../../../components/controlFields/ControlField";
import { BoxAlertSucess } from "../../../../components/boxAlertSucess/BoxAlertSucess";
import Pagination from "../../../../components/pagination2/Pagination";
import { PopupLayout } from "../../../../components/popupLayout/PopupLayout";

const RevenuFonds = () => {

  const { transaction, store, orderss, sales, getSaleStore, saleStore,
    createTransactionRequest, updateTransactionRequest,
    deleteTransactionRequest, getTransactionByStore } = useContext(GeneralContext);

  const [searchWord, setSearchWord] = useState("");
  const startNbPage = 5;
  const [nextPage, setNextPage] = useState(startNbPage);
  const [previouspage, setPreviousPage] = useState(0);
  const [resultSearch, setResultSearch] = useState([]);

  const [storeActive, setStoreActive] = useState([]);
  const [price, setPrice] = useState(null);
  const [done, isDone] = useState(false);
  const [popup, isPopup] = useState(false);
  const [openForm, isOpenForm] = useState(false);
  const [msg, setMsg] = useState(null);
  const [action, setAction] = useState("create");
  const [idReq, setIdReq] = useState(null);

  const search = async () => {
    if (searchWord) {
      const tab = transaction.filter(
        (item) => item.status_request.toLowerCase().includes(searchWord.toLowerCase()) ||
          item.date_request.includes(searchWord) || item.amount_requested.toString().includes(searchWord)
      );

      if (tab.length > 0) {
        setResultSearch(tab);
      } else {
        setResultSearch([]);
      }
    }
  };

  // return store active id and get sale 
  const returnStore = () => {
    if (store && store.length > 0) {
      let storeFind = store.find(
        (item) => item.is_active === true
      );
      if (storeFind) {
        getSaleStore(storeFind.store_id);
        getTransactionByStore(storeFind.store_id);
        setStoreActive(storeFind.store_id);
        return 0;
      }
    }
  };

  // action add and update request
  const handleSubmit = e => {
    e.preventDefault();
    let err = document.getElementById("err-price");
    const amount = getAmountTotal();
    if (validInputText(price, "err-price", "price")) {
      if (parseInt(price) <= parseInt(amount)) {
        if (action === "create") {
          createTransactionRequest(price, storeActive, isDone, isPopup, setMsg, isOpenForm);
        } else {
          updateTransactionRequest(idReq, price, isDone, isPopup, setMsg, isOpenForm);
        }
      } else {
        err.innerText = "Le montant que vous demandez est supérieur au prix total de vos ventes";
      }
    }
  };

  const setInfosTransactionUpdate = (list) => {
    let title = document.querySelector(".textTitle");
    let btn = document.querySelector(".btn-handle");

    setAction("update");
    btn.innerText = "Valider";
    title.innerText = "Modifier la demande";
    isOpenForm(true);

    setIdReq(list.id_req);
    setPrice(list.amount_requested);
  };

  // get amount transaction approuved 
  const getAmountTotal = () => {
    let amountTotalSale = 0;
    let amountTotalTransact = 0;

    if (sales.length > 0) {
      sales.forEach(item => {
        amountTotalSale += item.amount;
      });
    }

    if (transaction.length > 0) {
      let filter = transaction.filter(
        (item) => item.status_request === "satisfaire"
      );
      if (filter) {
        filter.forEach(item => {
          amountTotalTransact += item.amount_requested;
        });
      }
    }
    return amountTotalSale - amountTotalTransact;
  };


  useEffect(() => {
    // return store active id getSaleStore
    returnStore();

    // search 
    search();
  }, [store, searchWord]);

  return (
    <div className="revenu-fond-page">
      <HeadTabs title={"Demande de transaction"} />
      <BoxAlertSucess popup={popup}
        text={msg}
        handleBtn={isPopup} isDone={isDone} done={done}
        link={"#"} />
      <PopupLayout closes={openForm}>
        <div className="requestForm">
          <span className="close"
            onClick={() => isOpenForm(!openForm)}>&times;</span>
          <form>
            <h3 className="textTitle"> Demander une reversement </h3>
            <div className="requete-sum">
              <input type="text" placeholder="Entrer le montant que vous voulez retirer"
                value={price}
                onChange={e => setPrice(e.target.value)}
                onKeyUp={() => validInputText(price, "err-price", "price")} />
              <span id="err-price"></span>
              <button className="btn-handle" onClick={handleSubmit}> Envoyer </button>
            </div>
          </form>
        </div>
      </PopupLayout>
      <div className='block-revenu parent'>
        <div className="div1">
          <div >
            <p>
              Montant total des ventes :  <input type="text" value={getAmountTotal()+" FCFA"} />
            </p>
            {getAmountTotal() > 0 ?(
              <button onClick={() => isOpenForm(!openForm)}> Demander un reversement </button>
            ):null}
          </div>
        </div>
        <div className="child">
          <SearchBar placeholderText="Rechercher par date demande et status demande"
            searchWord={searchWord} allList={transaction.length > 0 ? transaction : null}
            setList={setResultSearch}
            setWord={setSearchWord} />
          <table className="table-data">
            <thead>
              <tr>
                <th> N° Demande </th>
                <th> Montant demander </th>
                <th> Date demande </th>
                <th> Statut demande </th>
                <th> Date réponse </th>
                <th> Actions </th>
              </tr>
            </thead>
            <tbody>
              {
                resultSearch.length == 0 ? (
                  transaction.length > 0 && (
                    transaction.map((list, index) => {
                      if (previouspage <= index && (nextPage - 1) >= index) {
                        return <tr key={index}>
                          <td> {index + 1} </td>
                          <td> {list.amount_requested} </td>
                          <td> {list.date_request} </td>
                          <td> {list.status_request} </td>
                          <td> {list.date_response != null ? list.date_response : "-"} </td>
                          <td>
                            {list.status_request !== "en cour" ? "-" : (
                              <>
                                <button onClick={() => setInfosTransactionUpdate(list)}> <FiEdit3 /> <br /> Modifier </button>
                                <button onClick={() => deleteTransactionRequest(list.id_req, isDone, isPopup, setMsg,)}> <FiTrash2 /> <br /> Supprimer </button>
                              </>
                            )}
                          </td>
                        </tr>;
                      }
                    })
                  )
                ) : (
                  resultSearch.map((list, index) => {
                    return <tr key={index}>
                      <td> {index + 1} </td>
                      <td> {list.amount_requested} </td>
                      <td> {list.date_request} </td>
                      <td> {list.status_request} </td>
                      <td> {list.date_response != null ? list.date_response : "-"} </td>
                      <td>
                        {list.status_request ? "-" : <button onClick={() => setInfosTransactionUpdate(list)}> <FiEdit3 /> <br /> Modifier </button>}
                        {list.status_request ? "-" : (
                          <button onClick={() => deleteTransactionRequest(list.id_req, isDone, isPopup, setMsg,)}> <FiTrash2 /> <br /> Supprimer </button>
                        )}
                      </td>
                    </tr>;
                  })
                )
              }
            </tbody>
          </table>
          {resultSearch.length == 0 ? (
            <Pagination nb={startNbPage}
              countTab={transaction.length > 0 ? transaction.length : 0}
              setNextt={setNextPage}
              setPrevious={setPreviousPage}
              nextt={nextPage}
              previous={previouspage} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default RevenuFonds;
