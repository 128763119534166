import React, { useContext, useEffect, useState } from "react";
import { FiEdit3, FiTrash2 } from "react-icons/fi";

import "./Transaction.scss";

import { HeadTabs } from "../../../../components/headTabs/HeadTabs";
import SearchBar from "../../../../components/searchBar2/SearchBar";
import GeneralContext from "../../../../context/GlobalContext";
import { BoxAlertSucess } from "../../../../components/boxAlertSucess/BoxAlertSucess";
import Pagination from "../../../../components/pagination2/Pagination";

const TransactionsAdmin = () => {

    const { transaction, approuveRequestTransaction,
        rejectRequestTransaction, getAllTransaction,
        downloadFileExcelTransaction, requestTransactionSucces } = useContext(GeneralContext);

    const [searchWord, setSearchWord] = useState("");
    const startNbPage = 5;
    const [nextPage, setNextPage] = useState(startNbPage);
    const [previouspage, setPreviousPage] = useState(0);
    const [resultSearch, setResultSearch] = useState([]);

    const [done, isDone] = useState(false);
    const [popup, isPopup] = useState(false);
    const [msg, setMsg] = useState(null);
    const [download, isDownload] = useState(false);

    const search = async () => {
        if (searchWord) {
            const tab = transaction.filter(
                (item) => item.status_request.toLowerCase().includes(searchWord.toLowerCase()) ||
                    item.date_request.includes(searchWord) || item.amount_requested.toString().includes(searchWord) ||
                    item.Store.name_store.toLowerCase().includes(searchWord.toLowerCase())
            );

            if (tab.length > 0) {
                setResultSearch(tab);
            } else {
                setResultSearch([]);
            }
        }
    };

    useEffect(() => {
        getAllTransaction();
        search();
    }, [searchWord]);

    return (
        <div className="revenu-fond-page">
            <HeadTabs title={"Demande de transaction"} />
            <BoxAlertSucess popup={popup}
                text={msg}
                handleBtn={isPopup} isDone={isDone} done={done}
                link={"#"} />
            <div className='block-revenu parent'>
                <div className="download-file-excel">
                    <button onClick={() => isDownload(!download)}>Télécharger la liste des transactions</button>
                    {download ? (
                        <ul className="list-transact">
                            <li onClick={() => downloadFileExcelTransaction("tout")}>Tout</li>
                            <li onClick={() => downloadFileExcelTransaction("en cour")}>En cour</li>
                            <li onClick={() => downloadFileExcelTransaction("approuvé")}>Approuvé</li>
                            <li onClick={() => downloadFileExcelTransaction("rejecté")}>Rejeté</li>
                        </ul>
                    ) : null}
                </div>
                <div className="child">
                    <SearchBar placeholderText="Rechercher par date demande et status demande"
                        searchWord={searchWord} allList={transaction.length > 0 ? transaction : null}
                        setList={setResultSearch}
                        setWord={setSearchWord} />
                    <table className="table-data">
                        <thead>
                            <tr>
                                <th> N° Demande </th>
                                <th> Id boutique </th>
                                <th> Nom boutique </th>
                                <th> Montant demandé </th>
                                <th> Date de la demande </th>
                                <th> Status </th>
                                <th> Date de réponse </th>
                                <th> Actions </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                resultSearch.length == 0 ? (
                                    transaction.length > 0 && (
                                        transaction.map((list, index) => {
                                            if (previouspage <= index && (nextPage - 1) >= index) {
                                                return <tr key={index}>
                                                    <td> {index + 1} </td>
                                                    <td> B{list.Store.store_id} </td>
                                                    <td> {list.Store.companyName} </td>
                                                    <td> {list.amount_requested} </td>
                                                    <td> {list.date_request} </td>
                                                    <td> {list.status_request} </td>
                                                    <td> {list.date_response != null ? list.date_response : "-"} </td>
                                                    <td>
                                                        <button onClick={() => approuveRequestTransaction(list.id_req, isDone, isPopup, setMsg)}> <FiEdit3 /> <br />Approuver</button>
                                                        <button onClick={() => requestTransactionSucces(list.id_req, isDone, isPopup, setMsg)}> <FiEdit3 /> <br />Satisfaire</button>
                                                        <button onClick={() => rejectRequestTransaction(list.id_req, isDone, isPopup, setMsg,)}> <FiTrash2 /> <br />Rejecter</button>
                                                    </td>
                                                </tr>;
                                            }
                                        })
                                    )
                                ) : (
                                    resultSearch.map((list, index) => {
                                        return <tr key={index}>
                                            <td> {index + 1} </td>
                                            <td> {list.Store.store_id} </td>
                                            <td> {list.Store.name_store} </td>
                                            <td> {list.amount_requested} </td>
                                            <td> {list.date_request} </td>
                                            <td> {list.status_request} </td>
                                            <td> {list.date_response != null ? list.date_response : "-"} </td>
                                            <td>
                                                <button onClick={() => approuveRequestTransaction(list.req, isDone, isPopup, setMsg)}> <FiEdit3 /> <br /> Approuver </button>
                                                <button onClick={() => rejectRequestTransaction(list.id_req, isDone, isPopup, setMsg,)}> <FiTrash2 /> <br /> Rejecter </button>
                                            </td>
                                        </tr>;
                                    })
                                )
                            }
                        </tbody>
                    </table>
                    {resultSearch.length == 0 ? (
                        <Pagination nb={startNbPage}
                            countTab={transaction.length > 0 ? transaction.length : 0}
                            setNextt={setNextPage}
                            setPrevious={setPreviousPage}
                            nextt={nextPage}
                            previous={previouspage} />
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default TransactionsAdmin;
