import React, { useContext, useEffect, useState } from "react";

import "../Footer.scss";
import AuthContext from "../../../context/AuthContext";
import { validInputText } from "../../controlFields/ControlField";

import { FaFacebookF, FaInstagram, FaPinterest, FaTwitter } from "react-icons/fa";
import { ABOUT_PAGE, CONDITION_GENERAL_PAGE, CONTACT_PAGE, FAQ_PAGE, POLITIC_CONFIDENTIALITe_PAGE, POLITIC_REMBOURSEMENT_PAGE } from "../../../settings/constant";
import GeneralContext from "../../../context/GlobalContext";
import { Link } from "react-router-dom";


const FooterHome = () => {

  const { save_email_newsletter } = useContext(AuthContext);
  const { category } = useContext(GeneralContext);

  const [email, setEmail] = useState();
  const [done, isDone] = useState(false);

  // action save infos newsletter
  const handleSubmit = e => {
    e.preventDefault();
    validInputText(email, "email-error", "email");

    if (validInputText(email, "email-error", "email")) {
      save_email_newsletter(email, isDone);
    }
  };
  const cats = ["Beauté Et Cheveux", "Vêtements", "Tissus", "Sacs Et Accessoires", "Bijoux"];
  const returnYear = () => {
    let date = new Date(),
      year = date.getFullYear();
      return year;
  };

  return (
    <footer>
      <div className="row1">
        <div className="foot-div1">
          <h2>Entreprise</h2>
          <ul>
            <a href={ABOUT_PAGE}>À propos de nous</a>
            <a href={CONTACT_PAGE}>Contact</a>
            <a href={FAQ_PAGE}>FAQ</a>
          </ul>
        </div>
        <div className="foot-div2">
          <h2> nos politiques </h2>
          <ul>
            <a href={POLITIC_CONFIDENTIALITe_PAGE}>nos politiques de confidentialité</a>
            <a href={CONDITION_GENERAL_PAGE}>conditions générales d'utilisation</a>
            <a href={POLITIC_REMBOURSEMENT_PAGE}>politiques de remboursement</a>
          </ul>
        </div>
        <div className="foot-div2">
          <h2> catégories </h2>
          <ul>
            {category &&
              category.map((list, index) => {
                for (let i = 0; i < cats.length; i++) {
                  if (list.nameCat === cats[i]) {
                    return <div className="nav-li" key={index}>
                      <Link className="link color-link"
                        to={{ pathname: "/categories/produits", search: `categorie=${list.nameCat.replaceAll(" ", "-")}catId${list.idCat}$` }}
                        state={list}> {list.nameCat} </Link>
                    </div>;
                  }
                }
              })
            }
          </ul>
        </div>
        <div className="foot-div4">
          <h2> suivez nous</h2>
          <p>
            Profitez de nos différentes offres sur nos différents réseaux sociaux !
          </p>
          <div className="group-social">
            <a href={""} target="_blank" rel="noreferrer" > <FaFacebookF /> </a>
            <a href={""} target="_blank" rel="noreferrer"> <FaTwitter /> </a>
            <a href={""} target="_blank" rel="noreferrer"> <FaInstagram /> </a>
            <a href={""} target="_blank" rel="noreferrer"> <FaPinterest /> </a>
          </div>
        </div>
      </div>
      <form className="newsletter">
        <h2>Inscrivez-vous à notre newsletter</h2>
        <p>
          Soyez les premiers à être informé de nos nouveaux arrivages et actualités.
        </p>
        {!done ? null : (
          <p className="msg-text">
            Cet email est déjà enrégistré dans le newsletter
          </p>
        )}
        <p>
          <input type='email' placeholder=""
            value={email}
            onChange={e => setEmail(e.target.value)} required />
          <button onClick={handleSubmit}>S'inscrire</button>
        </p>
        <span id="email-error"></span>
      </form>
      <div className="div-foot">
        <p>Réalisé par <a href="https://lavedette.net/">La Vedette Média</a> </p>
        <p>&copy; {returnYear()} AUTASYS</p>
      </div>
    </footer>
  );
};

export default FooterHome;
