import React from "react";
import FooterHome from "../../components/footer/footers/FooterHome";

import "./LayoutHome.scss";
import HeaderAuth from "../../components/header/headers/HeaderAuth";
import FooterAuth from "../../components/footer/footers/FooterAuth";

const LayoutAuth = ({children}) => {
  return (
    <>
      <HeaderAuth />
      <div className="auth-content">
        {children}
      </div>
      <FooterAuth />
    </>
  );
};

export default LayoutAuth;
