import React, { useContext, useEffect, useState } from "react";
import Slider from "@mui/material/Slider";
import "./filterBox.scss";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import GeneralContext from "../../context/GlobalContext";
import { Link } from "react-router-dom";

const FilterBox = ({ filterBox, isFilterBox }) => {

  const { getCategoryProduct, categoryProduct,
    filterProductByPrice,
    filterProductBySize,
    filterProductByColor } = useContext(GeneralContext);

  const [open1, isOpen1] = useState(false);
  const [open2, isOpen2] = useState(false);
  const [open3, isOpen3] = useState(false);
  const [open4, isOpen4] = useState(false);
  const [indexCat, setIndexCat] = useState(0);

  const [sizes, setSize] = useState(null);
  const [colors, setColor] = useState(null);

  const color = ["red", "green", "yellow", "white", "gray"];
  const size = ["S", "M", "L", "XL", "2XL", "3XL"];

  // plage price prod filter
  const [range, setRange] = useState([10, 10000]);

  // action get product filter by price
  const handleChangePrice = e => {
    setRange(e.target.value);
    filterProductByPrice(range[0], range[1]);
  };

  //show sub category
  const toggleSubCategories = (indexCat) => {
    setIndexCat(indexCat);
  };

  useEffect(() => {
    /* get category products */
    getCategoryProduct();
  }, []);

  return (
    <div className="filter-element-div"
      style={filterBox ? { display: "block" } : { display: "none" }}>
      <span className="close" onClick={() => isFilterBox(false)}>&times;</span>
      <div className="filters-element">
        <div className="head-filter">
          <h4> Filtre </h4>
          <button onClick={() => isFilterBox(false)}> &times; </button>
        </div>
        <div className="block-filters">
          <h2> FIltrer par catégories
            {open1 ? <FiChevronDown onClick={() => isOpen1(!open1)} /> :
              <FiChevronRight onClick={() => isOpen1(!open1)} />}
          </h2>
          <div className={`category ${open1 ? "ope" : "clos"}`}>
            {categoryProduct && (
              categoryProduct.map((list, index) => {
                return <div className="box-cat" key={index}>
                  <Link style={{ color: "var(--dark)" }}
                    to={{ pathname: "/categories/produits", search: `categorie=${list.nameCat.replaceAll(" ", "-")}catId${list.idCat}$` }}
                    state={list}>
                    <p onClick={() => toggleSubCategories(list.idCat)}>
                      <li> {list.nameCat} </li>
                      {list.SubCategories.length > 0 ? (indexCat === list.idCat ? <FiChevronDown /> : <FiChevronRight />) : null}
                    </p>
                  </Link>
                  {indexCat === list.idCat ? (
                    <ul className="sublink">
                      {list.SubCategories && (
                        list.SubCategories.map((list2, index2) => {
                          return <li className="SubCategories" key={index2}>
                            <Link className="" tyle={{ color: "var(--dark)" }}
                              to={{ pathname: "/categories/produits", search: `categorie=${list.nameCat.replaceAll(" ", "-")}catId${list.idCat}$subcategories=${list2.nameSubCat}subid${list2.idSubCat}$` }}
                              state={list2}> {list2.nameSubCat} </Link>
                          </li>;
                        })
                      )}
                    </ul>
                  ) : null}
                </div>;
              })
            )}
          </div>
          {/* <h2> Filtrer par couleur
            {open2 ? <FiChevronDown onClick={() => isOpen2(!open2)} /> :
              <FiChevronRight onClick={() => isOpen2(!open2)} />}
          </h2>
          <div className={`filter-color ${open2 ? "ope" : "clos"}`}>
            {color.map((list, index) => {
              return <div className={`color-div ${colors === list ? "active-color-filter": null }`} style={{ background: list }}
                onClick={() => {
                  filterProductByColor(list);
                  setColor(list);
                }} key={index}></div>;
            })}
          </div> */}
          {/* <h2> Filtrer par Taille
            {open3 ? <FiChevronDown onClick={() => isOpen3(!open3)} /> :
              <FiChevronRight onClick={() => isOpen3(!open3)} />}
          </h2>
          <div className={`filter-size ${open3 ? "ope" : "clos"}`}>
            {size.map((list, index) => {
              return <div className={`size ${sizes === list ? "active-size-filter": null }`}
                onClick={() => {
                  filterProductBySize(list);
                  setSize(list);
                }} key={index}>
                {list}
              </div>;
            })}
          </div> */}
          <h2> Filtrer par prix
            {open4 ? <FiChevronDown onClick={() => isOpen4(!open4)} /> :
              <FiChevronRight onClick={() => isOpen4(!open4)} />}
          </h2>
          <div className={`filter-price ${open4 ? "ope" : "clos"}`}>
            <Slider value={range} onChange={handleChangePrice}
              valueLabelDisplay="auto" max={50000} />
            <p>
              <span> Min: <input type="text" value={range[0] + " CFA"} /> </span>
              <span> Max: <input type="text" value={range[1] + " CFA"} /> </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterBox;