import React, { useContext, useEffect, useState } from "react";
import { FaBuffer, FaBuysellads, FaUserCircle } from "react-icons/fa";
import { BsNewspaper } from "react-icons/bs";
import { FiChevronDown, FiChevronRight, FiList, FiLogOut, FiMenu, FiSettings } from "react-icons/fi";
import { FcShop } from "react-icons/fc";
import { AiFillShop, AiOutlineDashboard } from "react-icons/ai";
import { BiCategoryAlt } from "react-icons/bi";
import { MdOutlineArticle, MdPayment } from "react-icons/md";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";

import "./LayoutAdmin.scss";

import logo from "../../../assets/images/png/logo.png";
import GeneralContext, { GlobalContext } from "../../../context/GlobalContext";
import {
  DASHBOARD_ADMIN_ADD_PRODUCTS,
  DASHBOARD_ADMIN_ARTICLE,
  DASHBOARD_ADMIN_CATEGORY,
  DASHBOARD_ADMIN_COBEILLE_PRODUCT,
  DASHBOARD_ADMIN_HOME,
  DASHBOARD_ADMIN_NEWSLETTER,
  DASHBOARD_ADMIN_ORDER,
  DASHBOARD_ADMIN_PRODUCTS,
  DASHBOARD_ADMIN_SALE,
  DASHBOARD_ADMIN_SETTINGS,
  DASHBOARD_ADMIN_SHOP,
  DASHBOARD_ADMIN_TRANSACTION,
  DASHBOARD_SELLER_ORDER
} from "../../../settings/constant";
import AuthContext from "../../../context/AuthContext";

const LayoutAdmin = ({ children }) => {

  const { logoutAdmin } = useContext(AuthContext);
  const { store } = useContext(GeneralContext);

  const [storeActive, setStoreActive] = useState("");
  const [index, setIndex] = useState(0);
  const [indexStoreActive, setIndexStoreActive] = useState(null);

  const [id, setId] = useState(0);
  const [id2, setId2] = useState(0);


  /* get store active */
  const getStoreActive = () => {
    if (store) {
      let find = store.find(
        (item) => item.is_active == true,
      );

      if (find) {
        setStoreActive(find.companyName.replace(/[-'_\s]/, ""));
        setIndexStoreActive(find.store_id);
      }
    }
  };

  /* menu link */
  const menu = [
    {
      label: "Tableau de bord",
      url: DASHBOARD_ADMIN_HOME,
      icon: <AiOutlineDashboard />,
      index: 0
    },
    {
      label: "Boutiques",
      url: DASHBOARD_ADMIN_SHOP,
      icon: <AiFillShop />,
      index: 1
    },
    {
      label: "Catégorie",
      url: DASHBOARD_ADMIN_CATEGORY,
      icon: <BiCategoryAlt />,
      index: 2
    },
    {
      label: "Produits",
      url: "",
      icon: <FiList />,
      index: 20,
      submenu: [
        { label2: "Liste des produits", url2: DASHBOARD_ADMIN_PRODUCTS, index: 3 },
        { label2: "Ajouter un produit", url2: DASHBOARD_ADMIN_ADD_PRODUCTS, index: 4 },
        { label2: "Corbeille", url2: DASHBOARD_ADMIN_COBEILLE_PRODUCT, index: 5 },
      ],
    },
    {
      label: "Commandes",
      url: DASHBOARD_ADMIN_ORDER,
      icon: <FaBuffer />,
      index: 6
    },
    {
      label: "Ventes",
      url: DASHBOARD_ADMIN_SALE,
      icon: <FaBuysellads />,
      index: 7
    },
    {
      label: "Articles",
      url: DASHBOARD_ADMIN_ARTICLE,
      icon: <MdOutlineArticle />,
      index: 8
    },
    {
      label: "Transaction",
      url: DASHBOARD_ADMIN_TRANSACTION,
      icon: <MdPayment />,
      index: 9
    },
    {
      label: "Newsletter",
      url: DASHBOARD_ADMIN_NEWSLETTER,
      icon: <BsNewspaper />,
      index: 10
    },
    {
      label: "Paramètres",
      url: DASHBOARD_ADMIN_SETTINGS,
      icon: <FiSettings />,
      index: 11
    }
  ];

  /* functon navigate of tabs */
  const navigateUrl = (url, index) => {
    sessionStorage.setItem("menuId", index);
    const allLink = document.querySelectorAll(".link-p");
    const allSub = document.querySelectorAll(".sub-ul");

    if (url) {
      window.location.href = url;
    }

    let ids = parseInt(sessionStorage.getItem("menuId"));

    if (ids === 20 || ids == 3 || ids == 4 || ids == 5) {
      if (allSub[0].style.height == "0px") {
        allSub[0].style.height = "max-content";
        setId(1);
      } else {
        setId(0);
        allSub[0].style.height = "0px";
      }
    }

    for (let i = 0; i < allLink.length; i++) {
      allLink[i].classList.remove("active-menu");
    }

    for (let i = 0; i < allSub.length; i++) {
      allSub[i].classList.remove("open-sub-menu");
    }
    allLink[ids].classList.add("active-menu");
  };


  // show menu mobile nav
  const showMenu = () => {
    const menu = document.querySelector(".nav-bar");
    if (menu.style.width == "190px") {
      menu.style.width = "0px";
    } else {
      menu.style.width = "190px";
      menu.style.position = "absolute";
    }
  };

  /* select store */
  const selectStore = (id) => {
    if (store && store.length > 0) {
      let find = store.find(
        (item) => item.is_active === true,
      );

      if (find) {
        if (id === find.store_id) {
          setIndex(id);
        }
      }
    }
  };

  // logout admin
  const logout = () => {
    logoutAdmin();
  };

  useEffect(() => {
    getStoreActive();
    selectStore(indexStoreActive);
  }, [store]);


  return (
    <GlobalContext>
      <div className="dashboard-seller-home">
        <div className="main">
          <div className="main-center">
            <div className="nav-bar">
              <span className="close-mobile-nav"
                onClick={showMenu}>&times;</span>
              <div className="logo z-index bg-white">
                <img src={logo} />
              </div>
              <div className="shop z-index ">
                <FaUserCircle />
                <span> Administrateur </span>
              </div>
              <div className="nav z-index">
                {
                  menu.map(({ label, url, index, icon, submenu }, key) => {
                    return <div className="nav-ul" key={key}>
                      <p onClick={() => navigateUrl(url, index)}
                        className={`link-p ${sessionStorage.getItem("menuId") == index ? "active-menu" : ""}`}>
                        <span> {icon} {label} </span>
                        {index == 20 ? (id == 1 ? <FiChevronDown /> : <FiChevronRight />) : null}
                        {index == 21 ? (id2 == 1 ? <FiChevronDown /> : <FiChevronRight />) : null}
                      </p>
                      {submenu && (
                        <div className="sub-ul">
                          {
                            submenu.map((list, index2) => {
                              return <li
                                className={`link-p ${sessionStorage.getItem("menuId") == list.index ? "active-menu" : ""}`}
                                onClick={() => navigateUrl(list.url2, list.index)}
                                key={index2}>
                                {list.label2}
                              </li>;
                            })
                          }
                        </div>
                      )}
                    </div>;
                  })
                }
                <button onClick={logout}> <FiLogOut /> Déconnexion </button>
              </div>
            </div>
            <div className="body">
              <div className="bar-head-mobile">
                <button className="btn-menu"
                  onClick={showMenu}><FiMenu /></button>
              </div>
              <div className="content-body">
                <div className="block-content">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    </GlobalContext>
  );
};

export default LayoutAdmin;
