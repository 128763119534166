import React from "react";
import { FiCheckCircle } from "react-icons/fi";
import { PopupLayout } from "../popupLayout/PopupLayout";

import "./BoxAlertSucess.scss";
import { Spinner2 } from "../spinner2/Spinner2";

export const BoxAlertSucess = ({ popup, text, handleBtn, done, isDone, link }) => {
  return (
    <PopupLayout closes={popup}>
      <div className='popup-card'>
        {done ? (
          <>
            <FiCheckCircle />
            <p>
              {text}
            </p>
            <button onClick={() => {
              handleBtn(false);
              isDone(false);
              link !== "#" ? window.location.href = link : window.location.reload();
            }}>OK</button>
          </>
        ):<Spinner2 /> }
      </div>
    </PopupLayout>
  );
};